import React, { createContext, useState, useContext } from 'react';

const SupPageTitleContext = createContext();

export const SupPageTitleProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('Overview');

  return (
    <SupPageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </SupPageTitleContext.Provider>
  );
};

export const useSupPageTitle = () => useContext(SupPageTitleContext);