//src/SuperComponents/SuperDashboard/Pricing/Coach/addpricing.js
import React, { useState, useEffect } from 'react';
import './addpricing.css';
import useApi from '../../../../api/useApi';

const permissionsList = [
  "coaching_permision",
  "displayCoach_permission",
  "shopCoach_permission",
  "nutrition_permission",
  
 ];

const Modal = ({ show, onClose, message }) => {
  if (!show) return null;

  return (
    <div className="modal-done" onClick={onClose}>
      <div className="modal_saved" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="checkmark-circle">
            <div className="background"></div>
            <div className="checkmark"></div>
          </div>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

const CoachAddPricing = ({ closeModal, currentPlan, setPlans, addNewPlan }) => {
  const [name, setName] = useState(currentPlan?.name || '');
  const [price, setPrice] = useState(currentPlan?.price || '');
  const [promotion, setPromotion] = useState(currentPlan?.promotionPercentage || '');
  const [duration, setDuration] = useState(currentPlan?.duration || 'MONTHLY');
  const [description, setDescription] = useState(currentPlan?.description || '');
  const [nbUser, setNbUser] = useState(currentPlan?.nbUser || '');
  const [promotionActive, setPromotionActive] = useState(currentPlan?.promotionActive || false);
  const [permissions, setPermissions] = useState(currentPlan?.autorisation || []);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { request } = useApi();

  useEffect(() => {
    if (currentPlan) {
      setName(currentPlan.name);
      setPrice(currentPlan.price);
      setPromotion(currentPlan.promotionPercentage);
      setDuration(currentPlan.duration);
      setDescription(currentPlan.description);
      setNbUser(currentPlan.nbUser);
      setPromotionActive(currentPlan.promotionActive);
      setPermissions(currentPlan.autorisation || []);
    }
  }, [currentPlan]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      price: parseFloat(price),
      promotionPercentage: parseFloat(promotion),
      duration,
      description,
      nbUser: parseInt(nbUser, 10),
      autorisation: permissions,   
      promotionActive,
    };

    try {
      let response;
      if (currentPlan) {
        response = await request('put', `coachpacks/${currentPlan._id}`, data);
      } else {
        response = await request('post', 'coachpacks', data);
      }

      if (response) {
        addNewPlan(response);
        setShowConfirmationModal(true);

        setTimeout(() => {
          setShowConfirmationModal(false);
          closeModal();
        }, 2000);
      } else {
        console.error('Failed to save data:', response);
      }
    } catch (error) {
      console.error('Error saving data:', error.response || error.message);
      if (error.response && error.response.data && error.response.data.message) {
        alert('Validation Error: ' + error.response.data.message.join(', '));
      } else {
        alert('An error occurred while saving the data.');
      }
    }
  };

  return (
    <div className="modal-backdrop" onClick={closeModal}>
      <div className="modal_form" onClick={(e) => e.stopPropagation()}>
        <div className="add-pricingform">
          <h2>{currentPlan ? 'Edit Pricing Pack' : 'Add Pricing Pack'}</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Name of the Pack
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </label>
            <label>
              Price(TND)/Month
              <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
            </label>
            <label>
              Promotion
              <input type="text" value={promotion} onChange={(e) => setPromotion(e.target.value)} />
            </label>
            <label>
              Duration
              <select value={duration} onChange={(e) => setDuration(e.target.value)}>
                <option value="MONTHLY">Monthly</option>
                <option value="YEARLY">Yearly</option>
              </select>
            </label>
            <label>
              Description
              <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />
            </label>
            <label>
              Number of Users
              <input type="number" value={nbUser} onChange={(e) => setNbUser(e.target.value)} required />
            </label>
            <label>
              Promotion Active
              <input type="checkbox" checked={promotionActive} onChange={(e) => setPromotionActive(e.target.checked)} />
            </label>
            <label>
              Permissions
              <select
                multiple
                value={permissions}
                onChange={(e) => setPermissions(Array.from(e.target.selectedOptions, option => option.value))}
              >
                {permissionsList.map((permission) => (
                  <option key={permission} value={permission}>
                    {permission}
                  </option>
                ))}
              </select>
            </label>
            <div className="form_btn">
              <button type="submit" className='done_btn'>Done</button>
            </div>
          </form>
        </div>
      </div>

      {showConfirmationModal && (
        <Modal show={showConfirmationModal} onClose={() => setShowConfirmationModal(false)} message="A Pack Has been Added" />
      )}
    </div>
  );
};

export default CoachAddPricing;