import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { ActivityHoursChartData } from '../../../../Data/OnlineCourse/ChartData';  
import Socialclicks from './Socialclicks/social.jsx'; 
import '../../Overview/ActivityHours/ActivityHours.css'; 
import './Clicks.css';  

const Clicks = () => {
  const twelveMonthsData = ActivityHoursChartData.twelveMonths;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="clicks-container">
      <div className='clicks-header-top'>
        <Breadcrumb>
          <BreadcrumbItem>
            <span>&lt; </span>
            <a href="/overview">Overview </a>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <span>/ Clicks</span>
          </BreadcrumbItem>
        </Breadcrumb>
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="clicks-calendar-icon large-icon">
         </button>
      </div>
      <Card className='clicks-big-card shared-card-style'>
        <CardHeader className='card-no-border'>
        </CardHeader>
        <CardBody className='pt-0'>
          <Row className='m-0 overall-card'>
            <Col xl='12'>
              <div className='clicks-chart-right'>
                <Row>
                  <Col xl='12'>
                    <CardBody className='p-0'>
                      <div className='clicks-activity-wrap'>
                        <ReactApexChart type='bar' height={300} options={twelveMonthsData.options} series={twelveMonthsData.series} />
                      </div>
                    </CardBody>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Socialclicks className='shared-card-style' />
      <div className={`clicks-calendar-sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button onClick={() => setIsSidebarOpen(false)} className="clicks-close-btn">
          &times;
        </button>
       </div>
    </div>
  );
};

export default Clicks;