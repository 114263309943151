//src/Route/SuperLayoutRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SuperOverview from '../SuperComponents/SuperDashboard/Overview/superoverview';
import Layout from '../SuperComponents/Layout/Layout';
import GymPricing from '../SuperComponents/SuperDashboard/Pricing/Gym/pricing';
import GymAddPricing from '../SuperComponents/SuperDashboard/Pricing/Gym/addpricing';
import CoachPricing from '../SuperComponents/SuperDashboard/Pricing/Coach/pricing';
import CoachAddPricing from '../SuperComponents/SuperDashboard/Pricing/Coach/addpricing';
import Gym from '../SuperComponents/SuperDashboard/Gym/gym';  
import Supergymdetails from '../SuperComponents/SuperDashboard/Gym/Supergymdetails/Supergymdetails';  
import AllSubscription from '../SuperComponents/SuperDashboard/Coaches/SuperAddCoach/SuperAddCoach';
import Coaches from '../SuperComponents/SuperDashboard/Coaches/Coaches';  
import SuperCoachDetails from '../SuperComponents/SuperDashboard/Coaches/SuperCoachDetails/SuperCoachDetails';
 
const SuperLayoutRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/Overviewsuperadmin" element={<SuperOverview />} />
        <Route path="/superadmin/gym" element={<Gym />} />
        <Route path="/superadmin/gym/:gymName" element={<Supergymdetails />} /> 
        <Route path="/Pricing/gym" element={<GymPricing />} />
        <Route path="/Pricing/gym/addpricing" element={<GymAddPricing/>} />
        <Route path="/Pricing/gym/editpricing" element={<GymAddPricing/>} />
        <Route path="/Pricing/coach" element={<CoachPricing />} />
        <Route path="/Pricing/coach/addpricing" element={<CoachAddPricing/>} />
        <Route path="/Pricing/coach/editpricing" element={<CoachAddPricing/>} />
        <Route path="/superadmin/subscriptions" element={<AllSubscription />} />
        <Route path="/superadmin/coaches" element={<Coaches />} />  
        <Route path="/superadmin/coaches/SuperCoachDetails/:id" element={<SuperCoachDetails />} />  
      </Route>
    </Routes>
  );
};

export default SuperLayoutRoutes;