import React, { useState } from 'react';
import './EditProduct.css';
import { useNavigate } from 'react-router-dom';

const EditProduct = () => {
    const [productName, setProductName] = useState('Name (Gloves)');
    const [description, setDescription] = useState('Découvrez notre Tote Bag FIGUE avec Poche en Coton 100% Naturel...');
    const [details, setDetails] = useState({ size: '12', type: 'Bag' });
    const [pricing, setPricing] = useState({ oldPrice: '20', newPrice: '12' });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const navigate = useNavigate();

    const handleSave = () => {
        // Save logic here
        setShowSuccessMessage(true);
    };

    const handleSeeInStore = () => {
        navigate('/shop');
    };

    return (
        <div className="edit-product-container">
            <h1>Edit product</h1>

            {showSuccessMessage && (
                <div className="success-popup-overlay">
                    <div className="success-popup-content">
                        <div className="checkmark-icon">&#10004;</div>
                        <h2 className="popup-title">Product Edited Successfully</h2>
                        <div className="popup-divider"></div>
                        <button className="done-button" onClick={() => setShowSuccessMessage(false)}>Done</button>
                    </div>
                </div>
            )}

            <div className="edit-product-section">
                <div className="edit-product-field">
                    <label htmlFor="productName">Name</label>
                    <input 
                        type="text" 
                        id="productName"
                        value={productName} 
                        onChange={(e) => setProductName(e.target.value)} 
                    />
                </div>

                <div className="edit-product-field">
                    <label htmlFor="description">Description</label>
                    <textarea 
                        id="description"
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)} 
                    />
                </div>
            </div>

            <div className="details-section">
                <label htmlFor="details">Details</label>
                <div className="details-group">
                    <div className="detail-item">
                        <label htmlFor="size"></label>
                        <input 
                            type="text" 
                            id="size"
                            value={details.size} 
                            onChange={(e) => setDetails({ ...details, size: e.target.value })} 
                        />
                    </div>
                    <div className="detail-item">
                        <label htmlFor="type"></label>
                        <input 
                            type="text" 
                            id="type"
                            value={details.type} 
                            onChange={(e) => setDetails({ ...details, type: e.target.value })} 
                        />
                    </div>
                </div>
            </div>

            <div className="pricing-section">
                <label htmlFor="pricing">Pricing</label>
                <div className="pricing-group">
                    <div className="pricing-item">
                        <label htmlFor="oldPrice">Old Price</label>
                        <input 
                            type="text" 
                            id="oldPrice"
                            value={pricing.oldPrice} 
                            onChange={(e) => setPricing({ ...pricing, oldPrice: e.target.value })} 
                        />
                    </div>
                    <div className="pricing-item">
                        <label htmlFor="newPrice">New Price</label>
                        <input 
                            type="text" 
                            id="newPrice"
                            value={pricing.newPrice} 
                            onChange={(e) => setPricing({ ...pricing, newPrice: e.target.value })} 
                        />
                    </div>
                </div>
            </div>

            <div className="actions-section">
                <button className="store-button" onClick={handleSeeInStore}>See in store</button>
                <button className="save-button" onClick={handleSave}>Save</button>
            </div>
        </div>
    );
};

export default EditProduct;