import React, { createContext, useState, useEffect } from 'react';
import { isAuthenticated, fetchUser } from '../api/auth/auth';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = isAuthenticated();
      if (authenticated) {
        const userData = await fetchUser();
        setUser(userData);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  const login = async (userData) => {
    setUser(userData);
    const role = userData.roles[0]?.toLowerCase();
    switch (role) {
      case 'superadmin':
        navigate('/Overviewsuperadmin');
        break;
      case 'respgym':
        navigate('/logo');
        break;
      case 'coach':
        navigate('/Overviewcoach');
        break;
      case 'user':
        navigate('/user/training');
        break;
      default:
        navigate('/logo');
        break;
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('id');
    setUser(null);
    navigate('/');  
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};