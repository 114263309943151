import React from 'react';
import AppRoutes from './Route/routes';
import SuperRoutes from './Route/superroutes';
import UserRoutes from './Route/userroute';
import CoachRoutes from './Route/coachroute';
import { UserProvider } from './api/UserContext';
import { SupPageTitleProvider } from './SuperComponents/context/SupPageTitleContext';
import { CoachPageTitleProvider } from './CoachComponents/context/CoachPageTitleContext';

const App = () => {
  return (
    <UserProvider>
      <SupPageTitleProvider>
        <AppRoutes />
        <SuperRoutes />
        <UserRoutes />
        <CoachPageTitleProvider> 
          <CoachRoutes />
        </CoachPageTitleProvider>
      </SupPageTitleProvider>
    </UserProvider>
  );
};

export default App;