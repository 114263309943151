import React from 'react';
import './filtershop.css';

const FilterShop = () => {
    return (
        <div className="filter-container">
            <label>Filter by:</label>
            <div className="input-group">
                <input type="text" placeholder="Product Name" />
            </div>
            <div className="input-group">
                <select>
                    <option value="">All</option>
                    <option value="visible">Visible</option>
                    <option value="invisible">Invisible</option>
                </select>
            </div>
            <button className="filter-button">Apply Filter</button>
        </div>
    );
};

export default FilterShop;
