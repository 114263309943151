// src/Components/Dashboard/Overview/Shoprevenue/shoprevenue.js
import React from 'react';
import { Link } from 'react-router-dom';
import Gender from '../Gymrevenue/Gender/gender';
import Income from './Income/shopincome';
import TopShopRev from './Top-shop-rev/top-shop-rev';
import './shoprevenue.css';

const Shoprevenue = () => {
  return (
    <div className='shoprevenue'>
      <div className="shoprevenue-breadcrumb">
        <Link to="/Overview">Overview</Link> / Shop Revenue
      </div>

      <div className='shoprevenue-gender-income-container'>
        <div className='shoprevenue-gender'>
          <Gender />
        </div>
        <div className='shoprevenue-income'>
          <Income />
        </div>
      </div>
      <div className='shoprevenue-top-shop-rev'>
        <TopShopRev />
      </div>
    </div>
  );
};

export default Shoprevenue;