import React, { useState, useEffect } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './Email.css';

const Email = ({ onClose }) => {
    const [emails, setEmails] = useState(['']); // Initial state with one email field

    useEffect(() => {
        // Ajouter la classe blur au conteneur principal quand le popup s'affiche
        const mainContent = document.getElementById('main-content'); // Assurez-vous d'avoir un ID sur le conteneur principal
        if (mainContent) {
            mainContent.classList.add('blur-background');
        }

        // Supprimer la classe blur quand le popup est fermÃ©
        return () => {
            if (mainContent) {
                mainContent.classList.remove('blur-background');
            }
        };
    }, []);

    const handleAddAnother = () => {
        setEmails([...emails, '']); // Add a new empty email field
    };

    const handleEmailChange = (index, value) => {
        const newEmails = [...emails];
        newEmails[index] = value;
        setEmails(newEmails);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission with all email fields
        console.log('Submitted emails:', emails);
        onClose(); // Close the popup after submission
    };

    return (
        <div className="adco-popupOverlay">
            <div className="adco-popupContent">
                <button className="adco-closeButton" onClick={onClose}>
                    <IoCloseOutline />
                </button>
                <h2>Add Subscription</h2>
                <form onSubmit={handleSubmit}>
                    {emails.map((email, index) => (
                        <div key={index} className="adco-emailField">
                            <label htmlFor={`email-${index}`}>Email</label>
                            <input 
                                type="email" 
                                id={`email-${index}`}
                                name={`email-${index}`} 
                                placeholder="Enter email" 
                                required 
                                autoComplete="email" 
                                value={email}
                                onChange={(e) => handleEmailChange(index, e.target.value)}
                            />
                        </div>
                    ))}
                    <a href="#" className="adco-addAnother" onClick={handleAddAnother}>+ Add Another</a>
                    <button type="submit" className="adco-submitButton">
                        Add Subscription
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Email;