import React from 'react';
import './social.css';
import { useNavigate } from 'react-router-dom';

const SocialMediaLinks = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/welcome');
  };

  return (
    <div className="socl-container">
      <div className="socl-form">
        <h2 className="socl-h2">Social Media links</h2>
        <p className="socl-p">Please make sure the links that lead to your Social Media Account are correct.</p>
        <form>
          <div className="socl-form-group">
            <label htmlFor="facebook">Facebook</label>
            <input type="text" id="facebook" placeholder="Link to Facebook Channel: @CaliforniaGym" />
          </div>
          <div className="socl-form-group">
            <label htmlFor="instagram">Instagram*</label>
            <input type="text" id="instagram" placeholder="Link to Instagram Channel: @CaliforniaGym" />
          </div>
          <div className="socl-form-group">
            <label htmlFor="youtube">YouTube*</label>
            <input type="text" id="youtube" placeholder="Link to YouTube Channel: @CaliforniaGym" />
          </div>
          <div className="socl-form-group">
            <label htmlFor="twitter">X*</label>
            <input type="text" id="twitter" placeholder="Link to our Twitter account @CaliforniaGym" />
          </div>
          <div className="socl-form-buttons">
            <button type="button" className="socl-btn-next" onClick={handleNext}>Next</button>
            <button type="button" className="socl-btn-skip">Skip</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SocialMediaLinks;