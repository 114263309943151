import './Addgym.css';
import { FaTrashAlt, FaEye, FaEdit } from 'react-icons/fa';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SuccessPopupSuper from '../SuccessPopupSuper/SuccessPopupSuper';
import SuccessPopupCheck from '../SucessPopupSuperCheck/SuccessPopupCheck';
import SuccessPopupSuperDelete from '../SuccessPopupSuperDelete/SuccessPopupSuperDelete';

const Addgym = ({ onAddGymInPopup, onCheckboxChange, gyms }) => {
  const navigate = useNavigate();

  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [showSecondSuccessPopup, setShowSecondSuccessPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showCheckPopup, setShowCheckPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [emailHistory, setEmailHistory] = useState([]);
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);

  const handleAddGymInPopup = (event) => {
    event.preventDefault();
    const email = event.target.elements.email.value;
    setEmailHistory([...emailHistory, email]);
    setShowSecondSuccessPopup(true);
    setShowSecondPopup(false);
  };

  const handleSecondPopupClose = () => {
    setShowSecondPopup(false);
  };

  const handleCheckboxChange = (index, status) => {
    setSelectedIndex(index);
    onCheckboxChange(index, status);
  };

  const handleCheckClick = () => {
    if (selectedIndex !== null) {
      setShowCheckPopup(true);
    }
  };

  const handleCheckConfirm = () => {
    const updatedGyms = [...gyms];
    updatedGyms[selectedIndex].status = 'Payed';
    // Update gyms state in the parent component
    setShowCheckPopup(false);
  };

  const handleCheckCancel = () => {
    setShowCheckPopup(false);
  };

  const handleSecondSuccessPopupClose = () => {
    setShowSecondSuccessPopup(false);
  };

  const handleDeleteClick = () => {
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = () => {
    const updatedGyms = gyms.filter((_, index) => index !== selectedIndex);
    // Update gyms state in the parent component
    setShowDeletePopup(false);
    setShowDeleteConfirmPopup(true);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const handleViewClick = (gymName) => {
    navigate(`/superadmin/gym/${gymName}`);
  };

  const handleUpdateClick = () => {
    setShowUpdatePopup(true);
  };

  const handleUpdateConfirm = () => {
    setShowUpdatePopup(false);
  };

  const handleUpdateCancel = () => {
    setShowUpdatePopup(false);
  };

  const handleDeleteConfirmPopupClose = () => {
    setShowDeleteConfirmPopup(false);
  };

  return (
    <div className="supp-addgym">
      <div>
        <h2>Add Gym</h2>
        <table className="supp-gymTable">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Pack</th>
              <th>Amount</th>
              <th>Subscription Debut</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {gyms.map((gym, index) => (
              <tr key={index}>
                <td>
                  <input type="checkbox" className="supp-checkbox" onChange={() => handleCheckboxChange(index, gym.status)} />
                  <span>{gym.fullName}</span>
                </td>
                <td>{gym.pack}</td>
                <td className="supp-amount supp-specialAmount">{gym.amount}</td>
                <td>{gym.subscriptionDebut}</td>
                <td>{gym.phoneNumber}</td>
                <td className={gym.status === 'Payed' ? 'supp-status-payed' : 'supp-status-pending'}>{gym.status}</td>
                <td className="supp-action">
                  <div className="supp-actionIcons">
                    <FaEdit onClick={handleUpdateClick} />
                    <FaEye onClick={() => handleViewClick(gym.fullName)} />
                    <FaTrashAlt onClick={handleDeleteClick} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showSecondPopup && (
        <div className="supp-gym-popup-overlay">
          <div className="supp-gym-popup-content">
            <button className="supp-gym-close-button" onClick={handleSecondPopupClose}>
              &times;
            </button>
            <h3>Add Gym</h3>
            <form onSubmit={onAddGymInPopup}>
              <label className="supp-email-address">Email Address</label>
              <input type="email" name="email" className="supp-default-gray supp-email-address" placeholder="Samirloussif@gmail.com" required />
              <label className="supp-pack">Pack</label>
              <select name="pack" className="supp-pack-select supp-pack">
                <option value="Standards">Standards</option>
                <option value="Premium">Premium</option>
                <option value="Basic">Basic</option>
              </select>
              <label className="supp-amount-payed">Amount Payed</label>
              <select name="amount" className="supp-pack-select supp-amount-payed">
                <option value="TND">250 TND</option>
                <option value="USD">500 TND</option>
                <option value="EUR">400 TND</option>
              </select>
              <button type="submit" className="supp-gym-add-event-button">Add Gym</button>
            </form>
          </div>
        </div>
      )}
      {showSecondSuccessPopup && (
        <SuccessPopupSuper
          message="An email has been sent to the email address."
          onClose={handleSecondSuccessPopupClose}
        />
      )}
      {showCheckPopup && (
        <SuccessPopupCheck
          message="By confirming, the gym is registered as paid. Invoice has Been sent to the Gym"
          onConfirm={handleCheckConfirm}
          onCancel={handleCheckCancel}
        />
      )}
      {showDeletePopup && (
        <SuccessPopupSuperDelete
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      {showUpdatePopup && (
        <div className="supp-gym-popup-overlay">
          <div className="supp-gym-popup-content">
            <button className="supp-gym-close-button" onClick={handleUpdateCancel}>
              &times;
            </button>
            <h3>Update Gym</h3>
            <form onSubmit={handleUpdateConfirm}>
              <label className="supp-email-address">Email Address</label>
              <input type="email" className="supp-default-gray supp-email-address" placeholder="Samirloussif@gmail.com" required />
              <label className="supp-pack">Pack</label>
              <select className="supp-pack-select supp-pack">
                <option value="Standards">Standards</option>
                <option value="Premium">Premium</option>
                <option value="Basic">Basic</option>
              </select>
              <label className="supp-amount-payed">Amount Payed</label>
              <select className="supp-pack-select supp-amount-payed">
                <option value="TND">250 TND</option>
                <option value="TND">400 TND</option>
                <option value="TND">300 TND</option>
              </select>
              <button type="submit" className="supp-gym-add-event-button">Update Gym</button>
            </form>
          </div>
        </div>
      )}
      {showDeleteConfirmPopup && (
        <SuccessPopupCheck
          message="The gym has been successfully deleted."
          onConfirm={handleDeleteConfirmPopupClose}
          onCancel={handleDeleteConfirmPopupClose}
        />
      )}
    </div>
  );
};

export default Addgym; 