//src/CoachComponents/Food/Food.jsx
import React, { useState, useEffect } from 'react';
import './Food.css';
import useApi from '../../api/useApi';
import {fetchUser} from "../../api/auth/auth"
const Food = ({ closeModal, onSubmit, existingData }) => {
  const [plateName, setPlateName] = useState('');
  const [ingredients, setIngredients] = useState([{ ingredientName: '', measure: '' }]);
  const [isLoading, setIsLoading] = useState(false);
  const { request } = useApi();

  useEffect(() => {
    if (existingData) {
      setPlateName(existingData.plateName || '');
      setIngredients(existingData.ingredients || [{ ingredientName: '', measure: '' }]);
    }
  }, [existingData]);

  const addIngredient = () => {
    setIngredients([...ingredients, { ingredientName: '', measure: '' }]);
  };

  const removeIngredient = (index) => {
    const updatedIngredients = ingredients.filter((_, i) => i !== index);
    setIngredients(updatedIngredients);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {_id} = await fetchUser()
    const data = { plateName, ingredients,coachId: _id };

    setIsLoading(true);
    try {
      if (existingData) {
       
        await request('put', `nutrition/${existingData._id}`, data);
      } else {
        // Add new data
        await request('post', 'nutrition', data);
      }
      onSubmit(data); // Pass the data to the parent component
      closeModal(); // Close the modal on successful submission
      // Optionally reset form fields
      setPlateName('');
      setIngredients([{ ingredientName: '', measure: '' }]);
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to save nutrition plan. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="food-popup-overlay">
      <div className="food-popup">
        <button className="food-popup-close" onClick={closeModal} aria-label="Close"></button>
        <form onSubmit={handleSubmit} className="food-form-container">
          <h2 className="form-title">{existingData ? 'Edit Nutrition Plan' : 'Add Nutrition Plan'}</h2>

          <div className="input-group">
            <label htmlFor="plateName">Plate Name:</label>
            <input
              id="plateName"
              type="text"
              value={plateName}
              onChange={(e) => setPlateName(e.target.value)}
              required
              placeholder="Enter plate name"
              className="input-field"
            />
          </div>

          {ingredients.map((ingredient, index) => (
            <div key={index} className="ingredient-row">
              <input
                type="text"
                value={ingredient.ingredientName}
                onChange={(e) => {
                  const newIngredients = [...ingredients];
                  newIngredients[index].ingredientName = e.target.value;
                  setIngredients(newIngredients);
                }}
                placeholder="Ingredient Name"
                required
                className="input-field"
              />
              <input
                type="text"
                value={ingredient.measure}
                onChange={(e) => {
                  const newIngredients = [...ingredients];
                  newIngredients[index].measure = e.target.value;
                  setIngredients(newIngredients);
                }}
                placeholder="Measure"
                required
                className="input-field"
              />
              <button
                type="button"
                className="remove-button"
                onClick={() => removeIngredient(index)}
                aria-label={`Remove ingredient ${index + 1}`}
              >
                Remove
              </button>
            </div>
          ))}

        
          <div className="form-actions">
          <button type="button" onClick={addIngredient} className="add-button">
            Add Ingredient
          </button>

            <button type="submit" disabled={isLoading} className="submit-button">
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
            <button type="button" onClick={closeModal} className="cancel-button">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Food;
