import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import styles from './CoachDetails.module.css';

function CoachDetails() {
  const api = useApi();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState(['']); // Initialize with one description
  const [image, setImage] = useState(null); // New image state for file upload
  const [imageUrl, setImageUrl] = useState(''); // State for the image URL
  const [video, setVideo] = useState(''); // State for YouTube video link
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [coachId, setCoachId] = useState(localStorage.getItem('id'));

  useEffect(() => {
    const fetchCoachDetails = async () => {
      try {
        setLoading(true);
        const response = await api.request('GET', `coaches/${coachId}`);
        console.log(response);
        const { name, email, number, address, description, image, video } = response;
        setName(name);
        setEmail(email);
        setNumber(number);
        setAddress(address);
        setDescription(description || ['']);
        setImageUrl(image);
        setVideo(video || ''); // Set the video link if it exists
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCoachDetails();
  }, []);

  const handleUpdateCoach = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('number', number);
      formData.append('address', address);
      formData.append('description', description[0]); // Send description as the first element of the array
      formData.append('video', video); // Add the video URL to formData

      if (image) {
        formData.append('image', image);
      }

      await api.request('PUT', `coaches/${coachId}`, formData, {
        'Content-Type': 'multipart/form-data',
      });

      alert('Mise à jour réussie');
    } catch (err) {
      console.error('Erreur lors de la mise à jour :', err);
      alert('Erreur lors de la mise à jour');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.CoachDC}>
      <div className={styles.CoachDetails}>
        <h2 className={styles.formTitle}>Détails du Coach</h2>
        {loading && <p>Chargement...</p>}
        {error && <p>Erreur : {error.message}</p>}
        
        {/* Display the current image if it exists */}
        {imageUrl && (
          <div className={styles.imagePreview}>
            <img src={imageUrl} alt="Coach" className={styles.coachImage} />
          </div>
        )}

        <form className={styles.FormContainer} onSubmit={handleUpdateCoach}>
          <div className={styles.formGroup}>
            <label htmlFor="coachName">Nom du Coach*</label>
            <input
              type="text"
              id="coachName"
              placeholder="samir loussif"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              id="email"
              placeholder="samirloussif@contact.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="phoneNumber">Numéro de Téléphone (Corporatif)*</label>
            <input
              type="tel"
              id="phoneNumber"
              placeholder="0000000000"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="address">Adresse</label>
            <input
              type="text"
              id="address"
              placeholder="Sousse, Rue Jaafar"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="description">Description*</label>
            <textarea
              id="description"
              placeholder="Description"
              value={description[0] || ''}
              onChange={(e) => setDescription([e.target.value])}
              required
            ></textarea>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="video">Lien YouTube (Vidéo)</label>
            <input
              type="url"
              id="video"
              placeholder="https://www.youtube.com/watch?v=example"
              value={video}
              onChange={(e) => setVideo(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="image">Télécharger une nouvelle image</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
          <div className={styles.BtnC}>
            <button type="submit" className={styles.saveButton} disabled={loading}>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CoachDetails;
