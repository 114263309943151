import React, { useEffect, useState } from 'react';
import useApi from '../../../api/useApi'; 
import './CreatePack.css'; 
import Addpack from './Addpack';

const CreatePack = () => {
  const [packs, setPacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editPack, setEditPack] = useState(null); 
  const [openIndex, setOpenIndex] = useState(null); 
  const { request } = useApi();

  useEffect(() => {
    const fetchUserPacks = async () => {
      setLoading(true);
      const userId = localStorage.getItem('id'); // Récupération de coachId
      try {
        const response = await request('GET', `userpacks/user/${userId}`); 
        setPacks(response);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserPacks();
  }, [request]);

  const handleAddPack = async (newPack) => {
    const userId = localStorage.getItem('id'); // Récupération de userId
    if (!userId || typeof userId !== 'string') {
      console.error('Invalid userId:', userId);
      return; // Sortir si userId n'est pas valide
    }
    
    // Ajout de userId à newPack
    const packToAdd = {
      ...newPack,
      userId: userId, // Assurez-vous que c'est le bon nom de champ
    };

    try {
      const addedPack = await request('POST', 'userpacks', packToAdd); 
      setPacks((prevPacks) => [...prevPacks, addedPack]);
    } catch (error) {
      console.error('Error adding pack:', error);
    }
  };

  const handleEditPack = async (updatedPack) => {
    try {
      const response = await request('PUT', `userpacks/${updatedPack._id}`, updatedPack); 
      setPacks((prevPacks) =>
        prevPacks.map((pack) => (pack._id === response._id ? response : pack))
      );
      setEditPack(null); 
    } catch (error) {
      console.error('Error editing pack:', error);
    }
  };

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index); 
  };

  return (
    <div className="create-pack-container">
      <h2>User Packs</h2>
      <button className="add-pack-button" onClick={() => { setIsModalOpen(true); setEditPack(null); }}>
        Add Pack Users
      </button>
      <Addpack
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onAddPack={handleAddPack} 
        editPack={editPack}
        onEditPack={handleEditPack}
      />
      <div className="packs-accordion">
        {loading && <p>Loading...</p>} {/* Affichage du chargement */}
        {error && <p>{error}</p>} {/* Affichage de l'erreur */}
        {packs.length === 0 ? (
          <p>Aucun pack trouvé.</p>
        ) : (
          packs.map((pack, index) => (
            <div className="pack-accordion-item" key={pack._id}>
              <div className="pack-header" onClick={() => toggleAccordion(index)}>
                <h3 className="pack-name">{pack.name}</h3>
                <span className="accordion-toggle">
                   {openIndex === index ? 'v' : '^'} 
                </span>
              </div>
              {openIndex === index && (
                <div className="pack-content">
                  <p>Duration: <span className="pack-duration">{pack.duration}</span></p>
                  <p>Price: <span className="pack-price">{pack.price} DT</span></p>
                  <p>Description: <span className="pack-description">{pack.description}</span></p>
                  <p>Permissions: <span className="pack-permissions">{pack.userPermission.join(', ')}</span></p>
                  <p>Type of Coaching: <span className="pack-coaching">{pack.TypeCoaching.join(', ')}</span></p>
                  <p>Type of Présence: <span className="pack-presence">{pack.TypePresence.join(', ')}</span></p>
                  <p>Promotion: <span className={`pack-promotion ${pack.promotionActive ? 'active' : 'inactive'}`}>
                    {pack.promotionActive ? `Active (${pack.promotionPercentage}%)` : 'Inactive'}
                  </span></p>
                  <div className="pack-actions">
                    <button onClick={() => { setEditPack(pack); setIsModalOpen(true); }}>Edit</button>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CreatePack;
