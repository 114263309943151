import React from 'react';
import { Outlet } from 'react-router-dom';
import SuperadminSidebar from './Sidebar/supersidebar'; 
import Navbar from '../../SuperComponents/Layout/Navbar';
import Footer from '../../Components/Layout/Footer';
import './Layout.css';  

const SuperadminLayout = () => {
  return (
    <div className="suplay-layout">
      <Navbar className="suplay-navbar" />
      <div className="suplay-main-content">
        <SuperadminSidebar className="suplay-sidebar" />
        <div className="suplay-content-wrapper">
          <div className="suplay-content">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer className="suplay-footer" />
    </div>
  );
};

export default SuperadminLayout;