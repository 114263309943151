import React, { useEffect, useState } from 'react';
import useApi from '../../../api/useApi';
import SuperAddCoach from './SuperAddCoach/SuperAddCoach';
import SuccessPopup from './SuccessPopup/SuccessPopup';
import { MdAddBox } from 'react-icons/md';
import './Coaches.css';

const Coaches = () => {
  const { request } = useApi();
  const [subscriptions, setSubscriptions] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [coachData, setCoachData] = useState({
    email: '',
    pack: '',
    subscriptionStatus: 'pending',
    paymentStatus: 'paid',
    startDate: '',
    endDate: '',
    coachId: '',
  });
  const [packs, setPacks] = useState([]);
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const response = await request('get', '/admin/subCoach');
      if (response) {
        setSubscriptions(response);
      }
    };

    const fetchPacks = async () => {
      const response = await request('get', '/coachpacks');
      if (response) {
        setPacks(response);
      }
    };

    const fetchCoaches = async () => {
      const response = await request('get', '/coaches');
      if (response) {
        setCoaches(response);
      }
    };

    fetchSubscriptions();
    fetchPacks();
    fetchCoaches();
  }, [request]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleFirstPopupSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const newCoach = {
        name: coachData.email.split('@')[0],
        email: coachData.email,
        roles: ['coach'],
      };
  
      const response = await request('post', '/auth/AddUserByadmin', newCoach);
        console.log(response);
      if (response && response._id) {
        setCoachData((prev) => ({ ...prev, coachId: response._id }));
        setShowPopup(false);
        setShowSecondPopup(true); // Proceed to the second popup
      } else {
        alert('Coach ID missing in response. Please try again.');
      }
    } catch (error) {
      console.error('Error adding coach:', error);
      alert('Failed to add coach. Please try again.');
    }
  };
  
  

  const handleAddCoach = async (e) => {
    e.preventDefault();
    try {
      const selectedPack = packs.find(pack => pack.name === coachData.pack);
      if (!selectedPack) {
        alert('Please select a valid pack.');
        return;
      }

      const startDate = new Date(coachData.startDate).toISOString();
      const endDate = new Date(coachData.endDate).toISOString();

      const newSubscription = {
        packId: selectedPack._id,
        startDate: startDate,
        endDate: endDate,
        subscriptionStatus: coachData.subscriptionStatus,
        paymentStatus: coachData.paymentStatus,
        coachId: coachData.coachId,
      };

      const response = await request('post', '/admin/subCoach', newSubscription);
      if (response) {
        setSubscriptions([...subscriptions, response]);
        setShowSecondPopup(false);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error('Error adding coach:', error);
    }
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const handleDeleteCoach = async (index) => {
    const subscriptionId = subscriptions[index]._id;
    const response = await request('delete', `/admin/subCoach/${subscriptionId}`);
    if (response && response.success) {
      const updatedSubscriptions = subscriptions.filter((_, i) => i !== index);
      setSubscriptions(updatedSubscriptions);
    }
  };

  const handleUpdateCoach = async (index, updatedData) => {
    const subscriptionId = subscriptions[index]._id;
    const response = await request('put', `/admin/subCoach/${subscriptionId}`, updatedData);
    if (response) {
      const updatedSubscriptions = [...subscriptions];
      updatedSubscriptions[index] = response;
      setSubscriptions(updatedSubscriptions);
    }
  };

  return (
    <div>
      <SuperAddCoach
        subscriptionData={subscriptions}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        onDeleteCoach={handleDeleteCoach}
        onUpdateCoach={handleUpdateCoach}
        packsList={packs}
        coachesList={coaches}
      />

      <div className="adco-addCoachButtonContainer">
        {checkedItems.length > 0 ? (
          <button className="adco-addCoachButton" onClick={() => alert('Check functionality')}>
            Check
          </button>
        ) : (
          <button className="adco-addCoachButton" onClick={togglePopup}>
            <MdAddBox className="adco-plusIcon" />
            Add Coach
          </button>
        )}
      </div>

      {showPopup && (
        <div className="adco-popup-overlay">
          <div className="adco-popup-content">
            <button className="adco-close-button" onClick={togglePopup}>
              &times;
            </button>
            <div className="adco-popup-title">Add Coach</div>
            <form onSubmit={handleFirstPopupSubmit}>
              <label className="adco-email-address">Email Address</label>
              <input type="email" name="email" className="adco-email-input" placeholder="coach@example.com" value={coachData.email} onChange={e => setCoachData({ ...coachData, email: e.target.value })} required />
              <div className="adco-center-button">
                <button type="submit" className="adco-add-event-button">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showSecondPopup && (
        <div className="adco-popup-overlay">
          <div className="adco-popup-content">
            <button className="adco-close-button" onClick={() => setShowSecondPopup(false)}>
              &times;
            </button>
            <div className="adco-popup-title">Add Coach</div>
            <form onSubmit={handleAddCoach}>
              <label className="adco-pack">Pack</label>
              <select name="pack" className="adco-pack-select" value={coachData.pack} onChange={e => setCoachData({ ...coachData, pack: e.target.value })} required>
                <option value="">Select a Pack</option>
                {packs.map(pack => (
                  <option key={pack._id} value={pack.name}>{pack.name}</option>
                ))}
              </select>
              <label className="adco-subscription-status">Subscription Status</label>
              <select name="subscriptionStatus" className="adco-subscription-status-select" value={coachData.subscriptionStatus} onChange={e => setCoachData({ ...coachData, subscriptionStatus: e.target.value })}>
                <option value="pending">Pending</option>
                <option value="accepted">Accepted</option>
                <option value="rejected">Rejected</option>
                <option value="expired">Expired</option>
              </select>
              <label className="adco-payment-status">Payment Status</label>
              <select name="paymentStatus" className="adco-payment-status-select" value={coachData.paymentStatus} onChange={e => setCoachData({ ...coachData, paymentStatus: e.target.value })}>
                <option value="paid">Paid</option>
                <option value="not_paid">Not Paid</option>
              </select>
              <label className="adco-start-date">Start Date</label>
              <input type="date" name="startDate" className="adco-start-date-input" value={coachData.startDate} onChange={e => setCoachData({ ...coachData, startDate: e.target.value })} required />
              <label className="adco-end-date">End Date</label>
              <input type="date" name="endDate" className="adco-end-date-input" value={coachData.endDate} onChange={e => setCoachData({ ...coachData, endDate: e.target.value })} required />
              <div className="adco-center-button">
                <button type="submit" className="adco-add-event-button">
                  Add Coach
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showSuccessPopup && (
        <SuccessPopup 
          message="Coach added successfully!" 
          onClose={closeSuccessPopup} 
        />
      )}
    </div>
  );
};

export default Coaches;