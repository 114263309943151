import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Image, H5 } from '../../../../AbstractElements';
import RadialChart from '../RadialChart';
import defaultImage from '../../../../assets/images/avtar/11.jpg'; // add a default image
import './Socialwidget.css';

const SocialWidget = ({ data }) => {
  const imageSrc = data && data.image ? require(`../../../../assets/images/dashboard-5/social/${data.image}`) : defaultImage;

  return (
    <Card className='social-widget widget-hover'>
      <CardBody>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <div className='social-icons'>
              <Image attrImage={{ src: imageSrc, alt: 'social icon' }} />
            </div>
            <span>{data?.title || 'Default Title'}</span>
          </div>
          <span className='font-success f-12 d-xxl-block d-xl-none'>+{data?.gros || 0}%</span>
        </div>
        <div className='social-content'>
          <div>
            <H5 attrH5={{ className: 'mb-1' }}>{data?.total || 0}</H5>
            <span className='f-light'>{data?.subTitle || 'Default Subtitle'}</span>
          </div>
          <div className='social-chart'>
            
            <RadialChart chartData={data?.chart || { series: [0] }} />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default SocialWidget;
