import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Details from './Details/Details';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import './Supergymdetails.css';
import { RiFileEditFill } from 'react-icons/ri';
import SuccessPopupSuper from '../../../../SuperComponents/SuperDashboard/Gym/SuccessPopupSuper/SuccessPopupSuper';

const Supergymdetails = () => {
  const { gymName } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [successPopupIsOpen, setSuccessPopupIsOpen] = useState(false);

  const handleEditClick = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="sgd-page-container">
      <nav>
        <ol className="sgd-breadcrumb">
          <li className="sgd-breadcrumb-item"><a href="/superadmin/gym">Gym</a></li>
          <li className="sgd-breadcrumb-item active" aria-current="page">{gymName}</li>
        </ol>
      </nav>
      <div>
        <Details />
      </div>
      <div>
        <PaymentHistory />
      </div>
      <div className="button-container">
        <button className="edit-button" onClick={handleEditClick}>
          <RiFileEditFill />
          Edit Plan
        </button>
      </div>
      {modalIsOpen && (
        <Popup
          isOpen={modalIsOpen}
          onClose={closeModal}
          onConfirm={() => {
            setSuccessPopupIsOpen(true);
            closeModal();
          }}
        />
      )}
      {successPopupIsOpen && (
        <SuccessPopupSuper
          message="Plan updated successfully!"
          onClose={() => setSuccessPopupIsOpen(false)}
        />
      )}
    </div>
  );
};

const Popup = ({ isOpen, onClose, onConfirm }) => {
  const [email, setEmail] = useState('');
  const [selectedPack, setSelectedPack] = useState('Pack 1');
  const [amountPaid, setAmountPaid] = useState('Amount 1');

  const handleConfirm = () => {
    if (!email || !selectedPack || !amountPaid) {
      alert('Please fill in all fields');
      return;
    }

    console.log('Email:', email);
    console.log('Selected Pack:', selectedPack);
    console.log('Amount Paid:', amountPaid);
    onConfirm();
  };

  if (!isOpen) return null;

  return (
    <div className="ppopup-overlay">
      <div className="ppopup-content">
        <div className="ppopup-header">
          <div className="ppopup-title-container">
            <h2>Edit Plan</h2>
          </div>
          <button className="pclose-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="ppopup-body">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            required
          />
          <label htmlFor="ppack-select">Pack</label>
          <select
            id="ppack-select"
            value={selectedPack}
            onChange={(e) => setSelectedPack(e.target.value)}
            required
          >
            <option value="Pack 1">Pack 1</option>
            <option value="Pack 2">Pack 2</option>
            <option value="Pack 3">Pack 3</option>
          </select>
          <label htmlFor="amount-paid-select">Amount Paid</label>
          <select
            id="amount-paid-select"
            value={amountPaid}
            onChange={(e) => setAmountPaid(e.target.value)}
            required
          >
            <option value="Amount 1">Amount 1</option>
            <option value="Amount 2">Amount 2</option>
            <option value="Amount 3">Amount 3</option>
          </select>
        </div>
        <div className="ppopup-footer">
          <button className="ppop-cancel-btn" onClick={onClose}>
            Cancel
          </button>
          <button className="ppop-confirm-btn" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Supergymdetails;