// src/Components/Dashboard/Overview/Components/TopCoachs/topcoachs.js
import React from 'react';
import { Link } from 'react-router-dom';
import './topcoachs.css'; 

const TopCoachs = () => {
  const coaches = [
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', trainee: 20, income: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', trainee: 20, income: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', trainee: 20, income: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', trainee: 20, income: '250 TND' },
  ];

  return (
    <div className="topCoachs">
      <div className="topCoachs-header">
        <h2>Top Coachs</h2>
        <div className="topCoachs-showMore">
          <Link to="/Overview/coachsdetails">Show More</Link>
        </div>
      </div>
      <table className="topCoachs-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Trainee</th>
            <th>Income</th>
          </tr>
        </thead>
        <tbody>
          {coaches.map((coach, index) => (
            <tr key={index}>
              <td className="topCoachs-name">{coach.name}</td>
              <td className="topCoachs-email">{coach.email}</td>
              <td className="topCoachs-phoneNumber">{coach.phoneNumber}</td>
              <td className="topCoachs-trainee">{coach.trainee}</td>
              <td className="topCoachs-income">{coach.income}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopCoachs;