import React, { useState } from 'react';
import './gym.css';  
import FilterGym from '../../../Components/Dashboard/Gym/FilterGym/filtergym';
import AddGym from './AddGym/Addgym';
import { RiAddBoxFill } from 'react-icons/ri';  

const Gym = () => {
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [isPendingSelected, setIsPendingSelected] = useState(false);
  const [gyms, setGyms] = useState([
    { fullName: 'California Gym', pack: 'Standards', amount: '250 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Payed' },
    { fullName: 'California Gym', pack: 'Standards', amount: '500 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Payed' },
    { fullName: 'California Gym', pack: 'Standards', amount: '200 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Pending' },
    { fullName: 'California Gym', pack: 'Standards', amount: '400 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Pending' },
    { fullName: 'California Gym', pack: 'Standards', amount: '250 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Payed' },
    { fullName: 'California Gym', pack: 'Standards', amount: '500 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Payed' },
    { fullName: 'California Gym', pack: 'Standards', amount: '200 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Pending' },
    { fullName: 'California Gym', pack: 'Standards', amount: '400 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Pending' },
    { fullName: 'California Gym', pack: 'Standards', amount: '250 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Payed' },
    { fullName: 'California Gym', pack: 'Standards', amount: '400 TND', subscriptionDebut: '12/02/2024', phoneNumber: '+21625178855', status: 'Pending' },
  ]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleAddSubscriptionClick = () => {
    setShowSecondPopup(true);
  };

  const handleSecondPopupClose = () => {
    setShowSecondPopup(false);
  };

  const handleAddGymInPopup = (event) => {
    event.preventDefault();
    const email = event.target.elements.email.value;
     console.log('Email:', email);
    setShowSecondPopup(false);
  };

  const handleCheckboxChange = (index, status) => {
    setSelectedIndex(index);
    setIsPendingSelected(status === 'Pending');
  };

  const handleCheckClick = () => {
    if (selectedIndex !== null) {
      const updatedGyms = [...gyms];
      updatedGyms[selectedIndex].status = 'Payed';
      setGyms(updatedGyms);
    }
  };

  return (
    <div className="sup-gym-container">
      <FilterGym />
      <AddGym onAddGymInPopup={handleAddGymInPopup} onCheckboxChange={handleCheckboxChange} gyms={gyms} />
      <div className="supp-buttonContainer">
        <button
          className="supp-addSubscriptionButton"
          onClick={isPendingSelected ? handleCheckClick : handleAddSubscriptionClick}
        >
          {isPendingSelected ? 'Check' : <><RiAddBoxFill /> Add Gym</>}
        </button>
      </div>
      {showSecondPopup && (
        <div className="supp-gym-popup-overlay">
          <div className="supp-gym-popup-content">
            <div className="supp-gym-popup-header">
              <h3>Add Gym</h3>
              <button className="supp-gym-close-button" onClick={handleSecondPopupClose}>
                &times;
              </button>
            </div>
            <form onSubmit={handleAddGymInPopup}>
              <label className="suppp-email-address">Email Address</label>
              <input type="email" name="email" className="supp-default-gray supp-email-address" placeholder="Samirloussif@gmail.com" required />
              <label className="suppp-pack">Pack</label>
              <select name="pack" className="supp-pack-select supp-pack">
                <option value="Standards">Standards</option>
                <option value="Premium">Premium</option>
                <option value="Basic">Basic</option>
              </select>
              <label className="suppp-amount-payed">Statut</label>
              <select name="amount" className="supp-pack-select supp-amount-payed">
                <option value="TND">Payed</option>
                <option value="USD">Pending</option>
               </select>
              <button type="submit" className="supp-gym-add-event-button">Add Gym</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gym;