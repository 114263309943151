import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../../api/useApi';
import session from '../../modules/session';
import './login.css';
import SaraImage from '../../assets/images/Sara.jpg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [formError, setFormError] = useState('');
  const { loading, request } = useApi();
  const navigate = useNavigate();

  const validateForm = () => {
    if (!email || !password) {
      setFormError('Email and password are required.');
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setFormError('Please enter a valid email address.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const response = await request('post', '/auth/login', {
        email,
        password,
      });

      if (response && response.accessToken) {
        localStorage.setItem('accessToken', response.accessToken);
        if (!rememberMe) {
          session.set('accessToken', response.accessToken);
        }
        await fetchUserInfo(response.accessToken);
      } else {
        setFormError('Invalid login credentials.');
      }
    } catch (err) {
      console.error('Login error:', err);
      setFormError('An error occurred during login.');
    }
  };

  const fetchUserInfo = async (token) => {
    try {
      const userResponse = await request('get', `/auth/load/me?token=${token}`, {}, {
        Authorization: `Bearer ${token}`,
      });

      if (userResponse) {
        console.log('User info:', userResponse);
        localStorage.setItem('id', userResponse._id);
        const role = userResponse.roles[0]?.toLowerCase(); 
        console.log('User role:', role);
        handleRedirection(role);
      }
    } catch (err) {
      console.error('Failed to load user info:', err);
      setFormError('Failed to load user information.');
    }
  };

  const handleRedirection = (role) => {
    switch (role) {
      case 'superadmin':
        navigate('/Overviewsuperadmin');
        break;
      case 'respgym':
        navigate('/overview');
        break;
      case 'coach':
        navigate('/Overviewcoach');
        break;
      case 'user':
        navigate('/user/training'); 
        break;
      default:
        navigate('/logo');
        break;
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <div className="page-container">
      <div className="login-wrapper" style={{ backgroundImage: `url(${SaraImage})` }}>
        <div className="login-container">
          <div className="login-logo">Login</div>
          <div className="login-welcome">Welcome Back</div>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="login-input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="login-input-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <div className="forgot-password" onClick={handleForgotPassword}>
                Forgot Password?
              </div>
            </div>
            <div className="login-checkbox-group">
              <input
                type="checkbox"
                id="rememberMe"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label htmlFor="rememberMe">
                By selecting this box, you are accepting our Terms of Use and confirming that you have read our policy in full.
              </label>
            </div>
            {formError && <div className="login-error">{formError}</div>}
            <button type="submit" className="login-button" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
