// src/Route/UserLayoutRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserSchedule from '../UserComponents/UserDashboard/UserSchedule/UserSchedule';
import UserLayout from '../UserComponents/Layout/UserLayout';
import Contact from '../UserComponents/UserDashboard/Contact/contact';
import FAQ from '../UserComponents/UserDashboard/FAQ/faq';
import Account from '../UserComponents/UserDashboard/Profile/Account/account';
import PlanningSch from '../UserComponents/UserDashboard/UserSchedule/PlanningSch/PlanningSch'; 
import DeleteAccount from '../UserComponents/UserDashboard/Profile/Delete/delete';
import Mail from '../UserComponents/UserDashboard/Profile/Mailsettings/mail';
import Goal from '../UserComponents/UserDashboard/Profile/Goal/goal';
import UserTraining from '../UserComponents/UserDashboard/UserTraining/UserTraining';
import TrainingGymDetails from '../UserComponents/UserDashboard/TrainingGymDetails/TrainingGymDetails';
import TrainingCoachsDetails from '../UserComponents/UserDashboard/TrainingCoachsDetails/TrainingCoachsDetails';
import Profile from '../Components/Dashboard/Coaches/Profile/profile';

const UserLayoutRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserLayout/>}>
        <Route path="schedule" element={<UserSchedule />} />
        <Route path="training" element={<UserTraining />} />
        <Route path="training/gym/:gymName" element={<TrainingGymDetails />} />
        <Route path="/coaches/Add/:name" element={<Profile />} />
        <Route path="training/Coach/:coachName" element={<TrainingCoachsDetails />} />
        <Route path="contact" element={<Contact />} />
        <Route path="FAQ" element={<FAQ />} />
        <Route path="Account" element={<Account/>} />
        <Route path="planning-sch" element={<PlanningSch />} />
        <Route path="delete" element={<DeleteAccount />} />
        <Route path="mailsettings" element={<Mail />} />
        <Route path="goalsettings" element={<Goal/>} />
        <Route path="/coaches/profile" element={<Profile />} /> 
      </Route>
    </Routes>
  );
};

export default UserLayoutRoutes;