import React, { useContext, useState, useEffect } from 'react';
import { IoNotificationsOutline } from 'react-icons/io5';
import './UserNavbar.css';
import { PageTitleUserContext } from '../../UserContext/PageTitleUserContext';
import { AuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../api/useApi';
import constantImages from '../../../ConstantImages/constantimages';

const UserNavbar = () => {
  const { pageTitle } = useContext(PageTitleUserContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const { request } = useApi();
  const [packName, setPackName] = useState('Silver'); 

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    logout();  
    navigate('/');  
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const userId = localStorage.getItem('id'); 
        const subscriptionsResponse = await request('get', `/subUser/user/${userId}`);
        if (subscriptionsResponse.length > 0) {
          const lastSubscription = subscriptionsResponse[subscriptionsResponse.length - 1]; 
          const packName = lastSubscription.packId.name;
          setPackName(packName);
        }
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
    };

    fetchSubscription();
  }, [request]);

  return (
    <nav className="navuse-navbar">
      <div className="navuse-navbar-left">
        <span className="navuse-navbar-title">{pageTitle}</span>
      </div>
      <div className="navuse-navbar-right">
        <span className="navuse-navbar-pack">Votre Pack : {packName}</span>
        <IoNotificationsOutline className="navuse-bell-icon" />
        <div className="navuse-profile-icon-container" onClick={toggleDropdown}>
          <img src={constantImages.profile} alt="Profile Icon" className="navuse-profile-icon" /> 
          {isDropdownOpen && (
            <div className="navuse-dropdown">
              <ul>
                <li onClick={handleLogout}>Deconnexion</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default UserNavbar;