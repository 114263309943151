// activities.js

import React, { useState, useEffect } from 'react';
import './activities.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import AddActivity from './addact';
import { MdAddBox } from 'react-icons/md'; // Import the MdAddBox icon
import constantImages from '../../../../ConstantImages/constantimages';

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editActivityId, setEditActivityId] = useState(null);

  useEffect(() => {
    const fetchActivities = async () => {
      const fetchedActivities = await new Promise((resolve) => {
        setTimeout(() => {
          resolve([
            { id: 1, name: 'RPM', description: 'Indoor cycling workout', visibility: 'visible', date: '12/05/2024', image: constantImages.product },
            { id: 2, name: 'Yoga', description: 'Relaxing and meditative', visibility: 'visible', date: '15/05/2024', image: constantImages.product }
          ]);
        }, 1000);
      });
      setActivities(fetchedActivities);
    };

    fetchActivities();
  }, []);

  const toggleVisibility = (id) => {
    setActivities(activities.map(activity => 
      activity.id === id ? { ...activity, visibility: activity.visibility === 'visible' ? 'Invisible' : 'visible' } : activity
    ));
  };

  const deleteActivity = (id) => {
    setActivities(activities.filter(activity => activity.id !== id));
  };

  const openModal = (id = null) => {
    setEditActivityId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditActivityId(null);
  };

  const addOrUpdateActivity = (activity) => {
    if (editActivityId) {
      setActivities(activities.map(act => act.id === editActivityId ? activity : act));
    } else {
      setActivities([...activities, { ...activity, id: Date.now() }]);
    }
    closeModal();
  };

  return (
    <div className="activv-activities">
      <table className="activv-table">
        <thead>
          <tr className="activv-header-row">
            <th className="activv-th">Activity Name</th>
            <th className="activv-th">Description</th>
            <th className="activv-th">Visibility</th>
            <th className="activv-th">Date of Creation</th>
            <th className="activv-th">Actions</th>
          </tr>
        </thead>
        <tbody>
          {activities.map(activity => (
            <tr key={activity.id}>
              <td className="activv-td activv-activity-name">
                <img src={activity.image} alt={activity.name} className="activv-activity-image" />
                {activity.name}
              </td>
              <td className="activv-td">{activity.description}</td>
              <td className="activv-td">
                <button 
                  className={`activv-visibility-btn ${activity.visibility}`} 
                  onClick={() => toggleVisibility(activity.id)}
                >
                  {activity.visibility === 'visible' ? 'Visible' : 'Invisible'}
                </button>
              </td>
              <td className="activv-td">{activity.date}</td>
              <td className="activv-td">
                <div className="activv-action-buttons">
                  <button onClick={() => openModal(activity.id)} className="activv-icon-link">
                    <span className="activv-icon-circle">
                      <FontAwesomeIcon icon={faEdit} className="activv-edit-icon" />
                    </span>
                  </button>
                  <button className="activv-del-btn" onClick={() => deleteActivity(activity.id)}>
                    <span className="activv-icon-circle">
                      <FontAwesomeIcon icon={faTrash} className="activv-delete-icon" />
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='activv-addactcontainer'>
        <button className="activv-addbtn" onClick={() => openModal(null)}>
          <MdAddBox /> Add
        </button>
      </div>

      {showModal && (
        <div className="modal-backdrop">
          <AddActivity 
            activities={activities} 
            setActivities={setActivities} 
            id={editActivityId} 
            closeModal={closeModal} 
            addOrUpdateActivity={addOrUpdateActivity}
          />
        </div>
      )}
    </div>
  );
}

export default Activities;