import React from 'react';

import './welcome.css';

import { useNavigate } from 'react-router-dom';


  
const Welcome = () => {
  
  const navigate = useNavigate();
  const handleNext = () => {
    navigate('/overview'); 
  };

  return (
    <div className="welcome-container">
      <div className="welcome-card">
        <img src='./check.png' alt="Check Icon" className="welcome-check-icon" />
        <p className="welcome-message">Thank you for your enthusiasm and welcome to Gym'sClub.</p>
        <button className="welcome-button" onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default Welcome;
