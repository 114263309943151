import React, { useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './gender.css';

const Gender = () => {

  const maleCount = 750;
  const femaleCount = 300;
  const total = maleCount + femaleCount;
  const malePercentage = (maleCount / total) * 100;
  const femalePercentage = (femaleCount / total) * 100;

  const [hovered, setHovered] = useState(false);

  return (
    <div className="gender-container">
      <h2>Gender</h2>
      <p>Total : {total}</p>
      <div
        className="circular-progress"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <CircularProgressbarWithChildren
          value={femalePercentage}
          styles={buildStyles({
            textColor: 'black',
            pathColor: '#FFA941',
            trailColor: '#7366FF',
            
            
          })}
        >
          <div className="percentage-text">
            {hovered ? `${malePercentage.toFixed(0)}% Male` : `${femalePercentage.toFixed(0)}% Female`}
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <div className="legend">
        <div className="female">
          <span className="dot female-dot"></span>
          <span>({femaleCount})Female</span>
        </div>
        <div className="male">
          <span className="dot male-dot"></span>
          <span>({maleCount})Male</span>
        </div>
      </div>
    </div>
  );
};

export default Gender;
