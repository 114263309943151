import React, { useState, useContext } from 'react';
import './Navbar.css';
import { AuthContext } from '../../../context/AuthContext'; 
import { useNavigate } from 'react-router-dom'; 
import { useCoachPageTitle } from '../../context/CoachPageTitleContext';
import { IoNotificationsOutline } from "react-icons/io5";
import { BiMessageAltDetail } from "react-icons/bi"; 
import { CgProfile } from "react-icons/cg"; 

const Navbar = () => {
  const { pageTitle } = useCoachPageTitle();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    logout(); 
    navigate('/'); 
  };

  return (
    <nav className="navgy-navbar">
      <div className="navgy-navbar-left">
        <span className="navgy-navbar-title">{pageTitle}</span>
      </div>
      <div className="navgy-navbar-right">
        <span className="navgy-navbar-pack">Votre Pack : Silver</span>
        <BiMessageAltDetail className="chat-icon" /> 
        <IoNotificationsOutline className="navgy-bell-icon" /> 
        <span className="navgy-navbar-welcome">Welcome</span>
        <div className="navgy-profile-icon-container" onClick={toggleDropdown}>
          <CgProfile className="navgy-profile-icon" /> 
          {isDropdownOpen && (
            <div className="navgy-dropdown">
              <ul>
                <li>
                  <a href="/coach/Settings/accoundetails">Profile</a>
                </li>
                <li onClick={handleLogout}>Deconnexion</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;