import React, { useEffect } from 'react';
import './SuccessPopupSuper.css';

const SuccessPopupSuper = ({ message, onClose }) => {
    useEffect(() => {
        document.body.classList.add('blurred');

        return () => {
            document.body.classList.remove('blurred');
        };
    }, []);

    return (
        <div className="s-success-popup-super-overlay">
            <div className="s-success-popup-super-content">
                <div className="s-success-popup-super-message">
                    <div className="s-checkmark-icon-super">&#10003;</div>
                    <p>{message}</p>
                </div>
                <button className="s-done-button-super" onClick={onClose}>Done</button>
            </div>
        </div>
    );
};

export default SuccessPopupSuper;