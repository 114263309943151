import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useApiWithErrors from '../../../../api/useApiWithErrors';
import Detailsco from './Detailsco/Detailsco';
import PaymentHistoryco from './PaymentHistoryco/PaymentHistoryco';
import './SuperCoachDetails.css';

const SuperCoachDetails = () => {
  const location = useLocation();
  const { apiRequest, error } = useApiWithErrors();
  const [coachDetails, setCoachDetails] = useState({});
  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    const fetchCoachDetails = async () => {
      const coachId = location.state?.coachId;
      if (coachId) {
        try {
          const response = await apiRequest('get', `/coaches/${coachId}`);
          if (response) {
            setCoachDetails(response);
          }
        } catch (err) {
          console.error('Error fetching coach details:', err);
        }
      }
    };

    const fetchPaymentHistory = async () => {
      const coachId = location.state?.coachId;
      if (coachId) {
        try {
          const response = await apiRequest('get', `/users/${coachId}`);
          if (response && response.subscriptions) {
            const subscriptionsWithPackNames = await Promise.all(response.subscriptions.map(async (subId) => {
              const subResponse = await apiRequest('get', `/admin/subCoach/${subId}`);
              const packResponse = await apiRequest('get', `/coachpacks/${subResponse.packId}`);
              return {
                ...subResponse,
                packName: packResponse ? packResponse.name : 'Unknown Pack',
              };
            }));
            setPaymentHistory(subscriptionsWithPackNames);
          }
        } catch (err) {
          console.error('Error fetching payment history:', err);
        }
      }
    };

    fetchCoachDetails();
    fetchPaymentHistory();
  }, []);

  return (
    <div className="scd-container">
      {error && <div className="error-message">{error}</div>}
      <div className="scd-content">
        <Detailsco coachDetails={coachDetails} />
        <PaymentHistoryco paymentHistory={paymentHistory} />
      </div>
    </div>
  );
};

export default SuperCoachDetails;