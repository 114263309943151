// src/UserComponents/Layout/UserSidebar/UserSidebar.js
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdLibraryAddCheck } from 'react-icons/md';
import { CiDumbbell } from 'react-icons/ci';
import { AiOutlineContacts } from 'react-icons/ai';
import { LuLifeBuoy } from 'react-icons/lu';
import { CgProfile } from "react-icons/cg";
 import './UserSidebar.css';
import { PageTitleUserContext } from '../../UserContext/PageTitleUserContext';

const UserSidebar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeLink, setActiveLink] = useState('/user/home');
  const navigate = useNavigate();
  const { setPageTitle } = useContext(PageTitleUserContext);

  const toggleMenu = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu(null);
    } else {
      setActiveMenu(menu);

      if (menu === 'profile') {
        setActiveLink('/user/account');
        navigate('/user/account');
        setPageTitle('Profile');
      }
    }
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);

    if (link === '/user/schedule') {
      setPageTitle('Schedule');
    } else if (link === '/user/training') {
      setPageTitle('Training');
    } else if (link === '/user/faq') {
      setPageTitle('FAQ');
    } else if (link === '/user/contact') {
      setPageTitle('Contact');
    } else if (link.startsWith('/user/account')) {
      setPageTitle('Profile');
    } else if (link.startsWith('/user/goalsettings')) {
      setPageTitle('Goal Settings');
    } else if (link.startsWith('/user/mailsettings')) {
      setPageTitle('Mail Settings');
    } else if (link.startsWith('/user/delete')) {
      setPageTitle('Delete Account');
    }

    if (
      link.startsWith('/user/account') ||
      link.startsWith('/user/goalsettings') ||
      link.startsWith('/user/mailsettings') ||
      link.startsWith('/user/delete')
    ) {
      setActiveMenu('profile');
    } else {
      setActiveMenu(null);
    }

    navigate(link);
  };

  return (
    <div className="user-sidebar-container">
      <div className="user-sidebar">
        <div className="user-sidebar-logo">
          <img src="/" alt="Logo" className="user-sidebar-logo-img" />
        </div>
        <ul className="user-sidebar-menu">
          <li className={activeLink === '/user/schedule' ? 'user-sidebar-menu-item active' : 'user-sidebar-menu-item'}>
            <Link to="/user/schedule" onClick={() => handleLinkClick('/user/schedule')}>
              <MdLibraryAddCheck className="user-sidebar-icon" alt='schedule' />
            </Link>
          </li>
          <li className={activeLink === '/user/training' ? 'user-sidebar-menu-item active' : 'user-sidebar-menu-item'}>
            <Link to="/user/training" onClick={() => handleLinkClick('/user/training')}>
              <CiDumbbell className="user-sidebar-icon" alt='training' />
            </Link>
          </li>
        </ul>
        <div className="user-sidebar-footer">
          <ul className="user-sidebar-footer-menu">
            <li
              className={`user-sidebar-footer-menu-item ${activeMenu === 'profile' ? 'active' : ''}`}
              onClick={() => toggleMenu('profile')}
            >
              <CgProfile className="user-sidebar-icon" alt='profile' />
            </li>
            <li className={activeLink === '/user/faq' ? 'user-sidebar-footer-menu-item active' : 'user-sidebar-footer-menu-item'}>
              <Link to="/user/faq" onClick={() => handleLinkClick('/user/faq')}>
                <LuLifeBuoy className="user-sidebar-icon" alt="FAQ" />
              </Link>
            </li>
            <li className={activeLink === '/user/contact' ? 'user-sidebar-footer-menu-item active' : 'user-sidebar-footer-menu-item'}>
              <Link to="/user/contact" onClick={() => handleLinkClick('/user/contact')}>
                <AiOutlineContacts className="user-sidebar-icon" alt='contact' />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {activeMenu === 'profile' && (
        <div className="user-sidebar-submenu-card">
          <ul className="user-sidebar-submenu-list">
            <li className={activeLink === '/user/account' ? 'user-sidebar-submenu-item active' : 'user-sidebar-submenu-item'}>
              <Link to="/user/account" onClick={() => handleLinkClick('/user/account')}>
                Account
              </Link>
            </li>
            <li className={activeLink === '/user/goalsettings' ? 'user-sidebar-submenu-item active' : 'user-sidebar-submenu-item'}>
              <Link to="/user/goalsettings" onClick={() => handleLinkClick('/user/goalsettings')}>
                Goal Settings
              </Link>
            </li>
            <li className={activeLink === '/user/mailsettings' ? 'user-sidebar-submenu-item active' : 'user-sidebar-submenu-item'}>
              <Link to="/user/mailsettings" onClick={() => handleLinkClick('/user/mailsettings')}>
                Mail Settings
              </Link>
            </li>
            <li className={activeLink === '/user/delete-account' ? 'user-sidebar-submenu-item active' : 'user-sidebar-submenu-item'}>
              <Link to="/user/delete" onClick={() => handleLinkClick('/user/delete-account')}>
                Delete Account
              </Link>
            </li>
          </ul>
        </div>
      )}
      <div className={`user-sidebar-main-content ${activeMenu === 'profile' ? 'shifted' : ''}`}>
       </div>
    </div>
  );
};

export default UserSidebar;