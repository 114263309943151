import React from 'react';
import { H5, UL, LI } from '../../../../../AbstractElements';
import { Earning, Order, OrderOverviewTitle, Refunds } from '../../../../../Constant';
import { OptionsOverView, OptionsOverViewBarChart } from '../../../../../Data/Ecommerce/Chart';
import ReactApexChart from 'react-apexcharts';
import './OrderOverview.css';
import { Link } from 'react-router-dom';
const SuperOrderOverview = () => {
  return (
    <div className='orderoverview-card'>
      <div className='orderoverview-card-header-card-no-border'>
        <div>
        <H5>{OrderOverviewTitle}</H5>
        <span className='orderoverview-show-more'>Last month</span>
      </div>
      <div>
          <UL attrUL={{ horizontal: true, className: 'ul-horizontal' }}>
            <LI>
              <span className='orderoverview-circle bg-secondary' />
              <Link to="/Gym" className='orderoverview-f-light'>{Refunds}</Link>
            </LI>
            <LI>
              <span className='orderoverview-circle bg-primary'> </span>
              <Link to="/Coach" className='orderoverview-f-light'>{Earning}</Link>
            </LI>
            <LI>
              <span className='orderoverview-circle bg-success'> </span>
              <Link to="/Shop" className='orderoverview-f-light'>{Order}</Link>
            </LI>
          </UL>
        </div>
                  </div>
      <div className='orderoverview-card-body pt-0'>
        <div className='orderoverview-overall-card'>
          <div className='orderoverview-box-col-7 p-0'>
            <div className='orderoverview-chart-right'>
              <div>
                <div className='orderoverview-p-0'>
                  
                  <div className='orderoverview-current-sale-container order-container'>
                    <ReactApexChart className='orderoverview-overview-wrapper' type='line' height={300} options={OptionsOverView.options} series={OptionsOverView.series} />
                    <div className='orderoverview-back-bar-container'>
                      <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default SuperOrderOverview;
