import React from 'react';
import './TopSellProduct.css'; // Assurez-vous que ce chemin est correct
import constantImages from '../../../../../../ConstantImages/constantimages'; // Ajustez le chemin selon votre structure de dossiers

const TopSellProduct = () => {
  const data = [
    {
      name: 'XXXXX',
      price: 49,
      category: 'Category1',
      orders: 80,
      gains: 3902,
    },
    {
      name: 'XXXXX',
      price: 49,
      category: 'Category1',
      orders: 80,
      gains: 3902,
    },
    {
      name: 'XXXXX',
      price: 49,
      category: 'Category1',
      orders: 80,
      gains: 3902,
    },
    {
        name: 'XXXXX',
        price: 49,
        category: 'Category1',
        orders: 80,
        gains: 3902,
      },
      {
        name: 'XXXXX',
        price: 49,
        category: 'Category1',
        orders: 80,
        gains: 3902,
      },
  ];

  return (
    <div className="top-sell-product-container">
      <div className="top-sell-product-header">
        <h2 className="top-sell-product-title">Top Selling Products</h2>
        
      </div>
      <table className="top-sell-product-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Category</th>
            <th>Number of Orders</th>
            <th>Total Gains</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="product-info">
                  <input type="checkbox" className="checkbox-spacing" /> {/* Appliquez la classe ici */}
                  <img src={constantImages.product} alt="product" /> {/* Utilisez l'image depuis constantImages */}
                  {item.name}
                </div>
              </td>
              <td>{item.price}</td>
              <td>{item.category}</td>
              <td>{item.orders}</td>
              <td className="top-sell-product-gains">{item.gains} TND</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopSellProduct;