import React, { useState } from 'react';
import './filtergym.css'; 

const FilterGym = ({ onFilterChange }) => {
  const [filterName, setFilterName] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const handleFilterChange = () => {
    onFilterChange({ name: filterName, date: filterDate, status: filterStatus });
  };

  return (
    <div className="filterContainer">
      <label htmlFor="filterName" className="label">Filter</label>
      <div className="inputGroup">
        <input
          type="text"
          id="filterName"
          name="filterName"
          placeholder="Name"
          className="input"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
        <div className="inputDateGroup">
          <input
            type="text"
            id="filterDate"
            name="filterDate"
            placeholder="12/02/2024"
            className="input"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
          />
        </div>
        <select 
          id="filterStatus" 
          name="filterStatus" 
          className="input"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
        >
          <option value="">Status</option>
          <option value="pending">Pending</option>
          <option value="payed">Payed</option>
        </select>
        <button className="filterButton" onClick={handleFilterChange}>Filter</button>
      </div>
    </div>
  );
};

export default FilterGym;