// src/Components/Dashboard/Overview/Trainersrevenue/trainersrevenue.js
import React from 'react';
import { Link } from 'react-router-dom';
import Gender from '../Gymrevenue/Gender/gender';
import Income from './Income/incometrainer';
import TopCoachs from './TopCoachs/topcoachs';  
import './trainersrevenue.css';

const Trainersrevenue = () => {
  return (
    <div className='trainersrevenue'>
      <div className="trainersrevenue-breadcrumb">
        <Link to="/Overview">Overview</Link> / Trainers Revenue
      </div>

      <div className='trainersrevenue-gender-income-container'>
        <div className='trainersrevenue-gender'>
          <Gender />
        </div>
        <div className='trainersrevenue-income'>
          <Income />
        </div>
      </div>
      <div className='trainersrevenue-top-coachs'>
        <TopCoachs />
      </div>
    </div>
  );
};

export default Trainersrevenue;