//src/Components/Dashboard/Overview/Components/FilterCoachs/filtercoachs.js
import React from 'react';
import './filtercoachs.css'; 

const FilterCoachs = () => {
  return (
    <div className="filco-filterContainer">
      <label htmlFor="filterName" className="filco-label">Filter</label>
      <div className="filco-inputGroup">
        <input
          type="text"
          id="filterName"
          name="filterName"
          placeholder="Name"
          className="filco-input filco-inputName"
        />
        <input
          type="text"
          id="filterMonth"
          name="filterMonth"
          placeholder="July"
          className="filco-input filco-inputMonth"
        />
        <input
          type="text"
          id="filterDate"
          name="filterDate"
          placeholder="12/07/2024"
          className="filco-input filco-inputDate"
        />
        <button className="filco-filterButton">Filter</button>
      </div>
    </div>
  );
};

export default FilterCoachs;