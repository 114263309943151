import React, { useEffect } from 'react';
import styles from './Gymdetailsdisplay.module.css';

function GymDetails() {
  useEffect(() => {
    const timeInputs = document.querySelectorAll('input[type="time"]');
    timeInputs.forEach(input => {
      if (input.defaultValue === "08:30") {
        input.classList.add('default-time');
      }
    });
  }, []);

  return (
    <div className={styles.GymDC}>
      <div className={styles.gymDetails}>
        <h2 className={styles.formTitle}>Gym Details</h2>
        <form className={styles.FormContainer}>
          <div className={styles.formGroup}>
            <label htmlFor="gymName">Name of The Gym*</label>
            <input type="text" id="gymName" placeholder="California Gym" required />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="email">Email*</label>
            <input type="email" id="email" placeholder="CaliforniaGym@contact.com" required />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="phoneNumber">Phone Number (Corporal)*</label>
            <input type="tel" id="phoneNumber" placeholder="CaliforniaGym@contact.com" required />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="address">Address</label>
            <input type="text" id="address" placeholder="Sousse, Rue Jaafar" />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="description">Description*</label>
            <textarea id="description" placeholder="Description" required></textarea>
          </div>

          <div className={styles.formGroup}>
            <label>Monday-Friday</label>
            <div className={styles.timeSlots}>
              <input type="time" id="monFriTime1" defaultValue="08:30" />
              <input type="time" id="monFriTime2" defaultValue="08:30" />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label>Saturday</label>
            <div className={styles.timeSlots}>
              <input type="time" id="satTime1" defaultValue="08:30" />
              <input type="time" id="satTime2" defaultValue="08:30" />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label>Sunday</label>
            <div className={styles.timeSlots}>
              <input type="time" id="sunTime1" defaultValue="08:30" />
              <input type="time" id="sunTime2" defaultValue="08:30" />
            </div>
          </div>

          <div className={styles.BtnC}>
            <button type="submit" className={styles.saveButton}>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default GymDetails;