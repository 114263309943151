import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import styles from './Activities.module.css';

function Activities() {
    const api = useApi();
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const coachId = localStorage.getItem('id');

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                setLoading(true);
                const response = await api.request('GET', `coaches/${coachId}`);
                const { activities } = response;

                if (Array.isArray(activities)) {
                    setActivities(activities.map(item => ({
                        activityName: item.activityName,
                        image: item.image || null,
                        modifiedFields: {},
                    })));
                } else {
                    console.error("Invalid activities data received:", activities);
                    setActivities([]);
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
    }, [coachId]);

    const handleActivityChange = (index, field, value) => {
        const updatedActivities = [...activities];
        updatedActivities[index][field] = value;

        if (!updatedActivities[index].modifiedFields) {
            updatedActivities[index].modifiedFields = {};
        }
        updatedActivities[index].modifiedFields[field] = true;

        setActivities(updatedActivities);
    };

    const handleUpdateActivities = async () => {
        const formData = new FormData();
        let hasChanges = false;
    
        activities.forEach((activity, index) => {
            // Only process activities that have modified fields
            if (activity.modifiedFields && Object.keys(activity.modifiedFields).length > 0) {
                hasChanges = true;
    
                // Append activityName if modified
                if (activity.modifiedFields['activityName']) {
                    formData.append(`activities[${index}][activityName]`, activity.activityName || '');
                }
    
                // Append image only if a new file has been selected
                if (activity.modifiedFields['image'] && activity.image instanceof File) {
                    formData.append(`activities[${index}][image]`, activity.image);
                }
            }
        });
    
        if (!hasChanges) {
            alert('No changes to update.');
            return;
        }
    
        // Optional: Log FormData entries for debugging
        for (let pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }
    
        try {
            setLoading(true);
            const response = await api.request('PUT', `coaches/${coachId}`, formData);
            // Handle the response as needed...
        } catch (err) {
            console.error('Error updating activities:', err);
            alert('Error updating activities');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className={styles.ActivitiesContainer}>
            <h2 className={styles.formTitle}>Manage Activities</h2>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {!loading && !error && activities.length === 0 && <p>No activities found.</p>}

            <div className={styles.FormContainer}>
                {activities.map((activity, index) => (
                    <div key={index} className={styles.formGroup}>
                        <h3>Activity {index + 1}</h3>
                        <input
                            type="text"
                            placeholder="Activity Name"
                            value={activity.activityName || ''}
                            onChange={(e) => handleActivityChange(index, 'activityName', e.target.value)}
                            required
                        />
                        {activity.image && (
                            <div className={styles.imagePreview}>
                                <img
                                    src={activity.image instanceof File ? URL.createObjectURL(activity.image) : activity.image}
                                    alt={`Activity ${index + 1}`}
                                    className={styles.activityImage}
                                />
                            </div>
                        )}
                        <div className={styles.fileInputContainer}>
                            <input
                                type="file"
                                id={`activityImage-${index}`}
                                onChange={(e) => handleActivityChange(index, 'image', e.target.files[0])}
                                accept="image/*"
                            />
                        </div>
                    </div>
                ))}
                <button
                    type="button"
                    className={styles.saveButton}
                    onClick={handleUpdateActivities}
                    disabled={loading}
                >
                    Update All Activities
                </button>
            </div>
        </div>
    );
}

export default Activities;
