// src/Components/Dashboard/Overview/Components/Subscription/Subscription.js
import React from 'react';
import './Subscription.css';

const Subscription = () => {
  const subscriptionData = [
    { name: 'Jane Cooper', pack: 'standards', date: '12/05/2024', status: 'Pending' },
    { name: 'Jane Cooper', pack: 'standards', date: '12/05/2024', status: 'Payed' },
    { name: 'Jane Cooper', pack: 'standards', date: '12/05/2024', status: 'Payed' },
    { name: 'Jane Cooper', pack: 'standards', date: '12/05/2024', status: 'Pending' },
    { name: 'Jane Cooper', pack: 'standards', date: '12/05/2024', status: 'Pending' },
  ];

  const coachData = [
    { name: 'Jane Cooper', subscriptions: 124, img: 'C21.png' },
    { name: 'Jane Cooper', subscriptions: 124, img: 'C21.png' },
    { name: 'Jane Cooper', subscriptions: 124, img: 'C21.png' },
    { name: 'Jane Cooper', subscriptions: 124, img: 'C21.png' },
    { name: 'Jane Cooper', subscriptions: 124, img: 'C21.png' },
  ];

  return (
    <div className='SubscriptionRequest-CoachBoard'>
      <div className="subscription-container">
        <div className="subscription-content">
          <div className="subscription-request">
            <h2 className="subscription-section-title">Subscription Request</h2>
            <table className="subscription-table">
              <thead className="subscription-request-thead">
                <tr>
                  <th>Full Name</th>
                  <th>Pack</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.pack}</td>
                    <td>{item.date}</td>
                    <td>
                      <span className={`subscription-status ${item.status === 'Pending' ? 'subscription-status-pending' : 'subscription-status-payed'}`}>
                        {item.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="subscription-coach-board">
            <h2 className="subscription-section-title">Coach Board</h2>
            <table className="subscription-coach-table">
              <thead className="subscription-coach-thead">
                <tr>
                  <th>Full Name</th>
                  <th>Number Of Subscription</th>
                </tr>
              </thead>
              <tbody>
                {coachData.map((item, index) => (
                  <tr key={index}>
                    <td className="subscription-coach-name">
                      <img src={item.img} alt="Coach" className="subscription-coach-img" />
                      {item.name}
                    </td >
                    <td className='subscription-subs'>{item.subscriptions}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;