import React from 'react';
import './goal.css';

const Goal = () => {
  return (
    <div className="goal-container">
      <h2 className="goal-title">Goal Settings</h2>
      <div className="goal-card">
        <h3 className="goal-question">What is your Goal</h3>
        <div className="goal-options">
          <label className="goal-option">
            <input type="checkbox" name="goal" value="gain-weight" />
            Gain Weight
          </label>
          <label className="goal-option">
            <input type="checkbox" name="goal" value="lose-weight" />
            Lose Weight
          </label>
          <label className="goal-option">
            <input type="checkbox" name="goal" value="gain-muscles" />
            Gain Muscles
          </label>
        </div>
      </div>
    </div>
  );
};

export default Goal;
