import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../Components/Login/login';
import LogoUpload from '../Components/Logo/logo';
import SocialMediaLinks from '../Components/Socialmedia/social';
import Welcome from '../Components/Welcome/welcome';
import LayoutRoutes from './LayoutRoutes';
import GymDetails1 from '../Components/Gymdetails/gymdetails';
import ForgetPassword from '../Components/ForgetPasswordd/forgetpasswordd';

const AppRoutes = () => {
  return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/logo" element={<LogoUpload />} />
        <Route path="/gymdetails" element={<GymDetails1 />} />
        <Route path="/socialmedia" element={<SocialMediaLinks />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/*" element={<LayoutRoutes />} />
      </Routes>
  );
};

export default AppRoutes;