// src/UserComponents/UserDashboard/UserTraining/TrainingGymDetails/TrainingGymDetails.js
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './TrainingGymDetails.css';
import DetailsUser from './DetailsUser/DetailsUser';
import PaymentHistoryUser from './PaymentHistoryUser/PaymentHistoryUser';

const TrainingGymDetails = () => {
  const { gymName } = useParams();

  return (
    <div>
      <nav className="trgy-nav">
        <ol className="trgy-breadcrumb">
          <li className="trgy-breadcrumb-item"><Link to="/user/training"><span className="trgy-breadcrumb-arrow">&lt;</span> Training</Link></li>
          <li className="trgy-breadcrumb-item"><Link to="/user/training/gym"><span className="trgy-breadcrumb-arrow"></span> Gym</Link></li>
          <li className="trgy-breadcrumb-item active" aria-current="page">{gymName}</li>
        </ol>
      </nav>
      <div className="trgy-details-user-container">
        <DetailsUser />
      </div>
      <div className="trgy-payment-history-user-container">
        <PaymentHistoryUser />
      </div>
      <div className="trgy-button-container">
        <button className="trgy-renew-subscription-button">Renew Subscription</button>
      </div>
    </div>
  );
};

export default TrainingGymDetails;