// src/Components/Dashboard/Gymrevenue/Gymrevenue.js
import React from 'react';
import { Link } from 'react-router-dom';
import Gender from '../Gymrevenue/Gender/gender';
import Pack from '../Gymrevenue/Packs/packs';
import Income from '../Gymrevenue/Income/income';
import './gymrevenue.css';

const Gymrevenue = () => {
  return (
    <div className='gymrev-gymrevenue'>
      <div className="gymrev-breadcrumb">
        <Link to="/Overview">Overview</Link> / Gym Revenue
      </div>

      <div className='gymrev-Container'>  
        <div className='gymrev-gender'>
          <Gender />
        </div>
      
        <div className='gymrev-income'>
          <Income />
        </div>
      </div>
     
      <div className='gymrev-packs'>
        <Pack />
      </div>
    </div>
  );
};

export default Gymrevenue;