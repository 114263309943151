import React, { useState } from 'react';
import './profile.css';
import { MdLocationOn } from 'react-icons/md';
import { FaStar } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import SuccessPopup from '../AddCoachs/SucessPopup/SuccessPopup';
import SwitchComponent from '../switch';
import { Weigth } from '../../Constant';
import { Height } from '@mui/icons-material';
import { MdPerson, MdFitnessCenter, MdHeight } from 'react-icons/md';

 const Profileco = () => {
  const { name } = useParams(); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const profileData = {
    name: name || ' louay',
    
  
    occupation: "Gym/Private Coaching",
    occupation1: "One-to-One Coaching",
    location: "Sousse",
    age :"23" ,
    Weigth : "86 kg",
    Height :"1.8 m",
    description: "Lorem ipsum dolor sit amet consectetur. Gravida risus nibh tristique id in egestas quam nisl pellentesque...",
    rating: 5,
    reviews: 250,
    trainees: 12,
    experience: 4,
    imageUrl: "https://i.ibb.co/4pHYLYK/2148795180.jpg"
  };

  const renderModal = () => (
    
    <div className="prf-modal-overlay" onClick={() => setIsModalOpen(false)}>
      <div className="prf-modal-content" onClick={e => e.stopPropagation()}>
        <div className="prf-modal-header">
          <h2>Add Subscription</h2>
          <button className="prf-modal-close-button" onClick={() => setIsModalOpen(false)}>X</button>
        </div>
        <div className="prf-modal-body">
          <label>Email</label>
          <input 
            type="text" 
            placeholder="Samirloussif@gmail.com" 
            value={email} 
            onChange={(e) => {
              setEmail(e.target.value);
              setIsEmailValid(e.target.value.includes('@'));
            }}
          />
        </div>
        <div className="prf-modal-footer">
          <button 
            className="prf-modal-submit-button" 
            disabled={!isEmailValid}  // Disable button when email is invalid
            onClick={() => {
              if (isEmailValid) {
                setIsModalOpen(false);
                setIsSuccessPopupOpen(true);
              } else {
                alert('Please enter a valid email address.');
              }
            }}
          >
            Add Event
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>    
    <div className="prf-profile-container">
     

      <div className="prf-profile-card">
        
        <div className="prf-profile-content">
          <div className="prf-profile-details">
            <div className="prf-profile-name">{profileData.name}</div>
            <div className="prf-profile-occupation">{profileData.occupation}</div>
            <div className="prf-profile-occupation">{profileData.occupation1}</div>
            <div className="prf-profile-location">
              <MdLocationOn style={{ color: 'black' }} /> {profileData.location}
            </div>
            <div className='ContainerBar'> 
            <div className="prf-profile-age">
  <MdPerson style={{ color: 'black' }} /> {profileData.age} years
</div>
<div className="prf-profile-weight">
  <MdFitnessCenter style={{ color: 'black' }} /> {profileData.weight} 86kg
</div>
<div className="prf-profile-height">
  <MdHeight style={{ color: 'black' }} /> {profileData.height}1.8 m
</div>
           
           </div>
          
            
           
          </div>
          <div className="prf-profile-image">
            <img src={profileData.imageUrl} alt={`${profileData.name}'s profile photo`} />
          </div>
        </div>
      </div>

      {isModalOpen && renderModal()}
      {isSuccessPopupOpen && (
        <SuccessPopup 
          message="Subscription added successfully!" 
          onClose={() => setIsSuccessPopupOpen(false)} 
        />
      )}
    </div>
    </>

  );
};

export default Profileco ;