// src/Components/Dashboard/Overview/overview.js
import React from 'react';
import './overviewcoach.css';

import OrderOverviewc from './Income/OrderOverviewc';
import ActivityH from './ActivityH/ActivityH';
import Subscriptionc from './Subscription/Subscriptionc';
import SocialMedia from './SocialMediac/socialmediac';


const Overviewcoach = () => {
    return (
        <div className="overview-container">
            <div className="social-media">
                <SocialMedia />
            </div>
            
            <div className="activity-order-overview">
                <div className='income-cliks'>
                    <ActivityH /> 
                    <OrderOverviewc />
                </div>
            </div>
            <div className="subscription">
                <Subscriptionc />
            </div>
        </div>
    );
};

export default Overviewcoach;