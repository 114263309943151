import React from 'react';
import './DetailsUser.css';

function DetailsUser() {
    return (
        <div className="detus-details-card">
            <div className="detus-details-header">
                <h2 className="detus-gym-name">California Gym</h2>
                <p className="detus-plan">Current Plan</p>
            </div>
            <div className="detus-details-content">
                <div className="detus-details-info">
                    <div className="detus-info-item">
                        <h4>Location</h4>
                        <p>Sousse</p>
                    </div>
                    <div className="detus-info-item">
                        <h4>Address</h4>
                        <p>Rue Fares, App</p>
                    </div>
                    
                </div>
                <div className="detus-details-logo">
                    <img src="https://www.california-gym.com/dz/wp-content/uploads/2019/09/logo.png" alt="" />
                </div>
            </div>
        </div>
    );
}

export default DetailsUser;