//src/Components/Dashboard/Overview/Clicks/Socialclicks/social.jsx
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { SocialWidgetDataWidgetPage } from '../../../../../Data/Social';
import SocialWidget from '../../../../Common/CommonWidgets/Socialwidgets/SocialWidget';
import './social.css';

const GeneralComponent = () => {
  return (
    <Fragment>
      <Container fluid={true} className='general-widget'>
        <Row className='social-widgets-row'>
          {SocialWidgetDataWidgetPage.map((item, i) => (
            <Col xl='3' md='6' key={i} className='social-widget-col'>
              <SocialWidget data={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;