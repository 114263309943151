// src/SuperComponents/SuperDashboard/Gym/Supergymdetails/PaymentHistory/PaymentHistory.js
import React from 'react';
import './PaymentHistory.css';

function PaymentHistory() {
    const payments = [
        { date: '12/05/2024', item: 'Pack 5', status: 'Payed', total: '250 TND', details: 'View' },
        { date: '12/05/2024', item: 'Pack 5', status: 'Payed', total: '250 TND', details: 'View' },
        { date: '12/05/2024', item: 'Pack 5', status: 'Payed', total: '250 TND', details: 'View' },
        { date: '12/05/2024', item: 'Pack 5', status: 'Payed', total: '250 TND', details: 'View' },
        { date: '12/05/2024', item: 'Pack 5', status: 'Payed', total: '250 TND', details: 'View' },
    ];

    return (
        <div className="payment-history-card payment-history-margin">
            <div className="header">
                <h2>Payment history</h2>
                <button className="payment-history-view-all" onClick={() => alert('View All clicked')}>View All</button>
            </div>
            <table className="payment-history-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Pack</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map((payment, index) => (
                        <tr key={index}>
                            <td>{payment.date}</td>
                            <td>{payment.item}</td>
                            <td className="status-payed">{payment.status}</td>
                            <td className="total-amount">{payment.total}</td>
                            <td className="payment-history-view-details">{payment.details}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PaymentHistory;