import './Coachs.css';
import { FaTrashAlt, FaEye, FaEdit  } from 'react-icons/fa';

import React from 'react';

const Coachs = () => {
  const coaches = [
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', commission: 20, amount: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', commission: 20, amount: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', commission: 20, amount: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', commission: 20, amount: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', commission: 20, amount: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', commission: 20, amount: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', commission: 20, amount: '250 TND' },
    { name: 'Samir Loussif', email: 'SamirLoussif@gmail.com', phoneNumber: '+21685696352', commission: 20, amount: '250 TND' },
  ];

  return (
    <div className="trco-coachs">
      <h2>Coachs</h2>
      <table className="trco-coachTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Commission</th>
            <th className="trco-actionHeader">Amount</th>
            <th className="trco-actionHeader">Action</th>
          </tr>
        </thead>
        <tbody>
          {coaches.map((coach, index) => (
            <tr key={index}>
              <td>{coach.name}</td>
              <td>{coach.email}</td>
              <td>{coach.phoneNumber}</td>
              <td>{coach.commission}</td>
              <td className="trco-amount trco-specialAmount">{coach.amount}</td>
              <td>
                <div className="trco-actionIcons">
                  <div className="trco-actionIcon">
                    <FaEdit />
                  </div>
                  <div className="trco-actionIcon">
                    <FaEye />
                  </div>
                  <div className="trco-actionIcon">
                    <FaTrashAlt />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Coachs;