// src/Components/Dashboard/Overview/Components/Schedule/Schedule.js
import React, { useState } from 'react';
import './schedule.css';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const hours = ['9:00h', '10:00h', '11:00h', '12:00h', '13:00h', '14:00h', '15:00h'];

const Schedule = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [weekOffset, setWeekOffset] = useState(0);
  const [selectedActivity, setSelectedActivity] = useState('BodyAttack');
  const [coachName, setCoachName] = useState('');
  const [events, setEvents] = useState({});

  const handleSlotClick = (day, hour, slotIndex) => {
    const eventKey = `${getWeekDates().findIndex(date => date.toDateString() === day.toDateString())}_${hour}_${slotIndex}`;
    const event = events[eventKey];

    if (event) {
      setSelectedActivity(event.activity);
      setCoachName(event.coach);
      setIsEditPopupVisible(true);
    } else {
      setSelectedDay(day);
      setSelectedTime({ hour, slotIndex });
      setIsPopupVisible(true);
    }
  };

  const handlePrevWeek = () => {
    setWeekOffset(weekOffset - 1);
  };

  const handleNextWeek = () => {
    setWeekOffset(weekOffset + 1);
  };

  const getWeekDates = () => {
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1 + weekOffset * 7);
    const dates = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  const getWeekRange = () => {
    const dates = getWeekDates();
    const startDate = dates[0];
    const endDate = dates[6];
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const month = startDate.toLocaleString('default', { month: 'short' });
    const year = startDate.getFullYear();

    return `${startDay}-${endDay} ${month} ${year}`;
  };

  const handleActivityChange = (event) => {
    setSelectedActivity(event.target.value);
  };

  const handleCoachChange = (event) => {
    setCoachName(event.target.value);
  };

  const handleAddEvent = () => {
    const newEvents = { ...events };
    const dayIndex = getWeekDates().findIndex(date => date.toDateString() === selectedDay.toDateString());
    const { hour, slotIndex } = selectedTime;

    const eventKey = `${dayIndex}_${hour}_${slotIndex}`;
    newEvents[eventKey] = { activity: selectedActivity, coach: coachName };

    setEvents(newEvents);
    setIsPopupVisible(false);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const handleEditEvent = () => {
    const newEvents = { ...events };
    const dayIndex = getWeekDates().findIndex(date => date.toDateString() === selectedDay.toDateString());
    const { hour, slotIndex } = selectedTime;

    const eventKey = `${dayIndex}_${hour}_${slotIndex}`;
    newEvents[eventKey] = { activity: selectedActivity, coach: coachName };

    setEvents(newEvents);
    setIsEditPopupVisible(false);
  };

  const handleDeleteEvent = () => {
    const newEvents = { ...events };
    const dayIndex = getWeekDates().findIndex(date => date.toDateString() === selectedDay.toDateString());
    const { hour, slotIndex } = selectedTime;

    const eventKey = `${dayIndex}_${hour}_${slotIndex}`;
    delete newEvents[eventKey];

    setEvents(newEvents);
    setIsEditPopupVisible(false);
  };

  const handleCloseEditPopup = () => {
    setIsEditPopupVisible(false);
  };

  const renderSlotContent = (dayIndex, hour, slotIndex) => {
    const eventKey = `${dayIndex}_${hour}_${slotIndex}`;
    const event = events[eventKey];

    if (event) {
      return (
        <div className="event-details">
          <p className="event-activity">{event.activity}</p>
          <p className="event-coach">{event.coach}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="schedule-main-container">
  
      <div className="schedule-grid">
      <div className="schedule-header">
        <button onClick={handlePrevWeek} className="schedule-nav-button"><IoIosArrowRoundBack /></button>
        <h2 className='sch-head'>{getWeekRange()}</h2>
        <button onClick={handleNextWeek} className="schedule-nav-button"><IoIosArrowRoundForward /></button>
      </div>
        <div className="schedule-time-header"></div>
        {getWeekDates().map((date, index) => (
          <div key={index} className="schedule-day-header">
            {`${date.getDate()} ${days[date.getDay()]}`}
          </div>
        ))}
        {hours.map((hour, hourIndex) => (
          <React.Fragment key={hourIndex}>
            <div className="schedule-time">{hour}</div>
            {getWeekDates().map((date, dayIndex) => (
              <React.Fragment key={`${dayIndex}-${hourIndex}`}>
                {[0, 1].map((slotIndex) => {
                  const eventKey = `${dayIndex}_${hour}_${slotIndex}`;
                  const event = events[eventKey];
                  
                  return (
                    <div
                      key={`${dayIndex}-${hourIndex}-${slotIndex}`}
                      className={`schedule-slot ${event ? 'event-filled' : ''}`}
                      onClick={() => handleSlotClick(date, hour, slotIndex)}
                    >
                      {renderSlotContent(dayIndex, hour, slotIndex)}
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>

      {isPopupVisible && (
        <div className="schedule-popup-container">
          <div className="schedule-popup-content">
            <div className="schedule-popup-header">
              <h3 className="schedule-popup-title">Add Event</h3>
              <button onClick={handleClosePopup} className="schedule-popup-close-button">x</button>
            </div>
            <h3 className="schedule-popup-date">{`${selectedDay.getDate()} ${days[selectedDay.getDay()]} ${selectedDay.toLocaleString('default', { month: 'long' })} ${selectedDay.getFullYear()}`}</h3>
            <p className="schedule-popup-time">{`${selectedTime.hour}`}</p>

            <label htmlFor="activityName" className="schedule-popup-label">Activity Name</label>
            <select
              id="activityName"
              value={selectedActivity}
              onChange={handleActivityChange}
              className="schedule-popup-select"
            >
              <option value="BodyAttack">BodyAttack</option>
              <option value="Yoga">Yoga</option>
              <option value="Zumba">Zumba</option>
              <option value="Pilates">Pilates</option>
            </select>

            <label htmlFor="coachName" className="schedule-popup-label">Coach Name</label>
            <input
              type="text"
              id="coachName"
              value={coachName}
              onChange={handleCoachChange}
              className="schedule-popup-input"
            />

            <button onClick={handleAddEvent} className="schedule-popup-button">Add Event</button>
          </div>
        </div>
      )}

      {isEditPopupVisible && (
        <div className="schedule-popup-container">
          <div className="schedule-popup-content">
            <div className="schedule-popup-header">
              <h3 className="schedule-popup-title">Edit Event</h3>
              <button onClick={handleCloseEditPopup} className="schedule-popup-close-button">x</button>
            </div>

            <label htmlFor="activityName" className="schedule-popup-label">Activity Name</label>
            <select
              id="activityName"
              value={selectedActivity}
              onChange={handleActivityChange}
              className="schedule-popup-select"
            >
              <option value="BodyAttack">BodyAttack</option>
              <option value="Yoga">Yoga</option>
              <option value="Zumba">Zumba</option>
              <option value="Pilates">Pilates</option>
            </select>

            <label htmlFor="coachName" className="schedule-popup-label">Coach Name</label>
            <input
              type="text"
              id="coachName"
              value={coachName}
              onChange={handleCoachChange}
              className="schedule-popup-input"
            />

            <div className="schedule-popup-buttons-container">
              <button onClick={handleDeleteEvent} className="schedule-popup-delete-button">Delete</button>
              <button onClick={handleEditEvent} className="schedule-popup-edit-button">Edit Event</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Schedule;