//src/CoachComponents/Setting/Notification/notification.js
import React, { useState } from 'react';
import './notification.css';

const NotificationSettings = () => {
  const [settings, setSettings] = useState({
    setting1: true,
    setting2: true,
    setting3: false,
    setting4: false,
  });

  const handleToggle = (setting) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [setting]: !prevSettings[setting],
    }));
  };

  return (
    <div className='MainContainer'>
    <div className='notf_container'>
        <div className='title_container'>
        <h1 className='container_title'>Notifications</h1>
        </div>
    <div className="notification-container">
      <h1 className='notif_title'>Notification Settings</h1>
      <p className='notif_desc'>By enabling these settings you will receive notifications on any order or reservation</p>
      <div className="settings">
        <label className="switch">
          <input
            type="checkbox"
            checked={settings.setting1}
            onChange={() => handleToggle('setting1')}
          />
          <span className="slider"></span>
          Lorem Ipsum
        </label>
        <label className="switch">
          <input
            type="checkbox"
            checked={settings.setting2}
            onChange={() => handleToggle('setting2')}
          />
          <span className="slider"></span>
          Lorem Ipsum
        </label>
        <label className="switch">
          <input
            type="checkbox"
            checked={settings.setting3}
            onChange={() => handleToggle('setting3')}
          />
          <span className="slider"></span>
          Lorem Ipsum
        </label>
        <label className="switch">
          <input
            type="checkbox"
            checked={settings.setting4}
            onChange={() => handleToggle('setting4')}
            
          />
          <span className="slider"></span>
          Lorem Ipsum
        </label>
      </div>
    </div>
    </div>
    </div>
  );
};

export default NotificationSettings;
