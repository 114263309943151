import React from 'react';
import { H5, UL, LI } from '../../../AbstractElements';
import { Earning, Order, OrderOverviewTitle, Refunds } from '../../../Constant';
import { OptionsOverView, OptionsOverViewBarChart } from '../../../Data/Ecommerce/Chart';
import ReactApexChart from 'react-apexcharts';
import './Orderc.css';
import { Link } from 'react-router-dom';

const OrderOverviewc = () => {
  return (
    <div className='card_overviewgym'>
      <div className='ocard-header card-no-border'>
        <div>
          <H5>{OrderOverviewTitle}</H5>
          <span className='show-more'>Last month</span>
        </div>
        <div>
          <UL attrUL={{ horizontal: true, className: 'ul-horizontal' }}>
            <LI>
              <span className='circle bg-secondary' />
              <Link to="/overview/gymrevenue" className='f'>{Refunds}</Link>
            </LI>
            <LI>
              <span className='circle bg-primary'> </span>
              <Link to="/overview/trainersrevenue" className='f'>private</Link>
            </LI>
            <LI>
              <span className='circle bg-success'> </span>
              <Link to="/overview/shoprevenue" className='f-light'>show more</Link>
            </LI>
          </UL>
        </div>
      </div>
      <div className='card-body pt-0'>
        <div className='overall-card'>
          <div className='chart-right'>
            <div className='current-sale-container order-container'>
              <ReactApexChart className='overview-wrapper' type='line' height={300} options={OptionsOverView.options} series={OptionsOverView.series} />
              <div className='back-bar-gym'>
                <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderOverviewc;