import React from 'react';
import './PaymentHistoryco.css';

const PaymentHistoryco = ({ paymentHistory }) => {
  return (
    <div className="payusco-payment-history-card payusco-payment-history-margin">
      <div className="payusco-header">
        <h2>Payment history</h2>
        <button className="payusco-payment-history-view-all" onClick={() => alert('View All clicked')}>View All</button>
      </div>
      <table className="payusco-payment-history-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Pack</th>
            <th>Status</th>
            <th>Total</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {paymentHistory.map((payment, index) => (
            <tr key={index}>
              <td>{new Date(payment.startDate).toLocaleDateString()}</td>
              <td>{payment.packName}</td>
              <td className={`payusco-status ${payment.subscriptionStatus === 'pending' ? 'payusco-status-pending' : 'payusco-status-payed'}`}>
                {payment.subscriptionStatus}
              </td>
              <td className="payusco-total-amount">{payment.paymentStatus}</td>
              <td className="payusco-view-details-payment-history">View</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentHistoryco;