import React from 'react';
import './socialmediac.css';

const socialMediaData = [
  {
    platform: 'Facebook',
    clicks: '68,954',
    growth: '+22.9%',
    icon: './fb.png', 
  },
  {
    platform: 'Instagram',
    clicks: '68,954',
    growth: '+22.9%',
    icon: './inst.png', 
  },
  {
    platform: 'Twitter',
    clicks: '68,954',
    growth: '+22.9%',
    icon: './twitter.png', 
  },
  {
    platform: 'Youtube',
    clicks: '68,954',
    growth: '+22.9%',
    icon: './youtube.png',
  },
];

const SocialMedia = () => {
  return (
    <div className="social-media-container">
      
      {socialMediaData.map((data, index) => (
        <div className="social-media-card" key={index}>
          
          <img src={data.icon} alt={data.platform} className="social-media-icon" />
     
          <div className="social-media-details">
          <span className="social-media-growth">{data.growth}</span>
            <span className="social-media-platform">{data.platform}</span>
            <span className="social-media-clicks">{data.clicks}</span>
            <span className="social-media-clicks-text">Clicks</span>
           
          </div>
        </div>
      ))}
    </div>
  );
};

export default SocialMedia;
