import React, { useState } from 'react';
import './Sidebar.css';
import { 
  FaHome, FaDumbbell, FaUser, FaCalendarAlt, FaShoppingCart, 
  FaTv, FaCog, FaQuestionCircle, FaEnvelope, FaBars, FaTimes, 
  FaInfo, FaImages, FaBicycle, FaShieldAlt, FaUserAlt, FaBell, FaRunning 
} from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../context/PageTitleContext';

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeLink, setActiveLink] = useState('/Overview');
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();

  const toggleMenu = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu(null);
      setIsSubMenuOpen(false);
    } else {
      setActiveMenu(menu);
      setIsSubMenuOpen(menu === 'coaches' || menu === 'display' || menu === 'settings');
      switch(menu) {
        case 'coaches':
          navigate('/coaches/profile');
          setActiveLink('/coaches/profile');
          setPageTitle('Coaches');
          break;
        case 'display':
          navigate('/display/gymdetails');
          setActiveLink('/display/gymdetails');
          setPageTitle('Display');
          break;
        case 'settings':
          navigate('/Settings/accoundetails');
          setActiveLink('/Settings/accoundetails');
          setPageTitle('Settings');
          break;
        default:
          break;
      }
    }
  };

  const handleLinkClick = (link, title) => {
    if (!link.startsWith('/coaches') && !link.startsWith('/display') && !link.startsWith('/Settings')) {
      setActiveMenu(null);
      setIsSubMenuOpen(false);
    }
    setActiveLink(link);
    navigate(link);
    setPageTitle(title);
  };

  return (
    <div className="sidgy-sidebar_container">
      <div className={`sidgy-sidebar ${open ? '' : 'sidgy-sidebar-closed'}`}>
        <div className="sidgy-toggle-button" onClick={() => setOpen(!open)}>
          {open ? <FaTimes /> : <FaBars />}
        </div>
        <div className="sidgy-logo">
          <img src="gymlogo.webp" alt="Gym logo" />
        </div>
        <div className="sidgy-menu">
          <h3 className={`${open ? '' : 'sidgy-hidden'}`}>DASHBOARD</h3>
          <ul>
            <li className={activeLink === '/Overview' ? 'active' : ''} onClick={() => handleLinkClick('/Overview', 'Overview')}>
              <Link to="/Overview">
                <div className="sidgy-icon"><FaHome /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>Overview</span>
              </Link>
            </li>
            <li className={activeLink === '/gym' ? 'active' : ''} onClick={() => handleLinkClick('/gym', 'Gym')}>
              <Link to="/gym">
                <div className="sidgy-icon"><FaDumbbell /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>Gym</span>
              </Link>
            </li>



              <li className={activeLink === '/Coaches/Add' ? 'active' : ''} onClick={() => handleLinkClick('/Coaches/Add', 'Coaches')}>
              <Link to="/Coaches/Add">
              <div className="sidgy-icon"><FaUser /></div>
              <span className={`${open ? '' : 'hidden'}`}>Coaches</span>
              </Link>
            </li>


            <li className={activeLink === '/schedule' ? 'active' : ''} onClick={() => handleLinkClick('/schedule', 'Schedule')}>
              <Link to="/schedule">
                <div className="sidgy-icon"><FaCalendarAlt /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>Schedule</span>
              </Link>
            </li>


            <li className={activeLink === '/shop' ? 'active' : ''} onClick={() => handleLinkClick('/shop', 'Shop')}>
              <Link to="/shop">
                <div className="sidgy-icon"><FaShoppingCart /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>Shop</span>
              </Link>
            </li>
            <li onClick={() => toggleMenu('display')} className={`menu-item ${activeMenu === 'display' ? 'active' : ''}`}>
              <div className="sidgy-icon"><FaTv /></div>
              <span className={`${open ? '' : 'sidgy-hidden'}`}>Display</span>
            </li>
            <li onClick={() => toggleMenu('settings')} className={`menu-item ${activeMenu === 'settings' ? 'active' : ''}`}>
              <div className="sidgy-icon"><FaCog /></div>
              <span className={`${open ? '' : 'sidgy-hidden'}`}>Settings</span>
            </li>
          </ul>
          <h3 className={`${open ? '' : 'sidgy-hidden'}`}>HELP AND SUPPORT</h3>
          <ul>
            <li className={activeLink === '/FAQ' ? 'active' : ''} onClick={() => handleLinkClick('/FAQ', 'FAQ')}>
              <Link to="/FAQ">
                <div className="sidgy-icon"><FaQuestionCircle /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>FAQ</span>
              </Link>
            </li>
            <li className={activeLink === '/Contact' ? 'active' : ''} onClick={() => handleLinkClick('/Contact', 'Contact Us')}>
              <Link to="/Contact">
                <div className="sidgy-icon"><FaEnvelope /></div>
                <span className={`${open ? '' : 'sidgy-hidden'}`}>Contact Us</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={`sidgy-content ${open ? '' : 'sidgy-content-expanded'} ${isSubMenuOpen ? 'sidgy-content-shifted' : ''}`}>
        {activeMenu === 'coaches' && (
          <div className="sidgy-submenu-card">
            <ul className="sidgy-submenu">
              <li className={activeLink === '/coaches/profile' ? 'active' : ''} onClick={() => handleLinkClick('/coaches/profile', 'Coaches')}>
                <Link to="/coaches/profile">
                  <div className="sidgy-icon"><FaUserAlt /></div>
                  <span>Profile</span>
                </Link>
              </li>
              <li className={activeLink === '/coaches/trainee' ? 'active' : ''} onClick={() => handleLinkClick('/coaches/trainee', 'Coaches')}>
                <Link to="/coaches/trainee">
                  <div className="sidgy-icon"><FaRunning /></div>
                  <span>Trainee</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
        {activeMenu === 'display' && (
          <div className="sidgy-submenu-card">
            <ul className="sidgy-submenu">
              <li className={activeLink === '/display/gymdetails' ? 'active' : ''} onClick={() => handleLinkClick('/display/gymdetails', 'Display')}>
                <Link to="/display/gymdetails">
                  <div className="sidgy-icon"><FaInfo /></div>
                  <span>Gym Details</span>
                </Link>
              </li>
              <li className={activeLink === '/display/gallery' ? 'active' : ''} onClick={() => handleLinkClick('/display/gallery', 'Display')}>
                <Link to="/display/gallery">
                  <div className="sidgy-icon"><FaImages /></div>
                  <span>Gallery</span>
                </Link>
              </li>
              <li className={activeLink === '/display/activities' ? 'active' : ''} onClick={() => handleLinkClick('/display/activities', 'Display')}>
                <Link to="/display/activities">
                  <div className="sidgy-icon"><FaBicycle /></div>
                  <span>Activities</span>
                </Link>
              </li>
              <li className={activeLink === '/display/pricing' ? 'active' : ''} onClick={() => handleLinkClick('/display/pricing', 'Display')}>
                <Link to="/display/pricing">
                  <div className="sidgy-icon"><FaShieldAlt /></div>
                  <span>Pricing</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
        {activeMenu === 'settings' && (
          <div className="sidgy-submenu-card">
            <ul className="sidgy-submenu">
              <li className={activeLink === '/Settings/accoundetails' ? 'active' : ''} onClick={() => handleLinkClick('/Settings/accoundetails', 'Settings')}>
                <Link to="/Settings/accoundetails">
                  <div className="sidgy-icon"><FaUserAlt /></div>
                  <span>Account Details</span>
                </Link>
              </li>
              <li className={activeLink === '/Settings/notifications' ? 'active' : ''} onClick={() => handleLinkClick('/Settings/notifications', 'Settings')}>
                <Link to="/Settings/notifications">
                  <div className="sidgy-icon"><FaBell /></div>
                  <span>Notifications</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;