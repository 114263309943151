// src/Components/AddCoachs/CoachsStatut/CoachsStatut.js
import React from 'react';
import './CoachsStatut.css';
import { FaTrashAlt, FaEye, FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const CoachsStatut = ({ coaches, onDeleteCoach, onEditCoach }) => {
    return (
        <div className="coachsStatut-container">
            <h2 className="coachsStatut-title">Coachs</h2>
            <table className="coachsStatut-table">
                <thead className='thd-chsst'>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Commission</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {coaches.map((coach, index) => (
                        <tr key={index}>
                            <td>
                                <input type="checkbox" className="coachsStatut-checkbox" />
                            </td>
                            <td>{coach.name}</td>
                            <td>{coach.email}</td>
                            <td>{coach.phoneNumber}</td>
                            <td>{coach.commission}</td>
                            <td>
                                <span className={`coachsStatut-status coachsStatut-status-${coach.status.toLowerCase()}`}>
                                    {coach.status}
                                </span>
                            </td>
                            <td>
                                <div className="coachsStatut-actionIcons">
                                    <div className="icon-wrapper" onClick={() => onEditCoach(index)}>
                                        <FaEdit />
                                    </div>
                                    <div className="icon-wrapper">
                                        <Link to="/Coaches/Profile">
                                            <FaEye />
                                        </Link>
                                    </div>
                                    <div className="icon-wrapper" onClick={() => onDeleteCoach(index)}>
                                        <FaTrashAlt />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CoachsStatut;