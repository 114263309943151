//src/Components/ForgetPasswordd/forgetpasswordd.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './forgetpasswordd.css';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send a request to your backend to reset the password
    // For simplicity, we'll just check if the email is in our fakeUsers list
    const fakeUsers = [
      { email: 'wieme.zayene20@gmail.com' },
      { email: 'wiwi.zn@gmail.com' },
      { email: 'test@example.com' },
    ];

    const user = fakeUsers.find((user) => user.email === email);
    if (user) {
      navigate('/');
    } else {
      setError('Email not found');
    }
  };

  return (
    <div className="page-container">
      <div className="forget-password-container">
        <div className="forget-password-logo">Logo</div>
        <div className="forget-password-welcome">Forgot Password</div>
        <form className="forget-password-form" onSubmit={handleSubmit}>
          <div className="forget-password-input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>
          {error && <div className="forget-password-error">{error}</div>}
          <button type="submit" className="forget-password-button">Reset Password</button>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;