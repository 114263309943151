import React, { useState } from 'react';
import axios from 'axios';
import './addpricing.css';

const Modal = ({ show, onClose, message }) => {
  if (!show) return null;

  return (
    <div className="modal-done" onClick={onClose}>
      <div className="modal_saved" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="checkmark-circle">
            <div className="background"></div>
            <div className="checkmark"></div>
          </div>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

const AddPricing = ({ closeModal, currentPlan, setPlans }) => {
  const [name, setName] = useState(currentPlan?.name || '');
  const [price, setPrice] = useState(currentPlan?.price || '');
  const [promotion, setPromotion] = useState(currentPlan?.promotion || '');
  const [duration, setDuration] = useState(currentPlan?.duration || '3 Months');
  const [options, setOptions] = useState(
    currentPlan?.features.map((feature, index) => ({ id: index + 1, value: feature })) || [{ id: 1, value: 'Basic' }]
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleAddOption = () => {
    setOptions([...options, { id: options.length + 1, value: '' }]);
  };

  const handleOptionChange = (id, value) => {
    setOptions(options.map(option => option.id === id ? { ...option, value } : option));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      price,
      promotion,
      duration,
      features: options.map(option => option.value),
    };

    try {
      let response;
      if (currentPlan) {
        response = await axios.put(`/api/pricing/${currentPlan.id}`, data); 
      } else {
        response = await axios.post('https://jsonplaceholder.typicode.com/posts', data); 
      }

      if (response.status === 201 || response.status === 200) {
        setPlans(prevPlans => {
          if (currentPlan) {
            return prevPlans.map(plan =>
              plan.id === currentPlan.id ? { ...plan, ...data } : plan
            );
          } else {
            return [...prevPlans, { id: response.data.id, ...data }];
          }
        });

        setShowConfirmationModal(true);

        setTimeout(() => {
          setShowConfirmationModal(false);
          closeModal();
        }, 2000);
      } else {
        console.error('Failed to save data:', response);
      }
    } catch (error) {
      console.error('Error saving data:', error.response || error.message);
    }
  };

  return (
    <div className="modal-backdrop" onClick={closeModal}>
      <div className="modal_form" onClick={(e) => e.stopPropagation()}>
        <div className="add-pricingform">
          <h2>{currentPlan ? 'Edit Pricing Pack' : 'Add Pricing Pack'}</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Name of the Pack
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </label>
            <label>
              Price(TND)/Month
              <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
            </label>
            <label>
              Promotion
              <input type="text" value={promotion} onChange={(e) => setPromotion(e.target.value)} />
            </label>
            <label>
              Duration
              <input type="text" value={duration} onChange={(e) => setDuration(e.target.value)} />
            </label>
            <div className="options-container">
              {options.map(option => (
                <label key={option.id}>
                  Option
                  <input type="text" value={option.value} onChange={(e) => handleOptionChange(option.id, e.target.value)} />
                </label>
              ))}
            </div>
            <button type="button" onClick={handleAddOption} className='add_btn'>Add Option</button>
            <div className="form_btn">
              <button type="submit" className='done_btn'>Done</button>
            </div>
          </form>
        </div>
      </div>

      {showConfirmationModal && (
        <Modal show={showConfirmationModal} onClose={() => setShowConfirmationModal(false)} message="A Pack Has been Added" />
      )}
    </div>
  );
};

export default AddPricing;