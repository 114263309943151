import React from 'react';
import { Outlet } from 'react-router-dom';
import UserSidebar from './UserSidebar/UserSidebar'; 
import Footer from '../../Components/Layout/Footer';
import './UserLayout.css';
import UserNavbar from './UserNavbar/UserNavbar';

const UserLayout = () => {
  return (
    <div className="layuse-layout">
      <UserNavbar className="layuse-navbar" />
      <div className="layuse-main-content">
        <UserSidebar className="layuse-sidebar" />
        <div className="layuse-content-wrapper">
          <div className="layuse-content">
            <Outlet />
          </div>
          <Footer className="layuse-footer" />
        </div>
      </div>
    </div>
  );
};

export default UserLayout;