import React from 'react';
import './Details.css';

function Details() {
    return (
        <div className="spdt-details-card">
            <div className="spdt-details-header">
                <h2 className="spdt-gym-name">California Gym</h2>
                <p className="spdt-plan">Plan : Pack 2</p>
            </div>
            <div className="spdt-details-content">
                <div className="spdt-details-info">
                    <div className="spdt-info-item">
                        <h4>Location</h4>
                        <p>Sousse</p>
                    </div>
                    <div className="spdt-info-item">
                        <h4>Address</h4>
                        <p>Rue Fares, App</p>
                    </div>
                    <div className="spdt-info-item">
                        <h4>Subscriptions</h4>
                        <p>4</p>
                    </div>
                </div>
                <div className="spdt-details-logo">
                    <img src="https://www.california-gym.com/dz/wp-content/uploads/2019/09/logo.png" alt="" />
                </div>
            </div>
        </div>
    );
}

export default Details;