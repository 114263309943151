import React, { useState } from 'react';
import './gallery.css';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [previewURLs, setPreviewURLs] = useState([]);
  const [videoLink, setVideoLink] = useState('');
  const [facebookLink, setFacebookLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');

  const handleImageUpload = (event, index) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      const newImages = [...images];
      const newPreviewURLs = [...previewURLs];
      newImages[index] = files[0];
      newPreviewURLs[index] = URL.createObjectURL(files[0]);
      setImages(newImages);
      setPreviewURLs(newPreviewURLs);
    }
  };

  const handleLinkChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleSubmit = () => {
    console.log({ images, videoLink, facebookLink, instagramLink, youtubeLink, twitterLink });
  };

  return (
    <div className="glr-gallery-container">
      <h2 className="glr-h2">Gallery</h2>
      <div className="glr-upload-box">
        {images.length < 3 && (
          <div className="glr-upload-area">
            <input type="file" accept="image/jpeg,image/png,application/pdf" onChange={(e) => handleImageUpload(e, images.length)} />
            <img src='../upload.png' alt="Upload Icon" className="glr-upload-icon" />
            <span>Drag & Drop or choose Image to upload</span>
            <p>Supported formats: Jpeg, png, pdf</p>
          </div>
        )}
        <div className="glr-image-preview-list">
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="glr-image-preview">
              {previewURLs[index] ? (
                <>
                  <img
                    src={previewURLs[index]}
                    alt={`Preview ${index + 1}`}
                    onClick={() => document.getElementById(`file-input-${index}`).click()}
                  />
                  <input
                    type="file"
                    id={`file-input-${index}`}
                    accept="image/jpeg,image/png,application/pdf"
                    onChange={(e) => handleImageUpload(e, index)}
                    style={{ display: 'none' }}
                  />
                </>
              ) : (
                <input
                  type="file"
                  id={`file-input-${index}`}
                  accept="image/jpeg,image/png,application/pdf"
                  onChange={(e) => handleImageUpload(e, index)}
                  style={{ display: 'block' }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      
      <div className="glr-link-section">
        <label>Video (YouTube Link)</label>
        <input
          type="text"
          placeholder="Link"
          value={videoLink}
          onChange={handleLinkChange(setVideoLink)}
        />
        <label>Facebook</label>
        <input
          type="text"
          placeholder="Link to Facebook Channel: @CaliforniaGym."
          value={facebookLink}
          onChange={handleLinkChange(setFacebookLink)}
        />
        <label>Instagram</label>
        <input
          type="text"
          placeholder="Link to Instagram Channel: @CaliforniaGym."
          value={instagramLink}
          onChange={handleLinkChange(setInstagramLink)}
        />
        <label>YouTube</label>
        <input
          type="text"
          placeholder="Link to YouTube Channel: @CaliforniaGym."
          value={youtubeLink}
          onChange={handleLinkChange(setYoutubeLink)}
        />
        <label>X</label>
        <input
          type="text"
          placeholder="Link to our Twitter account @CaliforniaGym."
          value={twitterLink}
          onChange={handleLinkChange(setTwitterLink)}
        />
      </div>
      <div className='glr-BtnContainer'>
        <button className='glr-but-gall' onClick={handleSubmit}>Save</button>
      </div>
    </div>
  );
};

export default Gallery;