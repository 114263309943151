//src/CoachComponents/FAQ/faq.js
import React from 'react';
import './faq.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const FAQ = () => {
  const faqs = [
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
  ];

  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      <p className="faq-description">Quick answers to questions you may have. Can't find what you're looking for? Search for your question below.</p>
      <div className="faq-search-container">
        <input className="faq-search" type="text" placeholder="Search here..." />
        <FontAwesomeIcon icon={faSearch} className="faq-search-icon" />
      </div>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className='question'>{faq.question}</div>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
      <div className="faq-contact">
        <div className='question'>Still have questions? </div>
        <p>Can't find the answer you're looking for? Please contact us.</p>
        <div className='contactbtn_container'>
        <Link to="/Contact">
          <button className="contact-button">Contact Us</button>
        </Link>
        </div>
      </div>
    </div>
  );
}

export default FAQ;