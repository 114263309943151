import React, { createContext, useState, useContext } from 'react';

const CoachPageTitleContext = createContext();

export const CoachPageTitleProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('Overview');

  return (
    <CoachPageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </CoachPageTitleContext.Provider>
  );
};

export const useCoachPageTitle = () => useContext(CoachPageTitleContext);