import React from 'react';
import SuperOrderOverview from './Components/Income/OrderOverview';
import Stat from './Components/Income/Stat/stat';
import './superoverview.css';
const SuperOverview = () => {
    return (

        <div className="overview-container">
            <div className='stat_container'>
                <Stat/>

            </div>
        <div className="order-overview">
            <SuperOrderOverview />
        </div>
        
    </div>
    );
  };
  
  export default SuperOverview;