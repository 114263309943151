import React, { useState } from 'react';
import { FaTrashAlt, FaEye, FaEdit } from 'react-icons/fa';
import { IoCloseOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import SuccessPopupSuperDeleteCo from '../SuccessPopupSuperDeleteCo/SuccessPopupSuperDeleteCo';
import './SuperAddCoach.css';

const SuperAddCoach = ({ subscriptionData, setCheckedItems, checkedItems, onDeleteCoach, onUpdateCoach, packsList, coachesList }) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [coachToDelete, setCoachToDelete] = useState(null);
  const [coachToUpdate, setCoachToUpdate] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const navigate = useNavigate();

  const handleCheckboxChange = (index) => {
    if (checkedItems.includes(index)) {
      setCheckedItems(checkedItems.filter(item => item !== index));
    } else {
      setCheckedItems([...checkedItems, index]);
    }
  };

  const handleDeleteClick = (index) => {
    setCoachToDelete(index);
    setShowDeletePopup(true);
  };

  const handleConfirmDelete = () => {
    onDeleteCoach(coachToDelete);
    setShowDeletePopup(false);
    setCoachToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeletePopup(false);
    setCoachToDelete(null);
  };

  const handleUpdateClick = (index) => {
    setCoachToUpdate(index);
    setUpdatedData({ ...subscriptionData[index] });
    setShowUpdatePopup(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({ ...updatedData, [name]: value });
  };

  const handleConfirmUpdate = () => {
    onUpdateCoach(coachToUpdate, updatedData);
    setShowUpdatePopup(false);
    setCoachToUpdate(null);
  };

  const handleCancelUpdate = () => {
    setShowUpdatePopup(false);
    setCoachToUpdate(null);
  };

  const handleViewClick = (index) => {
    const coachDetails = subscriptionData[index];
    navigate(`/superadmin/coaches/SuperCoachDetails/${coachDetails.coachId}`, { state: { coachId: coachDetails.coachId } });
  };

  const getCoachDetails = (coachId) => {
    const coach = coachesList.find(coach => coach._id === coachId);
    return coach ? { name: coach.name, phone: coach.number } : { name: 'Unknown Coach', phone: '' };
  };

  const getPackName = (packId) => {
    const pack = packsList.find(pack => pack._id === packId);
    return pack ? pack.name : 'Unknown Pack';
  };

  return (
    <div className="saddc-container">
      <div className="saddc-request">
        <h2>Coaches</h2>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Full Name</th>
              <th>Pack</th>
              <th>Subscription Debut</th>
              <th>End of Subscription</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Payment Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionData.map((item, index) => {
              const coachDetails = getCoachDetails(item.coachId);
              const packName = getPackName(item.packId);
              return (
                <tr key={index}>
                  <td>
                    <input type="checkbox" className="saddc-checkbox-spacing" onChange={() => handleCheckboxChange(index)} />
                  </td>
                  <td>{coachDetails.name}</td>
                  <td>{packName}</td>
                  <td>{new Date(item.startDate).toLocaleDateString()}</td>
                  <td>{new Date(item.endDate).toLocaleDateString()}</td>
                  <td>{coachDetails.phone}</td>
                  <td>
                    <span className={`saddc-status ${item.subscriptionStatus === 'pending' ? 'saddc-status-pending' : item.subscriptionStatus === 'expired' ? 'saddc-status-expired' : item.subscriptionStatus === 'rejected' ? 'saddc-status-rejected' : 'saddc-status-payed'}`}>
                      {item.subscriptionStatus}
                    </span>
                  </td>
                  <td>{item.paymentStatus}</td>
                  <td>
                    <div className="saddc-actionIcons">
                      <div className="icon-wrapper" onClick={() => handleViewClick(index)}>
                        <FaEye />
                      </div>
                      <div className="icon-wrapper" onClick={() => handleUpdateClick(index)}>
                        <FaEdit />
                      </div>
                      <div className="icon-wrapper" onClick={() => handleDeleteClick(index)}>
                        <FaTrashAlt />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showDeletePopup && (
        <SuccessPopupSuperDeleteCo 
          onConfirm={handleConfirmDelete} 
          onCancel={handleCancelDelete} 
        />
      )}

      {showUpdatePopup && (
        <div className="saddc-popupOverlay">
          <div className="saddc-popupContent">
            <button className="saddc-closeButton" onClick={handleCancelUpdate}>
              <IoCloseOutline /> 
            </button>
            <h2>Edit Coach</h2>
            <form onSubmit={handleConfirmUpdate}>
              <label>Pack</label>
              <select 
                name="packId" 
                required 
                value={updatedData.packId}
                onChange={handleInputChange}
              >
                <option value="">Select a Pack</option>
                {packsList.map(pack => (
                  <option key={pack._id} value={pack._id}>{pack.name}</option>
                ))}
              </select>
              <label>Subscription Debut</label>
              <input 
                type="date" 
                name="startDate" 
                required 
                value={new Date(updatedData.startDate).toISOString().split('T')[0]}
                onChange={handleInputChange}
              />
              <label>End of Subscription</label>
              <input 
                type="date" 
                name="endDate" 
                required 
                value={new Date(updatedData.endDate).toISOString().split('T')[0]}
                onChange={handleInputChange}
              />
              <label>Status</label>
              <select 
                name="subscriptionStatus" 
                required 
                value={updatedData.subscriptionStatus}
                onChange={handleInputChange}
              >
                <option value="pending">Pending</option>
                <option value="accepted">Accepted</option>
                <option value="rejected">Rejected</option>
                <option value="expired">Expired</option>
              </select>
              <label>Payment Status</label>
              <select 
                name="paymentStatus" 
                required 
                value={updatedData.paymentStatus}
                onChange={handleInputChange}
              >
                <option value="paid">Paid</option>
                <option value="not_paid">Not Paid</option>
              </select>
              <button type="submit" className="saddc-adddCoachButton">
                Update Coach
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperAddCoach;