
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { ActivityHoursChartData } from '../../../Data/OnlineCourse/ChartData';
import { Link } from 'react-router-dom';
import './ActivityH.css';

const ActivityH = () => {
  const sixMonthsData = ActivityHoursChartData.sixMonths;

  return (
    <Card className="card-activityhours">
      <CardHeader className='card-no-border'>
        <div className='header-top'>
          <H5 className='H5'>Clicks</H5>
          <Link to="/Overview/clicks">
            <Button  className="activityhours-show-more-button">Show More</Button>
          </Link>
        </div>
      </CardHeader>
      <CardBody className='pt-0'>
        <div className='total-clicks'>
          Total Clicks: 14575
        </div>
        <div className='chart-right'>
          <ReactApexChart type='bar' height={300} options={sixMonthsData.options} series={sixMonthsData.series} />
        </div>
      </CardBody>
    </Card>
  );
};

export default ActivityH;