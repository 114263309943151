//src/api/auth/auth.js
import session from '../../modules/session'; // Session management utility
import axiosInstance from '../axiosInstance'; // Import the Axios instance

export const isAuthenticated = () => {
  const token = session.get('accessToken') || localStorage.getItem('accessToken');
  return !!token;
};

export const getToken = () => {
  return session.get('accessToken') || localStorage.getItem('accessToken');
};

export const fetchUser = async () => {
  const token = getToken();
  if (!token) return null;

  try {
    // Use Axios instance to fetch user info
    const response = await axiosInstance.get('/auth/load/me' + '?token=' + token, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Return the user data
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
};