//src/api/UserContext.js
import { createContext, useState, useEffect } from 'react';
import useApi from '../api/useApi';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { request } = useApi();

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        const userResponse = await request('get', `/auth/load/me`, {}, {
          Authorization: `Bearer ${token}`,
        });
        setUser(userResponse);
      }
    };

    fetchUser();
  }, [request]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
