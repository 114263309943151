import React from 'react';
import { IoMail } from "react-icons/io5";
import './profileset.css';

const ProfileSet = () => {
  return (
    <div className="prfle-profile-container">
      <div className="prfle-profile-header">
        <img src="Ellipse 11.png" alt="Profile" className="prfle-profile-image" />
        <div className='nam-mail'>
          <h2>Alexa Rawles</h2>
          <p>alexarawles@gmail.com</p>
        </div>
        <button className="prfle-edit-button">Edit</button>
      </div>

      <div className="prfle-profile-details">
        <div className="prfle-profile-field">
          <label>Last Name</label>
          <input type="text" placeholder="Your First Name" />
        </div>

        <div className="prfle-profile-field">
          <label>First Name</label>
          <input type="text" placeholder="Your First Name" />
        </div>

        <div className="prfle-profile-field">
          <label>Gender</label>
          <select>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
           </select>
        </div>

        <div className="prfle-profile-field">
          <label>Country</label>
          <select>
            <option value="">Select Country</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Libya">Libya</option>
            <option value="Algeria">Algeria</option>
            <option value="France">France</option>
            <option value="Russie">Russie</option>
           </select>
        </div>

        <div className="prfle-profile-field">
          <label>Date Of Birth</label>
          <input type="text" placeholder="Your Date Of Birth" />
        </div>
      </div>

      <div className="prfle-profile-email-section">
        <h3>My email Address</h3>
        <div className="email-container">
          <div className="email-icon-wrapper">
            <IoMail className="email-icon" />
          </div>
          <p>alexarawles@gmail.com</p>
        </div>
        <div className="email-info">
          <p>1 month ago</p>
          <button className="prfle-link-button">Do you want to change password?</button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSet;