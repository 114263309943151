import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar/sidebar'; 
import Footer from './Footer/index'; 
import Navbar from './Navbar/index';  
import './Layout.css';  

const Layout = () => {
  return (
    <div className="laygy-layout">
      <Navbar />  
      <div className="laygy-main-content">
        <Sidebar />
        <div className="laygy-content-wrapper">
          <div className="laygy-content">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;