import React from 'react';
import './DetailsUserCoachs.css';
import constantImages from '../../../../ConstantImages/constantimages';

function DetailsUserCoachs() {
    return (
        <div className="detusco-details-card">
            <div className="detusco-details-header">
                <h2 className="detusco-gym-name">Abir Moussi</h2>
                <p className="detusco-plan">Current Plan</p>
            </div>
            <div className="detusco-details-content">
                <div className="detusco-details-info">
                    <div className="detusco-info-pair">
                        <div className="detusco-info-item">
                            <h4>Location</h4>
                            <p>Sousse</p>
                        </div>
                    </div>
                    <div className="detusco-info-pair">
                        <div className="detusco-info-item">
                            <h4>Age</h4>
                            <p>23</p>
                        </div>
                    </div>
                    <div className="detusco-info-pair">
                        <div className="detusco-info-item">
                            <h4>Experience</h4>
                            <p>4</p>
                        </div>
                    </div>
                </div>
                <div className="detusco-details-logo">
                    <img src={constantImages.coach} alt="Coach" className="detusco-coach-image" />
                </div>
            </div>
        </div>
    );
}

export default DetailsUserCoachs;