// src/Components/Dashboard/Coaches/Profile/profile.js
import React, { useState } from 'react';
import './profile.css';
import { MdLocationOn } from 'react-icons/md';
import { FaStar, FaUser, FaDumbbell } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import Trainee from '../Trainee/trainee';  
import SuccessPopup from '../../Gym/SuccessPopup/SuccessPopup';

const Profile = () => {
  const { name } = useParams(); 
  const [activeMenu, setActiveMenu] = useState('profile');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const profileData = {
    name: name || 'Fares SAFER', 
    occupation: "Gym/Private Coaching",
    location: "Sousse",
    description: "Lorem ipsum dolor sit amet consectetur. Gravida risus nibh tristique id in egestas quam nisl pellentesque. Habitant malesuada turpis ullamcorper diam metus. Volutpat quis id mauris viverra ante. Eu neque vitae quisque nunc ut molestie sit. Sit magna amet rhoncus tempor porta in. Vivamus dui tortor condimentum arcu libero. Sit quis nunc tellus eget risus viverra. Aliquam lectus sit a arcu arcu metus. Ipsum lacus eget est urna potenti. Sit quis nunc tellus eget risus viverra. Aliquam lectus sit a arcu arcu metus. Ipsum lacus eget est urna potenti.",
    rating: 5,
    reviews: 250,
    trainees: 12,
    experience: 4,
    imageUrl: "https://images.squarespace-cdn.com/content/v1/5398ac21e4b0299b7de13734/1d874364-8675-459a-88f3-ef546c1a1669/PXL_20240305_201650998.PORTRAIT.jpg"
  };

  const renderContent = () => {
    if (activeMenu === 'profile') {
      return (
        <div className="prf-profile-content">
          <div className="prf-profile-details">
            <div className="prf-profile-name">
              {profileData.name}
            </div>
            <div className="prf-profile-occupation">
              {profileData.occupation}
            </div>
            <div className="prf-profile-location">
              <MdLocationOn style={{ color: 'black' }} /> {profileData.location}
            </div>
            <div className="prf-profile-description">
              {profileData.description}
            </div>
            <div className="prf-profile-rating">
              {Array(profileData.rating).fill().map((_, i) => (
                <FaStar key={i} style={{ color: 'black' }} />
              ))}
              <span>({profileData.reviews})</span>
            </div>
            <div className="prf-profile-stats">
              <div className="prf-profile-trainees">
                {profileData.trainees}
                <p>Trainee</p>
              </div>
              <div className="prf-profile-experience">
                {profileData.experience}
                <p>Years of Experience</p>
              </div>
            </div>
          </div>
          <div className="prf-profile-image">
            <img src={profileData.imageUrl} alt="Profile" />
          </div>
        </div>
      );
    } else if (activeMenu === 'trainee') {
      return <Trainee />;
    }
  };

  const renderModal = () => (
    <div className="prf-modal-overlay" onClick={() => setIsModalOpen(false)}>
      <div className="prf-modal-content" onClick={e => e.stopPropagation()}>
        <div className="prf-modal-header">
          <h2>Add Subscription</h2>
          <button className="prf-modal-close-button" onClick={() => setIsModalOpen(false)}>X</button>
        </div>
        <div className="prf-modal-body">
          <label>Email</label>
          <input 
            type="text" 
            placeholder="Samirloussif@gmail.com" 
            value={email} 
            onChange={(e) => {
              setEmail(e.target.value);
              setIsEmailValid(e.target.value.includes('@'));
            }}
          />
        </div>
        <div className="prf-modal-footer">
          <button 
            className="prf-modal-submit-button" 
            onClick={() => {
              if (isEmailValid) {
                setIsModalOpen(false);
                setIsSuccessPopupOpen(true);
              } else {
                alert('Please enter a valid email address.');
              }
            }}
          >
            Add Event
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className='prf-profile-coach_profile'>
      <div className="prf-breadcrumb">
        <span className="">&lt;</span>
        <Link to="/overview"> Overview</Link> / 
        <Link to="/coaches"> Coaches</Link> / 
        <span>{name || 'Fares SAFER'}</span> 
      </div>
      <div className="prf-profile-header">
        {activeMenu === 'profile' ? 'Profile' : 'Trainee'}
      </div>

      <div className="prf-profile-main">
        <div>
          <div className="prf-profile-menu-container">
            <button 
              className={`prf-profile-menu-item ${activeMenu === 'profile' ? 'active' : ''}`}
              onClick={() => setActiveMenu('profile')}
            >
              <FaUser className="prf-profile-menu-icon" /> Profile
            </button>
            <button 
              className={`prf-profile-menu-item ${activeMenu === 'trainee' ? 'active' : ''}`}
              onClick={() => setActiveMenu('trainee')}
            >
              <FaDumbbell className="prf-profile-menu-icon" /> Trainer
            </button>
          </div>
        </div>
        <div className={`prf-profile-content-container ${activeMenu === 'trainee' ? 'prf-empty-background' : ''}`}>
          {renderContent()}
        </div>
      </div>

      {isModalOpen && renderModal()}
      {isSuccessPopupOpen && (
        <SuccessPopup 
          message="Subscription added successfully!" 
          onClose={() => setIsSuccessPopupOpen(false)} 
        />
      )}
    </div>
  );
};

export default Profile;