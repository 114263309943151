// src/UserComponents/UserDashboard/UserTraining/TrainingCoachDetails/TrainingCoachDetails.js
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './TrainingCoachsDetails.css';
import DetailsUserCoachs from './DetailsUserCoachs/DetailsUserCoachs';
import PaymentHistoryUserCoachs from './PaymentHistoryUserCoachs/PaymentHistoryUserCoachs';

const TrainingCoachsDetails = () => {
  const { coachName } = useParams();

  return (
    <div>
      <nav className="detco-nav">
        <ol className="detco-breadcrumb">
          <li className="detco-breadcrumb-item">
            <Link to="/user/training">
              <span className="detco-breadcrumb-arrow">&lt;</span> Training
            </Link>
          </li>
          <li className="detco-breadcrumb-item">
            <span className="detco-breadcrumb-slash">/</span>
            <Link to="/user/training/coach">
              <span className="detco-breadcrumb-arrow"></span> Coach
            </Link>
          </li>
          <li className="detco-breadcrumb-item active" aria-current="page">
            <span className="detco-breadcrumb-slash">/</span>
            {coachName}
          </li>
        </ol>
      </nav>
      <div className="detco-details-user-container">
        <DetailsUserCoachs />
      </div>
      <div className="detco-payment-history-user-container">
        <PaymentHistoryUserCoachs />
      </div>
      <div className="detco-button-container">
        <button className="detco-renew-subscription-button">Renew Subscription</button>
      </div>
    </div>
  );
};

export default TrainingCoachsDetails;