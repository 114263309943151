import React, { useEffect, useState, useContext } from 'react';
import './PlanningSch.css';
import CalendarHeader from '../CalendarHeader/CalendarHeader';
import { EventContext } from '../EventContext/EventContext';

const PlanningSch = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const { events } = useContext(EventContext);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (date) => date.toLocaleTimeString('en-TN', { hour: '2-digit', minute: '2-digit', timeZone: 'Africa/Tunis' });

  const groupEventsByDate = (events) => {
    return events.reduce((acc, event) => {
      const date = event.date.toDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(event);
      return acc;
    }, {});
  };

  const groupedEvents = groupEventsByDate(events);

  const generateHours = () => {
    const colors = ['plann-violet', 'plann-redfatah', 'plann-beigefatah', 'plann-green', 'plann-yellow', 'plann-blue', 'plann-purple'];
    const hours = [];
    for (let i = 10; i <= 22; i++) {
      const colorClass = `plann-time-slot ${colors[(i - 10) % colors.length]}`;
      hours.push({ hour: `${i}:00`, className: colorClass });
    }
    return hours;
  };

  const hours = generateHours();

  const calculateTimeLinePosition = () => {
    const startHour = 10;
    const hourHeight = 90; 
    const gapHeight = 30;   
    const totalSlotHeight = hourHeight + gapHeight;  
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
  
     const totalMinutesSinceStart = (currentHour - startHour) * 60 + currentMinute;
  
     const position = (totalMinutesSinceStart / 60) * totalSlotHeight;
  
     const minutesInCurrentSlot = currentMinute % 60;
    const positionWithinSlot = (minutesInCurrentSlot / 60) * hourHeight;
  
     return position + positionWithinSlot - gapHeight;
  };

  return (
    <div className="plann-planning-sch">
      <CalendarHeader
        className="plann-calendar-header"
        monthYear={new Date().toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        })}
        onPrev={() => console.log('Previous month')}
        onNext={() => console.log('Next month')}
        onViewChange={(view) => console.log(`View changed to ${view}`)}
        onToday={() => console.log('Today')}
      />
      
      <div className="plann-schedule-content">
        <div className="plann-first-column">
          <div className="plann-date-box">30 July</div>
          {hours.map(({ hour, className }, index) => (
            <div key={index} className={className}>{hour}</div>
          ))}
        </div>

        <div className="plann-schedule-container">
          <div className="plann-time-line" style={{ top: `${calculateTimeLinePosition()}px` }}>
            <div className="plann-time-line-time">{formatTime(currentTime)}</div>
          </div>
          {Object.keys(groupedEvents).map((date, index) => (
            <div key={index} className="plann-event-row">
              {groupedEvents[date].map((event, eventIndex) => (
                <div key={eventIndex} className="plann-event-card" style={{ borderColor: event.color }}>
                  <div className="plann-event-header">
                    <div className="plann-event-title">{event.title}</div>
                    <div className="plann-event-time">{`${event.startTime} - ${event.endTime}`}</div>
                  </div>
                  <div className="plann-event-description">{event.description}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanningSch;