import React, { useState } from 'react';
import './gymdetails.css';
import { useNavigate } from 'react-router-dom';

const GymDetails1 = () => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/socialmedia');
    console.log('Full Name:', fullName);
    console.log('Phone Number:', phoneNumber);
  };

  return (
    <div className="gydet-page-container">
      <div className="gydet-gym-details-container">
        <h2>Gym Details</h2>
        <p>Please ensure that you enter the correct details about your gym, as this information will be visible on the website.</p>
        <form>
          <div className="gydet-form-group">
            <label htmlFor="fullName">Full Name*</label>
            <input
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="California Gym@gmail.Com"
            />
          </div>
          <div className="gydet-form-group">
            <label htmlFor="phoneNumber">Phone Number*</label>
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="+216892258"
            />
          </div>
          <button type="button" className="gydet-next-button" onClick={handleNext}>
            Next
          </button>
          <button type="button" className="gydet-skip-button">
            Skip
          </button>
        </form>
      </div>
    </div>
  );
};

export default GymDetails1;