import React from 'react';
import { useNavigate } from 'react-router-dom';
import './shop.css';
import AllProducts from './AllProducts/allproducts';
import FilterShop from './FilterShop/filtershop';

const Shop = () => {
    const navigate = useNavigate();

    const navigateToCreateProduct = () => {
        navigate('/shop/create-product');
    };

    return (
        <div className="shop-page-container">
            <FilterShop />
            <AllProducts />
            <div className="shop-add-product-button">
                <button className="shop-add-button" onClick={navigateToCreateProduct}>
                    + Add Product
                </button>
            </div>
        </div>
    );
};

export default Shop;
