import React, { useState } from 'react';
import './edit.css';

const Edit = ({ onCancel, currentEmail, currentPassword, onSave, field }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [email, setEmail] = useState(currentEmail);
  const [confirmEmail, setConfirmEmail] = useState('');

  const validatePassword = (password) => {
    let error = '';
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);

    if (!hasUpperCase && !hasNumber) {
      error = 'Password must contain at least one uppercase letter and one number.';
    } else if (!hasUpperCase) {
      error = 'Password must contain at least one uppercase letter.';
    } else if (!hasNumber) {
      error = 'Password must contain at least one number.';
    }
    
    if (password.length < 8) {
      error = 'Password must be at least 8 characters.';
    }

    return error;
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setNewPassword(value);

    const error = validatePassword(value);
    setPasswordError(error);
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    
    if (value !== newPassword) {
      setConfirmPasswordError('Passwords do not match.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleConfirmEmailChange = (e) => {
    setConfirmEmail(e.target.value);
    if (e.target.value !== email) {
      setConfirmPasswordError('Emails do not match.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleSave = () => {
    if (field === 'email') {
      if (email === confirmEmail) {
        onSave(email);
        onCancel();
      } else {
        setConfirmPasswordError('Emails do not match.');
      }
    } else if (!passwordError && !confirmPasswordError) {
      onSave(newPassword);
      onCancel();
    } else {
      alert('Please correct the errors before saving.');
    }
  };

  return (
    <div className="edt-edit-container">
      <h3 className="edt-h3">Change {field === 'email' ? 'Email' : 'Password'}</h3>
      <form className="edt-form">
        {field === 'password' && (
          <>
            <label className='edt-editlabel'>
              Current Password
              <input
                type="text"
                value={currentPassword}
                readOnly
                className="edt-input"
              />
            </label>
            <label className='edt-editlabel'>
              New Password
              <input
                type="password"
                value={newPassword}
                onChange={handlePasswordChange}
                required
                className="edt-input"
              />
              {passwordError && <p className="edt-error-message">{passwordError}</p>}
            </label>
            <label className='edt-editlabel'>
              Confirm New Password
              <input
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
                className="edt-input"
              />
              {confirmPasswordError && <p className="edt-error-message">{confirmPasswordError}</p>}
            </label>
          </>
        )}
        {field === 'email' && (
          <>
            <label className='edt-editlabel'>
              New Email
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="edt-input"
              />
            </label>
            <label className='edt-editlabel'>
              Confirm Your Email
              <input
                type="email"
                value={confirmEmail}
                onChange={handleConfirmEmailChange}
                required
                className="edt-input"
              />
              {confirmPasswordError && <p className="edt-error-message">{confirmPasswordError}</p>}
            </label>
          </>
        )}
        <div className="edt-buttons">
          <button type="button" onClick={onCancel} className="edt-cancel-button">
            Cancel
          </button>
          <button type="button" onClick={handleSave} className="edt-save-button">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default Edit;