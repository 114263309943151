import React from 'react';
import { Route, Routes } from 'react-router-dom';
 import UserLayoutRoutes from './UserLayoutRoutes';
import { EventProvider } from '../UserComponents/UserDashboard/UserSchedule/EventContext/EventContext';
import { PageTitleUserProvider } from '../UserComponents/UserContext/PageTitleUserContext';
 
const UserRoutes = () => {
  return (
       <PageTitleUserProvider> 
        <EventProvider>
          <Routes>
            <Route path="/user/*" element={<UserLayoutRoutes />} />
          </Routes>
        </EventProvider>
      </PageTitleUserProvider>
   );
};

export default UserRoutes;