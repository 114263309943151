import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './pricing.css';
import AddPricing from './addpricing'; 

const Pricing = () => {
  const [plans, setPlans] = useState([
    {
      id: 1,
      name: 'Basic',
      price: '110 TND',
      features: [
        'Lorem ipsum dolor sit amet conse',
        'Lorem ipsum dolor sit amet conse',
        'Lorem ipsum dolor sit amet conse',
        'Lorem ipsum dolor sit amet conse',
      ],
      isOpen: false,
    },
    {
      id: 2,
      name: 'Standard',
      price: '150 TND',
      features: [
        'Feature one for Standard',
        'Feature two for Standard',
        'Feature three for Standard',
        'Feature four for Standard',
        'Feature five for Standard',
      ],
      isOpen: false,
    },
    {
      id: 3,
      name: 'Premium',
      price: '200 TND',
      features: [
        'Feature one for Premium',
        'Feature two for Premium',
        'Feature three for Premium',
        'Feature four for Premium',
        'Feature five for Premium',
      ],
      isOpen: false,
    },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get('/api/pricing');
        const fetchedPlans = response.data.map(plan => ({
          ...plan,
          id: plan.id || Math.random().toString(36).substr(2, 9),  
          isOpen: false,  
        }));
        setPlans(fetchedPlans);  
      } catch (error) {
        console.error('Error fetching pricing plans:', error);
      }
    };
    fetchPlans();
  }, []);

  const togglePlan = (id) => {
    setPlans(plans.map(plan =>
      plan.id === id ? { ...plan, isOpen: !plan.isOpen } : plan
    ));
  };

  const deletePlan = (id) => {
    setPlans(plans.filter(plan => plan.id !== id));  
  };

  const editPlan = (plan) => {
    setCurrentPlan(plan);  
    setShowModal(true);  
  };

  const handleAddClick = () => {
    setCurrentPlan(null);  
    setShowModal(true);  
  };

  const closeModal = () => {
    setShowModal(false); 
  };

  const addNewPlan = (newPlan) => {
    const newId = Math.random().toString(36).substr(2, 9);  
    setPlans([...plans, { ...newPlan, id: newId, isOpen: false }]);
  };

  return (
    <div className="pricing-container">
      {plans.map(plan => (
        <div className={`plan-card ${plan.isOpen ? 'open' : 'closed'}`} key={plan.id}>
          <div className="plan-header" onClick={() => togglePlan(plan.id)}>
            <h3>{plan.name}</h3>
            <span>{plan.isOpen ? '˅' : '˄ '}</span>
          </div>
          {plan.isOpen && (
            <>
              <div className='pricing_content'>
                <p>{plan.price}</p>
                <ul>
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <div className="plan-actions">
                  <button className="delete-btn" onClick={() => deletePlan(plan.id)}>Delete</button>
                  <button className="edit-btn" onClick={() => editPlan(plan)}>Edit</button>
                </div>
              </div>
            </>
          )}
        </div>
        
      ))}
     <div className='addBtncontainer'>
        <button className="add-btn" onClick={handleAddClick}>Adds</button>
      </div>

       {showModal && (
        <AddPricing
          closeModal={closeModal}
          currentPlan={currentPlan}
          setPlans={setPlans}
          addNewPlan={addNewPlan}  
        />
      )}
    </div>
  );
};

export default Pricing;
