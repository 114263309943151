import React, { useState } from 'react';
import './contactuser.css';

const Contact = () => {
  const [form, setForm] = useState({
    fullName: '',
    email: '',
    subject: '',
    otherSubject: '',
    message: '',
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    alert('Message sent!');
  };

  return (
    <div className="wrapper">
      <div className="header">Contact Us</div>
      <p className="intro-text">
        Got any questions or concerns? Something is unclear? Want to report a bug? 
        Fill out the form below and we will happily answer you!
      </p>
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="input-group">
          <input
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={form.fullName}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={form.email}
            onChange={handleChange}
            required
          />
        </div>
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          value={form.subject}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="otherSubject"
          placeholder="Subject (other)"
          value={form.otherSubject}
          onChange={handleChange}
        />
        <textarea
          name="message"
          placeholder="Message"
          value={form.message}
          onChange={handleChange}
          required
        />
        <div className="button-wrapper">  
          <button type="submit" className="submit-btn">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default Contact;
