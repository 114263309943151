import React, { useState } from 'react';
import './superSidebar.css';
import {
  FaHome, FaDumbbell, FaUser, FaShieldAlt, FaShoppingCart,
  FaCog, FaBars, FaTimes
} from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useSupPageTitle } from '../../context/SupPageTitleContext';

const SuperadminSidebar = () => {
  const [open, setOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeLink, setActiveLink] = useState('/Overview');
  const navigate = useNavigate();
  const { setPageTitle } = useSupPageTitle();

  const toggleMenu = (menu) => {
    if (activeMenu === menu) {
      setActiveMenu(null);
    } else {
      setActiveMenu(menu);
      if (menu === 'pricing') {
        setPageTitle('Pricing');
        navigate('/Pricing/gym');
        setActiveLink('/Pricing/gym');
      }
    }
  };

  const handleLinkClick = (link, title) => {
    if (!link.startsWith('/Pricing')) {
      setActiveMenu(null);
    }
    setActiveLink(link);
    setPageTitle(title); 
    navigate(link);
  };

  return (
    <div className="supsid-sidebar_container">
      <div className={`supsid-sidebar ${open ? '' : 'supsid-sidebar-closed'}`}>
        <div className="supsid-toggle-button" onClick={() => setOpen(!open)}>
          {open ? <FaTimes /> : <FaBars />}
        </div>
        <div className="supsid-logo">
        <img src="gymlogo.webp" alt="Gym logo" />
        </div>
        <div className="supsid-menu">
          <h3 className={`${open ? '' : 'supsid-hidden'}`}>DASHBOARD</h3>
          <ul>
            <li className={activeLink === '/Overview' ? 'active' : ''}>
              <Link to="/Overviewsuperadmin" onClick={() => handleLinkClick('/Overview', 'Overview')}>
                <FaHome className="side_icon" />
                <span className={`${open ? '' : 'supsid-hidden'}`}>Overview</span>
              </Link>
            </li>
            <li className={activeLink === '/superadmin/gym' ? 'active' : ''}>
              <Link to="/superadmin/gym" onClick={() => handleLinkClick('/superadmin/gym', 'Gym')}>
                <FaDumbbell className="side_icon" />
                <span className={`${open ? '' : 'supsid-hidden'}`}>Gym</span>
              </Link>
            </li>
            <li className={activeLink === '/superadmin/coaches' ? 'active' : ''}>
              <Link to="/superadmin/coaches" onClick={() => handleLinkClick('/superadmin/coaches', 'Coaches')}>
                <FaUser className="side_icon" />
                <span className={`${open ? '' : 'supsid-hidden'}`}>Coaches</span>
              </Link>
            </li>
            <li
              className={`menu-item ${activeMenu === 'pricing' ? 'active' : ''}`}
              onClick={() => toggleMenu('pricing')}
            >
              <FaShieldAlt className="side_icon" />
              <span className={`${open ? '' : 'supsid-hidden'}`}>Pricing</span>
            </li>
            <li className={activeLink === '/Superadminshop' ? 'active' : ''}>
              <Link to="/Superadminshop" onClick={() => handleLinkClick('/Superadminshop', 'Shop')}>
                <FaShoppingCart className="side_icon" />
                <span className={`${open ? '' : 'supsid-hidden'}`}>Shop</span>
              </Link>
            </li>
            <li
              className={`menu-item ${activeMenu === 'settings' ? 'active' : ''}`}
              onClick={() => handleLinkClick('/settings', 'Settings')}
            >
              <FaCog className="side_icon" />
              <span className={`${open ? '' : 'supsid-hidden'}`}>Settings</span>
            </li>
          </ul>
        </div>
      </div>
      <div className={`supsid-content ${open ? '' : 'supsid-content-expanded'} ${activeMenu ? 'supsid-content-shifted' : ''}`}>
        {activeMenu === 'pricing' && (
          <div className="supsid-submenu-card">
            <ul className="supsid-submenu">
              <li className={activeLink === '/Pricing/gym' ? 'active' : ''}>
                <Link to="/Pricing/gym" onClick={() => handleLinkClick('/Pricing/gym', 'Pricing')}>
                  <FaDumbbell />
                  <span>Gym</span>
                </Link>
              </li>
              <li className={activeLink === '/Pricing/coach' ? 'active' : ''}>
                <Link to="/Pricing/coach" onClick={() => handleLinkClick('/Pricing/coach', 'Pricing')}>
                  <FaUser />
                  <span>Coach</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuperadminSidebar;