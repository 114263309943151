import React, { useEffect, useState } from 'react';
import './MenuCard.css'; 
import { FaDumbbell, FaPlus, FaEdit, FaTrash, FaTimes } from 'react-icons/fa';  
import { PiBowlFoodFill } from 'react-icons/pi';
import Food from '../Food/Food';  
import ExerciseCard from '../Food/ExerciseCard';  
import useApi from '../../api/useApi';

const MenuCard = () => {
  const [showFoodModal, setShowFoodModal] = useState(false);
  const [showFoodCard, setShowFoodCard] = useState(false);
  const [showExerciseCard, setShowExerciseCard] = useState(false);  
  const [submittedFood, setSubmittedFood] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  const [editFoodData, setEditFoodData] = useState(null); 
  const { request } = useApi();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const coachId = localStorage.getItem('id');
    setUserId(coachId); // Setting the userId
    getFood(coachId);  // Fetch the food when component mounts
  }, []);  

  const handleFoodClick = () => {
    setShowFoodCard(true);  
    setShowExerciseCard(false);  
    setEditFoodData(null);  
    setShowFoodModal(false);  
  };

  const handleExerciseCardClick = () => {
    setShowExerciseCard(true);  
    setShowFoodCard(false);  
  };

  const closeFoodModal = () => {
    setShowFoodModal(false);
  };

  const handleFoodSubmit = async (data) => {
    await getFood(userId);  
    setShowFoodModal(false);  
  };

  // Fetch only the nutrition added by the connected coach
  const getFood = async (coachId) => {
    setIsLoading(true);
    try {
      // Fetch food data using coachId, filtering by the coach's ID
      const data = await request('get', `nutrition?coachId=${coachId}`);
      setSubmittedFood(data);  
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to fetch nutrition plan. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (food) => {
    setEditFoodData(food); 
    setShowFoodModal(true);  
  };

  const handleDelete = async (foodId) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      setIsLoading(true);
      try {
        await request('delete', `nutrition/${foodId}`);
        await getFood(userId);  
      } catch (error) {
        console.error('Error:', error);
        alert('Failed to delete nutrition plan. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="co-menu-card-container">
      <div className="co-menu-card">
        <div className="co-menu-item" onClick={handleExerciseCardClick}>
          <FaDumbbell className="co-menu-icon" />
          <span>Exercices</span>
        </div>
        <div className="co-menu-item" onClick={handleFoodClick}>
          <PiBowlFoodFill className="co-menu-icon" />
          <span>Food</span>
        </div>
      </div>

      {showExerciseCard && (
        <div className="co-exercise-card-container">
          <ExerciseCard 
            onAddExercise={() => {
              console.log("Add Exercise button clicked");
            }}
          />
        </div>
      )}

      {showFoodCard && (
        <div className="co-food-card-container">
          <div className="co-add-food-card">
            <button
              className="co-add-food-button"
              onClick={() => setShowFoodModal(true)}  
              aria-label="Add Food"
            >
              <FaPlus className="co-add-food-icon" />
              <span>Add Food</span>
            </button>

            {isLoading && <p>Loading...</p>}  

            {submittedFood.length > 0 && (
              <div className="co-submitted-food-details">
                {submittedFood
                  .filter((food) => food.coachId === userId) // Filtering based on coachId
                  .map((food) => (
                    <div key={food._id} className="co-food-item">
                      <h3>{food.plateName}</h3>
                      <ul>
                        {food.ingredients.map((ingredient, idx) => (
                          <li key={idx}>
                            {ingredient.ingredientName} - {ingredient.measure}
                          </li>
                        ))}
                      </ul>
                      <div className="co-food-item-actions">
                        <button
                          className="co-edit-buttonFood"
                          onClick={() => handleEdit(food)}
                          aria-label="Edit"
                        >
                          <FaEdit />
                        </button>
                        <button
                          className="co-delete-buttonFood"
                          onClick={() => handleDelete(food._id)}
                          aria-label="Delete"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}

      {showFoodModal && (
        <div className="co-food-modal">
          <button
            className="co-food-modal-close"
            onClick={closeFoodModal}
            aria-label="Close Food Modal"
          >
            <FaTimes /> {/* Added close icon */}
          </button>
          <Food
            onSubmit={handleFoodSubmit}
            closeModal={closeFoodModal}
            existingData={editFoodData}  
          />
        </div>
      )}
    </div>
  );
};

export default MenuCard;
