import React from 'react';
import './stat.css';
import { FaMoneyBillWave, FaUsers, FaShoppingCart, FaChartLine } from 'react-icons/fa';

const fakeData = [
  { title: 'Total Balance', value: '$3,908', change: '+80%', icon: <FaMoneyBillWave />, color: 'green' },
  { title: 'Total Subscriptions', value: '2536', change: '+20%', icon: <FaUsers />, color: 'orange' },
  { title: 'New Orders', value: '2565', change: '+50%', icon: <FaShoppingCart />, color: 'purple' },
  { title: 'YoY Growth', value: '21', change: '-10%', icon: <FaChartLine />, color: 'red' },
];

const StatCard = ({ title, value, change, icon, color }) => {
  return (
    <div className="stat-card">
      <div className={`stat-icon ${color}`}>{icon}</div>
      <div className="stat-details">
        <div className="stat-title">{title}</div>
        <div className="stat-value">{value}</div>
        <div className={`stat-change ${color}`}>{change}</div>
      </div>
    </div>
  );
};

const Stat = () => {
  return (
    <div className="stat-container">
      {fakeData.map((data, index) => (
        <StatCard key={index} {...data} />
      ))}
    </div>
  );
};

export default Stat;
