//src/SuperComponents/SuperDashboard/Gym/SuccessPopupSuperCheck/SuccessPopupSuperCheck.js
import React from 'react';
import './SuccessPopupCheck.css';

const SuccessPopupCheck = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="s-success-popup-check-overlay">
      <div className="s-success-popup-check-content">
        <div className="s-success-popup-check-message">
          <div className="s-checkmark-icon-check">&#10003;</div>
          <p>{message}</p>
        </div>
        <div className="s-button-container">
          <button className="s-confirm-button" onClick={onConfirm}>Confirm</button>
          <button className="s-cancel-button" onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopupCheck;