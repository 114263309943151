//src/UserComponents/UserDashboard/UserSchedule/CalenderHeader/CalenderHeader.js
import React from 'react';

const CalendarHeader = ({ monthYear, onPrev, onNext, onViewChange, onToday }) => {
  return (
    <div className="usesch-calendar-header">
      <div className="usesch-nav-buttons">
        <button className="usesch-nav-button" onClick={onPrev}>
          {'<'}
        </button>
        <div className="usesch-month-year">{monthYear}</div>
        <button className="usesch-nav-button" onClick={onNext}>
          {'>'}
        </button>
      </div>
      <div className="usesch-view-controls">
        <select className="usesch-view-select" onChange={(e) => onViewChange(e.target.value)}>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="day">Day</option>
        </select>
        <button className="usesch-today-button" onClick={onToday}>
          Today
        </button>
      </div>
    </div>
  );
};

export default CalendarHeader;