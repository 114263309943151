// src/CoachComponents/AddCoachs/CoachsStatut/CoachsStatut.js
import React from 'react';
import './CoachsStatut.css';
import { FaTrashAlt, FaEye, FaEdit } from 'react-icons/fa'; // Import the edit icon
import { Link } from 'react-router-dom';

const CoachsStatut = ({ coaches, onDeleteCoach, onCheckboxChange, onEditCoach }) => {
    return (
        <div className="req-container">
            <h2 className="req-title">Subscription Request</h2>
            <table className="req-table">
                <thead className='thd-req'>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Pack</th>
                        <th scope="col">Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Category</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {coaches.map((coach) => (
                        <tr key={coach._id}>
                            <td>
                                <input 
                                    type="checkbox" 
                                    className="req-checkbox" 
                                    aria-label={`Select ${coach.FullName}`} 
                                    onChange={() => onCheckboxChange(coach._id)} 
                                />
                            </td>
                            <td>{coach.FullName}</td>
                            <td>{coach.Pack}</td>
                            <td>{coach.Type}</td>
                            <td>{coach.Date}</td>
                            <td className={coach.Category === 'Online' ? 'category-online' : ''}>
                                {coach.Category}
                            </td>
                            <td>
                                <span className={`status-${(coach.status || '').toLowerCase()}`}>
                                    {coach.status || 'N/A'}
                                </span>
                            </td>
                            <td>
                                <div className="req-actionIcons">
                                    <div className="icon-wrapper" aria-label={`View ${coach.FullName} Profile`}>
                                        <Link to="/coach/switch">
                                            <FaEye />
                                        </Link>
                                    </div>
                                    <div className="icon-wrapper" aria-label={`Edit ${coach.FullName}`} onClick={() => onEditCoach(coach)}>
                                        <FaEdit /> {/* Edit icon */}
                                    </div>
                                    <div className="icon-wrapper" aria-label={`Delete ${coach.FullName}`} onClick={() => onDeleteCoach(coach._id)}>
                                        <FaTrashAlt />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CoachsStatut;
