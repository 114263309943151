import React from 'react';
import './faquser.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const FAQ = () => {
  const faqs = [
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
    {
      question: "How do I access the Dashboard?",
      answer: "Lorem ipsum dolor sit amet consectetur. Diam vel sit eget ut erat eget arcu at."
    },
  ];

  return (
    <div className="faq-wrapper">
      <h1>Frequently Asked Questions</h1>
      <p className="intro-paragraph">Quick answers to questions you may have. Can't find what you're looking for? Search for your question below.</p>
      <div className="search-bar-wrapper">
        <input className="search-input" type="text" placeholder="Search here..." />
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
      </div>
      <div className="faq-items-wrapper">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-entry">
            <div className='faq-question'>{faq.question}</div>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
      <div className="contact-section">
        <div className='faq-question'>Still have questions? </div>
        <p>Can't find the answer you're looking for? Please contact us.</p>
        <div className='btn-container'>
          <Link to="/user/contact">
            <button className="contact-btn">Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
