import React, { useState } from 'react';
import './account.css';
import Edit from './edit';

const Account = () => {
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [email, setEmail] = useState('faressafer05@gmail.com');
  const [password, setPassword] = useState('wiwi123');

  const handleEditEmail = () => {
    setIsEditingEmail(true);
    setIsEditingPassword(false);
  };

  const handleEditPassword = () => {
    setIsEditingPassword(true);
    setIsEditingEmail(false);
  };

  return (
    <div className='acct-profilecontainer'>
      <div className='acct-profiletitle'><h1>Account</h1></div>
      <div className="acct-account-container">
        <h2 className="acct-h2">Account Details</h2>
        <div className="acct-account-details">
          {isEditingEmail ? (
            <Edit
              onCancel={() => setIsEditingEmail(false)}
              currentEmail={email}
              onSave={(newEmail) => setEmail(newEmail)}
              field="email"
            />
          ) : (
            <div className="acct-account-item">
              <span className="acct-label">Email</span>
              <span className="acct-value">{email}</span>
              <button className="acct-change-button" onClick={handleEditEmail}>Change</button>
            </div>
          )}

          {isEditingPassword ? (
            <Edit
              onCancel={() => setIsEditingPassword(false)}
              currentPassword={password}
              onSave={(newPassword) => setPassword(newPassword)}
              field="password"
            />
          ) : (
            <div className="acct-account-item">
              <span className="acct-label">Password</span>
              <span className="acct-value">{'*'.repeat(password.length)}</span>
              <button className="acct-change-button" onClick={handleEditPassword}>Change</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Account;