//src/Components/Dashboard/Overview/Components/FilterShop/FilterShop.js
import React from 'react';
import './FilterShop.css'; 

const FilterShop = () => {
  return (
    <div className="filsh-filterContainer">
      <label htmlFor="filterName" className="filsh-label">Filter</label>
      <div className="filsh-inputGroup">
        <input
          type="text"
          id="filterName"
          name="filterName"
          placeholder="Name"
          className="filsh-input filsh-inputName"
        />
     
        <select
          id="filterCategory"
          name="filterCategory"
          className="filsh-input filsh-inputCategory"
        >
          <option value="">Category</option>
          <option value="category1">Category 1</option>
          <option value="category2">Category 2</option>
          <option value="category3">Category 3</option>
        </select>
        <button className="filsh-filterButton">Filter</button>
      </div>
    </div>
  );
};

export default FilterShop;