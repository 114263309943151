import React, { useState } from 'react';
import './logo.css';
import { useNavigate } from 'react-router-dom';

const LogoUpload = () => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/gymdetails');
    // Handle file upload logic here
    console.log(file);
  };

  return (
    <div className='logo-logo_container'>
      <div className="logo-upload-container">
        <h2 className="logo-h2">Upload Logo</h2>
        <p className="logo-p">Please upload a logo that you would like to showcase on the website.</p>
        <form onSubmit={handleSubmit}>
          <div className="logo-upload-box">
            <input type="file" accept="image/jpeg,image/png,application/pdf" onChange={handleFileChange} />
            {preview ? (
              <img src={preview} alt="Preview" className="logo-image-preview" />
            ) : (
              <>
                <img src='./upload.png' alt="upload Icon" className="logo-upload-icon" />
                <span>Drag & Drop or choose Image to upload</span>
                <p>Supported formats: Jpeg, png, pdf</p>
              </>
            )}
          </div>
          <div className="logo-buttons">
            <button type="submit" className="logo-next-btn">Next</button>
            <button type="button" className="logo-skip-btn">Skip</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LogoUpload;