import React, { useState } from 'react';
import './switsch.css';
import { FaUser, FaUtensils, FaDumbbell } from 'react-icons/fa';
import Profileco from './Request/Profileco';
import Nutrition from './Nutrition/Nutrition';
import DraggableContain from './Calender/DraggableCalender';

const SwitchComponent = () => {
  // Set 'profile' as the default active view
  const [activeView, setActiveView] = useState('profile'); 

  const handleButtonClick = (view) => {
    setActiveView(view); // Update the active view
  };

  return (
    <div className="switch-container">
      <div className="switch-tabs">
        {/* Profile Button */}
        <button
          className={`switch-tab ${activeView === 'profile' ? 'active' : ''}`}
          onClick={() => handleButtonClick('profile')}
        >
          <FaUser className="switch-icon" />
          <span>Profile</span>
        </button>

        {/* Nutrition Button */}
        <button
          className={`switch-tab ${activeView === 'nutrition' ? 'active' : ''}`}
          onClick={() => handleButtonClick('nutrition')}
        >
          <FaUtensils className="switch-icon" />
          <span>Nutrition</span>
        </button>

        {/* Training Button */}
        <button
          className={`switch-tab ${activeView === 'training' ? 'active' : ''}`}
          onClick={() => handleButtonClick('training')}
        >
          <FaDumbbell className="switch-icon" />
          <span>Training</span>
        </button>
      </div>

      {/* Conditional Rendering based on activeView */}
      <div className="content">
        {activeView === 'profile' && <Profileco />} {/* Display Profileco only when activeView is 'profile' */}
        {activeView === 'nutrition' && <Nutrition />}
        {activeView === 'training' && <DraggableContain />}
      </div>
    </div>
  );
};

export default SwitchComponent;
