import React, { useState, useEffect } from 'react';
import './Addpack.css';

const Addpack = ({ isOpen, onClose, onAddPack, editPack, onEditPack }) => {
  const validUserPermissions = [
    'coaching_permision',
    'nutrition_permission',
    'coaching_permision_group',
    'nutrition_permission_group',
  ];

  const validTypeCoaching = ['one-to-one', 'groupe_classe'];
  const validTypePresence = ['presentiel', 'EN_LINE'];
  const validDurations = ['MONTHLY', 'THREE_MONTHS', 'SIX_MONTHS', 'YEARLY'];

  const [formData, setFormData] = useState({
    name: '',
    duration: 'SIX_MONTHS', 
    price: '',
    description: '',
    userPermission: ['nutrition_permission'], 
    TypeCoaching: ['one-to-one'], 
    TypePresence: ['presentiel'],
    promotionActive: false,
    promotionPercentage: 0,
    displayPack: true, 
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (editPack) {
      setFormData({
        ...editPack,
      });
    } else {
      resetForm();
    }
  }, [editPack]);

  const resetForm = () => {
    setFormData({
      name: '',
      duration: 'SIX_MONTHS', 
      price: '',
      description: '',
      userPermission: ['nutrition_permission'], 
      TypeCoaching: ['one-to-one'], 
      TypePresence: ['presentiel'],
      promotionActive: false,
      promotionPercentage: 0,
      displayPack: true, 
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleMultiSelectChange = (name, values) => {
    setFormData((prev) => ({
      ...prev,
      [name]: values, 
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = 'Name should not be empty';
    }

    if (isNaN(formData.price) || formData.price <= 0) {
      newErrors.price = 'Price must be a positive number';
    }

    if (formData.promotionActive && (isNaN(formData.promotionPercentage) || formData.promotionPercentage < 0 || formData.promotionPercentage > 100)) {
      newErrors.promotionPercentage = 'Promotion percentage must be a number between 0 and 100';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const preparedData = {
      ...formData,
      price: parseFloat(formData.price),
      promotionPercentage: parseFloat(formData.promotionPercentage) || 0,
    };

    if (editPack) {
      onEditPack(preparedData);
    } else {
      onAddPack(preparedData);
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{editPack ? 'Edit Pack' : 'Add New Pack'}</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Nom du pack"
            required
          />
          {errors.name && <p className="error">{errors.name}</p>}

          <label htmlFor="duration">Duration</label>
          <select 
            name="duration" 
            value={formData.duration} 
            onChange={handleChange} 
            required
          >
            {validDurations.map((dur) => (
              <option key={dur} value={dur}>
                {dur}
              </option>
            ))}
          </select>

          <label htmlFor="price">Price</label>
          <input
            type="number"
            name="price"
            value={formData.price || ''} 
            onChange={handleChange}
            placeholder="Prix en €"
            required
          />
          {errors.price && <p className="error">{errors.price}</p>}

          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Description"
            required
          />

          <label htmlFor="userPermission">User Permissions</label>
          <select 
            name="userPermission" 
            value={formData.userPermission} 
            onChange={(e) => handleMultiSelectChange('userPermission', Array.from(e.target.selectedOptions, option => option.value))}
            multiple
          >
            {validUserPermissions.map((perm) => (
              <option key={perm} value={perm}>
                {perm}
              </option>
            ))}
          </select>

          <label htmlFor="TypeCoaching">Type of Coaching</label>
          <select 
            name="TypeCoaching" 
            value={formData.TypeCoaching} 
            onChange={(e) => handleMultiSelectChange('TypeCoaching', Array.from(e.target.selectedOptions, option => option.value))}
            multiple
          >
            {validTypeCoaching.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          <label htmlFor="TypePresence">Type of Presence</label>
          <select 
            name="TypePresence" 
            value={formData.TypePresence[0]} 
            onChange={(e) => handleMultiSelectChange('TypePresence', [e.target.value])}
          >
            {validTypePresence.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          <label htmlFor="promotionActive">Promotion Active</label>
          <input
            type="checkbox"
            name="promotionActive"
            checked={formData.promotionActive}
            onChange={handleChange}
          />

          {formData.promotionActive && (
            <>
              <label htmlFor="promotionPercentage">Promotion Percentage</label>
              <input
                type="number"
                name="promotionPercentage"
                value={formData.promotionPercentage >= 0 ? formData.promotionPercentage : 0} 
                onChange={handleChange}
                placeholder="Promotion Percentage"
              />
              {errors.promotionPercentage && (
                <p className="error">{errors.promotionPercentage}</p>
              )}
            </>
          )}

          <button type="submit" className="updatepack">
            {editPack ? 'Update' : 'Add'}
          </button>
          <button type="button" onClick={onClose} className="closepack">
            Close
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addpack;
