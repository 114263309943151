import React from 'react';
import { useLocation } from 'react-router-dom';
import './SuccessPage.css';

const SuccessPage = () => {
  const location = useLocation();
  const { plateName, ingredients } = location.state; // Access the passed state

  return (
    <div className="success-page">
      <h2>Nutrition Plan Saved Successfully!</h2>
      <div className="nutrition-details">
        <h3>Plate Name: {plateName}</h3>
        <ul>
          {ingredients.map((ingredient, index) => (
            <li key={index}>
              {ingredient.ingredientName} - {ingredient.measure}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SuccessPage;
