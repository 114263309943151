//src/CoachComponents/Calender/DraggableCalender/index.jsx
import DragCalendar from "./DragCalendar";
import React, { Fragment } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import { Dragging_Event } from "../../../Constant";
import HeaderCard from "../../../Components/Common/Component/HeaderCard";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const DraggableContain = () => {
 return (
   <Fragment>
     <Breadcrumbs mainTitle="Dragable Calender" parent="Apps" title="Dragable Calender" />
     <Container fluid={true} className="calendar-basic">
       <Row>
         <Col sm="12">
           <Card style={{ width: "240%" }}>
             <HeaderCard title={Dragging_Event} />
             <CardBody>
                 <Row>
                   <DragCalendar />
                 </Row>
             </CardBody>
           </Card>
         </Col>
       </Row>
     </Container>
   </Fragment>
 );
};
export default DraggableContain;
