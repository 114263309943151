import React, { useState } from 'react';
import './gym.css';
import Allsubscription from './AllSubscription/allsubscription';
import FilterGym from './FilterGym/filtergym';
import SuccessPopup from './SuccessPopup/SuccessPopup'; 
import { subscriptionData as initialSubscriptionData } from '../../../Data/allsubsdate';
import { RiAddBoxFill } from 'react-icons/ri';  

const Gym = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState(initialSubscriptionData);
    const [filteredData, setFilteredData] = useState(initialSubscriptionData);
    const [checkedItems, setCheckedItems] = useState([]);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleAddEvent = (e) => {
        e.preventDefault();
        setShowPopup(false); 
        setShowSuccessPopup(true); 
    };

    const closeSuccessPopup = () => {
        setShowSuccessPopup(false);
    };

    const handleCheck = () => {
        const updatedData = subscriptionData.map((item, index) => 
            checkedItems.includes(index) ? { ...item, status: 'Payed' } : item
        );
        setSubscriptionData(updatedData);
        setFilteredData(updatedData);  
        setCheckedItems([]); 
    };

    const handleFilterChange = ({ name, date, status }) => {
        const filtered = subscriptionData.filter(item => {
            return (name ? item.name.toLowerCase().includes(name.toLowerCase()) : true) &&
                   (date ? item.date.includes(date) : true) &&
                   (status ? item.status.toLowerCase() === status.toLowerCase() : true);
        });
        setFilteredData(filtered);
    };

    return (
        <div className='dash-gymm'>
            <div className='dashfilt-gym'>
                <FilterGym onFilterChange={handleFilterChange} />
            </div>
            <Allsubscription 
                subscriptionData={filteredData}
                setCheckedItems={setCheckedItems} 
                checkedItems={checkedItems} 
            />
            {checkedItems.length > 0 ? (
                <div className="gym-add-subscription-button">
                    <button className="gym-add-button" onClick={handleCheck}>
                        <img src="checkbutton.png" alt="" /> Check
                    </button>
                </div>
            ) : (
                <div className="gym-add-subscription-button">
                    <button className="gym-add-button" onClick={togglePopup}>
                        <RiAddBoxFill size={24} />  
                        Add Subscription
                    </button>
                </div>
            )}
            {showPopup && (
                <div className="gym-popup-overlay">
                    <div className="gym-popup-content">
                        <button className="gym-close-button" onClick={togglePopup}>
                            &times;
                        </button>
                        <div className="gym-popup-title">Add Subscription</div>
                        <form onSubmit={handleAddEvent}>
                        <label className="suppp-email-address">Email Address</label>
              <input type="email" name="email" className="supp-default-gray supp-email-address" placeholder="Samirloussif@gmail.com" required />
              <label className="suppp-pack">Pack</label>
              <select name="pack" className="supp-pack-select supp-pack">
                <option value="Standards">Standards</option>
                <option value="Premium">Premium</option>
                <option value="Basic">Basic</option>
              </select>
              <label className="suppp-amount-payed">Statut</label>
              <select name="amount" className="supp-pack-select supp-amount-payed">
                <option value="TND">Payed</option>
                <option value="USD">Pending</option>
               </select>
              <button type="submit" className="supp-gym-add-event-button">Add Subscription</button>
                        </form>
                    </div>
                </div>
            )}
            {showSuccessPopup && (
                <SuccessPopup 
                    title="Add Subscription" 
                    message="An email has been sent to the gym regarding his account." 
                    onClose={closeSuccessPopup} 
                />
            )}
        </div>
    );
};

export default Gym;