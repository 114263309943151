//src/Data/allsubsdata.js
export const subscriptionData = [
    { name: 'Wajdi', pack: 'Standards', gender: 'Male', amount: '250 TND', date: '12/05/2024', phone: '+21685968325', status: 'Pending' },
    { name: 'Hamza', pack: 'Standards', gender: 'Male', amount: '250 TND', date: '12/05/2024', phone: '+21685968325', status: 'payed' },
    { name: 'khaled', pack: 'Standards', gender: 'Male', amount: '250 TND', date: '12/05/2024', phone: '+21685968325', status: 'Pending' },
    { name: 'Jane Cooper', pack: 'Standards', gender: 'Male', amount: '250 TND', date: '12/06/2024', phone: '+21685968325', status: 'Pending' },
    { name: 'Jane Cooper', pack: 'Standards', gender: 'Male', amount: '250 TND', date: '12/05/2024', phone: '+21685968325', status: 'Pyed' },
    { name: 'Jane Cooper', pack: 'Standards', gender: 'Male', amount: '250 TND', date: '12/05/2023', phone: '+21685968325', status: 'Pending' },

    // ... other data
];