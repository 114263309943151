//src/SuperComponents/SuperDashboard/Pricing/Gym/addpricing.js
import React, { useState, useEffect } from 'react';
import './addpricing.css';
import useApi from '../../../../api/useApi';

const Modal = ({ show, onClose, message }) => {
  if (!show) return null;

  return (
    <div className="modal-done" onClick={onClose}>
      <div className="modal_saved" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="checkmark-circle">
            <div className="background"></div>
            <div className="checkmark"></div>
          </div>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

const GymAddPricing = ({ closeModal, currentPlan, setPlans, addNewPlan, updatePlan }) => {
  const [name, setName] = useState(currentPlan?.name || '');
  const [price, setPrice] = useState(currentPlan?.price || '');
  const [promotion, setPromotion] = useState(currentPlan?.promotionPercentage || '');
  const [duration, setDuration] = useState(currentPlan?.duration || 'MONTHLY');
  const [description, setDescription] = useState(currentPlan?.description || '');
  const [nbCoach, setNbCoach] = useState(currentPlan?.nbCoach || '');
  const [promotionActive, setPromotionActive] = useState(currentPlan?.promotionActive || false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { request } = useApi();

  const [autorisation, setAutorisation] = useState(currentPlan?.autorisation || []);

   useEffect(() => {
   }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!name || !price || !description || !nbCoach) {
      alert('Please fill in all required fields.');
      return;
    }
  
    const data = {
      name,
      price: parseFloat(price),
      promotionPercentage: parseFloat(promotion),
      duration,
      description,
      nbCoach: parseInt(nbCoach, 10),
      autorisation, 
      promotionActive,
    };
  
    console.log('Request Payload:', data); 
  
    try {
      let response;
      if (currentPlan) {
        console.log('Updating pack with ID:', currentPlan._id); 
        response = await request('put', `gympacks/${currentPlan._id}`, data);
        console.log('Update Response:', response); 
        updatePlan(response); 
      } else {
        response = await request('post', 'gympacks', data);
        console.log('Create Response:', response); 
        addNewPlan(response); 
      }
  
      if (response) {
        setShowConfirmationModal(true);
  
        setTimeout(() => {
          setShowConfirmationModal(false);
          closeModal();
        }, 2000);
      } else {
        console.error('Failed to save data:', response);
      }
    } catch (error) {
      
    }
  };
  return (
    <div className="modal-backdrop" onClick={closeModal}>
      <div className="modal_form" onClick={(e) => e.stopPropagation()}>
        <div className="add-pricingform">
          <h2>{currentPlan ? 'Edit Pricing Pack' : 'Add Pricing Pack'}</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Name of the Pack
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </label>
            <label>
              Price(TND)/Month
              <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
            </label>
            <label>
              Promotion
              <input type="text" value={promotion} onChange={(e) => setPromotion(e.target.value)} />
            </label>
            <label>
              Duration
              <select value={duration} onChange={(e) => setDuration(e.target.value)}>
                <option value="MONTHLY">Monthly</option>
                <option value="YEARLY">Yearly</option>
              </select>
            </label>
            <label>
              Description
              <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />
            </label>
            <label>
              Number of Coaches
              <input type="number" value={nbCoach} onChange={(e) => setNbCoach(e.target.value)} required />
            </label>
            <label>
              Promotion Active
              <input type="checkbox" checked={promotionActive} onChange={(e) => setPromotionActive(e.target.checked)} />
            </label>
            <label>
              Autorisation
              <select multiple value={autorisation} onChange={(e) => setAutorisation([...e.target.selectedOptions].map(o => o.value))}>
                <option value="displayGym_permission">Display Gym Permission</option>
                <option value="shopGym_permission">Shop Gym Permission</option>
                <option value="displayCoach_permission">Display Coach Permission</option>
                <option value="coaching_permision">Coaching Permission</option>
                <option value="shopCoach_permission">Shop Coach Permission</option>
                <option value="nutrition_permission">Nutrition Permission</option>
              </select>
            </label>
            <div className="form_btn">
              <button type="submit" className='done_btn'>Done</button>
            </div>
          </form>
        </div>
      </div>

      {showConfirmationModal && (
        <Modal show={showConfirmationModal} onClose={() => setShowConfirmationModal(false)} message="A Pack Has been Added" />
      )}
    </div>
  );
};

export default GymAddPricing;