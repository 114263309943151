import React, { useState } from 'react';
import './CreateProduct.css';

const CreateProduct = () => {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission
        // Logic to handle product creation goes here

        setShowSuccessMessage(true); // Show success message
    };

    const handleCloseMessage = () => {
        setShowSuccessMessage(false); // Hide success message
    };

    return (
        <div className="create-product-container">
            <h1>Create Product</h1>

            {showSuccessMessage && (
                <div className="success-popup-overlay">
                    <div className="success-popup-content">
                        <div className="checkmark-icon">&#10004;</div>
                        <h2 className="popup-title">Product Added Successfully</h2>
                        <div className="popup-divider"></div>
                        <button className="done-button" onClick={handleCloseMessage}>Done</button>
                    </div>
                </div>
            )}

            <form className="create-product-form" onSubmit={handleSubmit}>
                <div className="form-group">
                <input type="text" placeholder="Product Name" />

                </div>

                <div className="form-group">
                <textarea type="text" placeholder="Description" />
                    
                </div>

                <div className="form-section">
                    <h3>Details</h3>
                    <div className="form-group">
                    <input type="text" placeholder="Inventaire" />

                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="Category" />
                        </div>
                </div>

                <div className="form-section">
                    <h3>Pricing</h3>
                    <div className="form-group">
                        <input type="text" placeholder="Price HT" />
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="Discount" />
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="Compare to price" />
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="TVA %" />
                    </div>
                </div>

                <div className="form-section">
                    <h3>Images</h3>
                    <div className="form-group">
                        <input type="file" />
                    </div>
                </div>

                <div className="form-section">
                    <h3>Visibility</h3>
                    <div className="form-group">
                        <input type="checkbox" />
                        <label>Visible in The Shop</label>
                    </div>
                </div>

                <button type="submit" className="shop-add-event-button">Add Product</button>
            </form>
        </div>
    );
};

export default CreateProduct;
