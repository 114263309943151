import React, { useState, useEffect, useContext } from 'react';
import './UserSchedule.css';
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import { EventContext } from './EventContext/EventContext';
import useApi from '../../../api/useApi';

const UserSchedule = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [hoveredDay, setHoveredDay] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [currentView, setCurrentView] = useState('month');
  const [currentDay, setCurrentDay] = useState(new Date().getDate());
  const [clickedDay, setClickedDay] = useState(null);
  const [days, setDays] = useState([{ dayName: '', description: '', startTime: '08:00', endTime: '08:00' }]);
  const { events, setEvents } = useContext(EventContext);
  const navigate = useNavigate();
  const { request } = useApi();
  const [exercises, setExercises] = useState([]);
  const [coachId, setCoachId] = useState(null);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handlePrevWeek = () => {
    const newDate = new Date(currentYear, currentMonth, currentDay - 7);
    setCurrentYear(newDate.getFullYear());
    setCurrentMonth(newDate.getMonth());
    setCurrentDay(newDate.getDate());
  };

  const handleNextWeek = () => {
    const newDate = new Date(currentYear, currentMonth, currentDay + 7);
    setCurrentYear(newDate.getFullYear());
    setCurrentMonth(newDate.getMonth());
    setCurrentDay(newDate.getDate());
  };

  const handlePrevDay = () => {
    const newDate = new Date(currentYear, currentMonth, currentDay - 1);
    setCurrentYear(newDate.getFullYear());
    setCurrentMonth(newDate.getMonth());
    setCurrentDay(newDate.getDate());
  };

  const handleNextDay = () => {
    const newDate = new Date(currentYear, currentMonth, currentDay + 1);
    setCurrentYear(newDate.getFullYear());
    setCurrentMonth(newDate.getMonth());
    setCurrentDay(newDate.getDate());
  };

  const fetchCoachId = async () => {
    try {
      const userId = localStorage.getItem('id');
      const subscriptionsResponse = await request('get', `/subUser/user/${userId}`);
      if (subscriptionsResponse.length > 0) {
        const coachId = subscriptionsResponse[0].packId.coachId;
        setCoachId(coachId);
      }
    } catch (error) {
      console.error('Error fetching coach ID:', error);
    }
  };

  const fetchExercises = async () => {
    if (!coachId) return; 
    try {
      const response = await request('get', '/exercises');
      const filteredExercises = response.filter(exercise => exercise.coachId === coachId); 
      setExercises(filteredExercises);
    } catch (error) {
      console.error('Error fetching exercises:', error);
    }
  };

  useEffect(() => {
    fetchCoachId();
  }, []);

  useEffect(() => {
    if (coachId) {
      fetchExercises();
    }
  }, [coachId]);

  const renderCalendar = () => {
    if (currentView === 'month') {
      const daysInMonth = getDaysInMonth(currentYear, currentMonth);
      const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
      const weeks = [];
      let day = 1;

      for (let i = 0; i < 6; i++) {
        const days = [];
        for (let j = 0; j < 7; j++) {
          if ((i === 0 && j < firstDayOfMonth) || day > daysInMonth) {
            days.push(<td key={j}></td>);
          } else {
            const currentDay = day;
            const dayEvents = events.filter(
              (event) =>
                event.date.getDate() === currentDay &&
                event.date.getMonth() === currentMonth &&
                event.date.getFullYear() === currentYear
            );
            const dayExercises = exercises.filter(
              (exercise) =>
                new Date(exercise.createdAt).getDate() === currentDay &&
                new Date(exercise.createdAt).getMonth() === currentMonth &&
                new Date(exercise.createdAt).getFullYear() === currentYear
            );
            days.push(
              <td
                key={j}
                onMouseEnter={() => setHoveredDay(currentDay)}
                onMouseLeave={() => setHoveredDay(null)}
                onClick={() => {
                  setClickedDay(currentDay);
                  setShowPopup(true);
                }}
              >
                {currentDay}
                {(dayEvents.length > 0 || dayExercises.length > 0) && (
                  <div className="usesch-event-container">
                    {dayEvents.map((event, index) => (
                      <div key={index} className="usesch-event-badge">
                        {event.title}
                      </div>
                    ))}
                    {dayExercises.map((exercise, index) => (
                      <div key={index} className="usesch-event-badge">
                        {exercise.exerciseName}
                      </div>
                    ))}
                  </div>
                )}
                {hoveredDay === currentDay && (
                  <button
                    className="usesch-add-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setClickedDay(currentDay);
                      setShowPopup(true);
                    }}
                  >
                    +
                  </button>
                )}
              </td>
            );
            day++;
          }
        }
        weeks.push(<tr key={i}>{days}</tr>);
      }
      return weeks;
    } else if (currentView === 'week') {
      const startOfWeek = new Date(currentYear, currentMonth, currentDay - new Date(currentYear, currentMonth, currentDay).getDay());
      const days = [];
      for (let i = 0; i < 7; i++) {
        const currentDate = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + i);
        const event = events.find(
          (event) =>
            event.date.getDate() === currentDate.getDate() &&
            event.date.getMonth() === currentDate.getMonth() &&
            event.date.getFullYear() === currentDate.getFullYear()
        );
        const exercise = exercises.find(
          (exercise) =>
            new Date(exercise.createdAt).getDate() === currentDate.getDate() &&
            new Date(exercise.createdAt).getMonth() === currentDate.getMonth() &&
            new Date(exercise.createdAt).getFullYear() === currentDate.getFullYear()
        );
        days.push(
          <td key={i}>
            {currentDate.getDate()}
            {event && <span className="usesch-event-badge">{event.title}</span>}
            {exercise && <span className="usesch-event-badge">{exercise.exerciseName}</span>}
          </td>
        );
      }
      return <tr>{days}</tr>;
    } else if (currentView === 'day') {
      const currentDate = new Date(currentYear, currentMonth, currentDay);
      const event = events.find(
        (event) =>
          event.date.getDate() === currentDate.getDate() &&
          event.date.getMonth() === currentDate.getMonth() &&
          event.date.getFullYear() === currentDate.getFullYear()
      );
      const exercise = exercises.find(
        (exercise) =>
          new Date(exercise.createdAt).getDate() === currentDate.getDate() &&
          new Date(exercise.createdAt).getMonth() === currentDate.getMonth() &&
          new Date(exercise.createdAt).getFullYear() === currentDate.getFullYear()
      );
      return (
        <tr>
          <td>
            {currentDate.getDate()}
            {event && <span className="usesch-event-badge">{event.title}</span>}
            {exercise && <span className="usesch-event-badge">{exercise.exerciseName}</span>}
          </td>
        </tr>
      );
    }
  };

  const handleAddDay = () => {
    setDays([...days, { dayName: '', description: '', startTime: '08:00', endTime: '08:00' }]);
  };

  const handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const newEvents = days.map((day, index) => ({
      date: new Date(currentYear, currentMonth, clickedDay),
      title: formData.get(`dayName-${index}`),
      description: formData.get(`description-${index}`),
      startTime: formData.get(`startTime-${index}`),
      endTime: formData.get(`endTime-${index}`),
    }));
    setEvents([...events, ...newEvents]);
    setShowPopup(false);
  };

  const handleToday = () => {
    setCurrentMonth(new Date().getMonth());
    setCurrentYear(new Date().getFullYear());
    setCurrentDay(new Date().getDate());
    navigate('/user/planning-sch');
  };

  return (
    <div className="usesch-global-schedule">
      <CalendarHeader
        className="usesch-calendar-header"
        monthYear={new Date(currentYear, currentMonth).toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        })}
        onPrev={currentView === 'month' ? handlePrevMonth : currentView === 'week' ? handlePrevWeek : handlePrevDay}
        onNext={currentView === 'month' ? handleNextMonth : currentView === 'week' ? handleNextWeek : handleNextDay}
        onViewChange={(view) => setCurrentView(view)}
        onToday={handleToday}
      />
      <table className="usesch-schedule-table">
        <thead>
          <tr>
            {daysOfWeek.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderCalendar()}</tbody>
      </table>

      {showPopup && (
        <div className="usesch-popup">
          <div className="usesch-popup-content">
            <h3 className='usesch-add-dispp'>Add Disponibility</h3>
            <form onSubmit={handleSave}>
              {days.map((day, index) => (
                <div key={index}>
                  <label>
                    Day Name:
                    <input type="text" name={`dayName-${index}`} placeholder="Add day name" className="usesch-day-name-input" />
                  </label>
                  <label>
                    Description:
                    <input type="text" name={`description-${index}`} placeholder="Add description" className="usesch-description-input" />
                  </label>
                  <span className="usesch-time-label">Start time - End time</span>
                  <div className="usesch-time-inputs">
                    <div className="usesch-time-input-row">
                      <input type="time" name={`startTime-${index}`} defaultValue="08:00" />
                      <input type="time" name={`endTime-${index}`} defaultValue="08:00" />
                    </div>
                  </div>
                </div>
              ))}
              <button type="button" className="usesch-pop-sch-plus-button" onClick={handleAddDay}><FaPlus /></button>
              <button type="submit" className="usesch-pop-sch-save-button">Save</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSchedule;