//src/CoachComponents/Setting/Security/security.js
import React from 'react';
import { FaPhone, FaEnvelope, FaLock } from 'react-icons/fa';
import './security.css';

const securityInfo = [
  '081*********',
  'jac***@email.com',
  'Last change was 1 month ago',
];

const Security = () => {
  return (
    
    <div className='sec_page'>
        <div className='sectitle_container'>
      <div className='page_title'>Security</div>
      </div>
      <div className="security-container">
        <div className="security-items">
          <div className="security-item">
            <div className="icon">
              <FaPhone size={24} color='rgba(50, 77, 169, 1)' />
            </div>
            <div className="details">
              <div className='sec_title'>Phone Number Verification</div>
              <p>Protect your account and transactions</p>
              <div className="masked-info">{securityInfo[0]}</div>
            </div>
            <button className="change-button">Change</button>
          </div>
          <div className="security-item">
            <div className="icon">
              <FaEnvelope size={24} color='rgba(50, 77, 169, 1)' />
            </div>
            <div className="details">
              <div className='sec_title'>Backup Email Address</div>
              <p>Protect your account and transactions</p>
              <div className="masked-info">{securityInfo[1]}</div>
            </div>
            <button className="change-button">Change</button>
          </div>
          <div className="security-item">
            <div className="icon">
              <FaLock size={24} color='rgba(50, 77, 169, 1)' />
            </div>
            <div className="details">
              <div className='sec_title'>Login Password</div>
              <p>Protect your account with a security key</p>
              <div className="masked-info">{securityInfo[2]}</div>
            </div>
            <button className="change-button">Change</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
