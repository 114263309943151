import React from 'react';
import './allsubscription.css';

const AllSubscription = ({ subscriptionData, setCheckedItems, checkedItems }) => {
  const handleCheckboxChange = (index) => {
    if (checkedItems.includes(index)) {
      setCheckedItems(checkedItems.filter(item => item !== index));
    } else {
      setCheckedItems([...checkedItems, index]);
    }
  };

  return (
    <div className="allsub-subscription-container">
      <div className="allsub-subscription-request">
        <h2>Subscription Request</h2>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Full Name</th>
              <th>Pack</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Phone Number</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {subscriptionData.map((item, index) => (
              <tr key={index}>
                <td>
                  <input type="checkbox" className="checkbox-spacing" onChange={() => handleCheckboxChange(index)} />
                </td>
                <td>{item.name}</td>
                <td>{item.pack}</td>
                <td style={{ color: '#6BCB77' }}>{item.amount}</td>
                <td>{item.date}</td>
                <td>{item.phone}</td>
                <td style={{ color: item.status === 'Pending' ? '#FF6B6B' : '#6BCB77' }}>
                  {item.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllSubscription;