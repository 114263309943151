//src/SuperComponents/SuperDashboard/Pricing/Gym/priicng.js
import React, { useState, useEffect } from 'react';
import { RiAddBoxFill } from "react-icons/ri";
import './pricing.css';
import GymAddPricing from './addpricing';
import useApi from '../../../../api/useApi';

const GymPricing = () => {
  const [plans, setPlans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const { request } = useApi();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const fetchedPlans = await request('get', 'gympacks');
        const plansWithIsOpen = fetchedPlans.map(plan => ({
          ...plan,
          id: plan._id || plan.id,  
          isOpen: false,  
          features: plan.features || [],  
        }));
        setPlans(plansWithIsOpen);
      } catch (error) {
        console.error('Error fetching gym packs:', error);
      }
    };
    fetchPlans();
  }, [request]);

  const togglePlan = (id) => {
    setPlans(plans.map(plan =>
      plan.id === id ? { ...plan, isOpen: !plan.isOpen } : plan
    ));
  };

  const deletePlan = async (id) => {
    try {
      await request('delete', `gympacks/${id}`);
      setPlans(plans.filter(plan => plan.id !== id));
    } catch (error) {
      console.error('Error deleting gym pack:', error);
    }
  };

  const editPlan = (plan) => {
    setCurrentPlan(plan);
    setShowModal(true);
  };

  const handleAddClick = () => {
    setCurrentPlan(null);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const addNewPlan = (newPlan) => {
    setPlans([...plans, { ...newPlan, isOpen: false, features: newPlan.features || [] }]); // Ensure features is an array
  };

  const updatePlan = (updatedPlan) => {
    setPlans(plans.map(plan => plan.id === updatedPlan.id ? updatedPlan : plan));
  };

  return (
    <div className="pricing-container">
      {plans.map(plan => (
        <div className={`plan-card ${plan.isOpen ? 'open' : 'closed'}`} key={plan.id} onClick={() => togglePlan(plan.id)}>
          <div className="plan-header">
            <h3>{plan.name}</h3>
            <span>{plan.isOpen ? '˅' : '˄ '}</span>
          </div>
          {plan.isOpen && (
            <div className='pricing_content'>
              <p>{plan.price} TND</p>
              <ul>
                {plan.features && plan.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              <div className="plan-actions">
                <button className="delete-btn" onClick={() => deletePlan(plan.id)}>Delete</button>
                <button className="edit-btn" onClick={() => editPlan(plan)}>Edit</button>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className='addBtnC'>
  <button className="add-btn" onClick={handleAddClick}>
    <RiAddBoxFill style={{ marginRight: '8px' }} />
    Add
  </button>
</div>


      {showModal && (
        <GymAddPricing
          closeModal={closeModal}
          currentPlan={currentPlan}
          setPlans={setPlans}
          addNewPlan={addNewPlan}
          updatePlan={updatePlan}
        />
      )}
    </div>
  );
};

export default GymPricing;