import React, { useState, useEffect } from 'react';
import useApi from '../../../api/useApi';
import styles from './Gallerie.module.css';

function Gallerie() {
  const api = useApi();
  const [gallery, setGallery] = useState([]); // State for the gallery
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const coachId = localStorage.getItem('id');

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        setLoading(true);
        const response = await api.request('GET', `coaches/${coachId}`);
        const { gallery } = response;

        // Initialize gallery state with current values
        setGallery(gallery.map(image => ({
          imageName: image.imageName,
          image: image.image || null,
        })));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGallery();
  }, [coachId]);

  const handleGalleryChange = (index, field, value) => {
    const updatedGallery = [...gallery];
    updatedGallery[index][field] = value;
    setGallery(updatedGallery);
  };

  const handleUpdateGallery = async (index) => {
    const imageItem = gallery[index];
    const formData = new FormData();

    // Append only the current gallery image's data to FormData
    formData.append(`gallery[${index}][imageName]`, imageItem.imageName);

    // Append the new image only if it has been changed
    if (imageItem.image instanceof File) {
      formData.append(`gallery[${index}][image]`, imageItem.image);
    }

    try {
      setLoading(true);
      await api.request('PUT', `coaches/${coachId}/`, formData, {
        'Content-Type': 'multipart/form-data',
      });
      alert(`Mise à jour de l'image de la galerie ${index + 1} réussie`);
    } catch (err) {
      console.error(`Erreur lors de la mise à jour de l'image de la galerie ${index + 1} :`, err);
      alert(`Erreur lors de la mise à jour de l'image de la galerie ${index + 1}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.Gallerie}>
      <h2 className={styles.formTitle}>Galerie</h2>
      {loading && <p>Chargement...</p>}
      {error && <p>Erreur : {error.message}</p>}

      <div className={styles.FormContainer}>
        {gallery.map((imageItem, index) => (
          <div key={index} className={styles.formGroup}>
            <h3>Image {index + 1}</h3>
            <input
              type="text"
              placeholder="Nom de l'image"
              value={imageItem.imageName}
              onChange={(e) => handleGalleryChange(index, 'imageName', e.target.value)}
              required
            />
            {imageItem.image && (
              <div className={styles.imagePreview}>
                <img src={imageItem.image} alt={`Gallery Image ${index + 1}`} className={styles.activityImage} />
              </div>
            )}
            <div className={styles.fileInputContainer}>
              <input
                type="file"
                id={`galleryImage-${index}`}
                onChange={(e) => handleGalleryChange(index, 'image', e.target.files[0])}
                accept="image/*"
              />
            </div>
            <button 
              type="button" 
              className={styles.saveButton} 
              onClick={() => handleUpdateGallery(index)} 
              disabled={loading}
            >
              Update Gallery Image 
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallerie;
