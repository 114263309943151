// src/Data/OnlineCourse/ChartData.jsx
export const ActivityHoursChartData = {
  sixMonths: {
    series: [
      {
        name: "Activity",
        data: [20, 40, 60, 80, 100, 80], // Example data for 6 months
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "bar",
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 10,
          left: 0,
          blur: 5,
          color: "#7064F5",
          opacity: 0.35,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 6,
          columnWidth: "30%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"], // 6 months
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Rubik, sans-serif",
            colors: "var(--chart-text-color)",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 5,
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Rubik, sans-serif",
            colors: "var(--chart-text-color)",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        borderColor: "var(--chart-dashed-border)",
        strokeDashArray: 5,
      },
      colors: ["#7064F5", "#8D83FF"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          gradientToColors: ["#7064F5", "#8D83FF"],
          opacityFrom: 0.98,
          opacityTo: 0.85,
          stops: [0, 100],
        },
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 200,
            },
          },
        },
      ],
    },
  },
  twelveMonths: {
    series: [
      {
        name: "Activity",
        data: [20, 40, 60, 80, 100, 80, 60, 40, 20, 40, 60, 80], // Example data for 12 months
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "bar",
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 10,
          left: 0,
          blur: 5,
          color: "#7064F5",
          opacity: 0.35,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 6,
          columnWidth: "30%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], // 12 months
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Rubik, sans-serif",
            colors: "var(--chart-text-color)",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 5,
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Rubik, sans-serif",
            colors: "var(--chart-text-color)",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        borderColor: "var(--chart-dashed-border)",
        strokeDashArray: 5,
      },
      colors: ["#7064F5", "#8D83FF"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          gradientToColors: ["#7064F5", "#8D83FF"],
          opacityFrom: 0.98,
          opacityTo: 0.85,
          stops: [0, 100],
        },
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 200,
            },
          },
        },
      ],
    },
  },
};