import React, { useState, useEffect } from 'react';
import { FaDumbbell, FaUserFriends } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import useApi from '../../../api/useApi';
import './UserTraining.css';

const UserTraining = () => {
  const [activeTab, setActiveTab] = useState('Gym');
  const [coachData, setCoachData] = useState([]);
  const { request } = useApi();

  const gymData = [
    { name: 'California Gym', pack: 'standards', date: '21/05/2025', duration: '1 Month', status: 'accepted' },
    { name: 'Extra Fitness', pack: 'standards', date: '21/05/2025', duration: '1 Month', status: 'pending' },
    { name: 'Extra Fitness', pack: 'standards', date: '21/05/2025', duration: '1 Month', status: 'pending' },
  ];

  useEffect(() => {
    const fetchUserSubscriptions = async () => {
      try {
        const userId = localStorage.getItem('id');
        const subscriptionsResponse = await request('get', `/subUser/user/${userId}`);
        const coachIds = subscriptionsResponse.map(sub => sub.packId.userId);

        const coachesResponse = await request('get', `/auth/usersbyrole?role=coach`);
        const coachesMap = coachesResponse.reduce((acc, coach) => {
          acc[coach._id] = coach.name;
          return acc;
        }, {});

        const mappedCoachData = subscriptionsResponse.map(sub => ({
          coachName: coachesMap[sub.packId.userId],
          pack: sub.packId.name,
          coachingType: sub.packId.TypeCoaching[0] || 'N/A',
          date: new Date(sub.startDate).toLocaleDateString(),
          duration: sub.packId.duration,
          status: sub.subscriptionStatus,
        }));

        // Trier les données des coachs par date de début dans l'ordre croissant
        mappedCoachData.sort((a, b) => new Date(a.date) - new Date(b.date));

        setCoachData(mappedCoachData);
      } catch (error) {
        console.error('Error fetching user subscriptions:', error);
      }
    };

    fetchUserSubscriptions();
  }, [request]);

  return (
    <div className="user-training">
      <div className="tabs">
        <div className="tab-container">
          <div
            className={`tab ${activeTab === 'Gym' ? 'active' : ''}`}
            onClick={() => setActiveTab('Gym')}
          >
            <FaDumbbell className="tab-icon" />  
            Gym
          </div>
        </div>
        <div className="tab-container">
          <div
            className={`tab ${activeTab === 'Coachs' ? 'active' : ''}`}
            onClick={() => setActiveTab('Coachs')}
          >
            <FaUserFriends className="tab-icon" />  
            Coachs
          </div>
        </div>
      </div>
      <div className="tab-content">
        {activeTab === 'Gym' && (
          <table className="training-table">
            <thead>
              <tr>
                <th>Gym Name</th>
                <th>Pack</th>
                <th>Date</th>
                <th>Duration</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {gymData.map((gym, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/user/training/gym/${gym.name}`}>
                      {gym.name}
                    </Link>
                  </td>
                  <td>{gym.pack}</td>
                  <td>{gym.date}</td>
                  <td>{gym.duration}</td>
                  <td>
                    <span className={`status ${gym.status.toLowerCase()}`}>
                      {gym.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {activeTab === 'Coachs' && (
          <table className="coach-table">
            <thead>
              <tr>
                <th>Coach Name</th>
                <th>Pack</th>
                <th>Coaching Type</th>
                <th>Date</th>
                <th>Duration</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {coachData.map((coach, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/user/training/coach/${coach.coachName}`}>
                      {coach.coachName}
                    </Link>
                  </td>
                  <td>{coach.pack}</td>
                  <td>{coach.coachingType}</td>
                  <td>{coach.date}</td>
                  <td>{coach.duration}</td>
                  <td>
                    <span className={`status status-${coach.status}`}>
                      {coach.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserTraining;