// src/Components/Dashboard/Overview/overview.js
import React from 'react';
import OrderOverview from './Components/Income/OrderOverview';
import SocialMedia from './Components/Socialmedia/socialmedia';
import Subscription from './Components/Subscription/Subscription';
import './overview.css';
import ActivityHours from './ActivityHours/ActivityHours';

const Overview = () => {
    return (
        <div className="overview-container">
            <div className="social-media">
                <SocialMedia />
            </div>
            
            <div className="activity-order-overview">
                <div className='income-cliks'>
                    <ActivityHours /> 
                    <OrderOverview />
                </div>
            </div>
            <div className="subscription">
                <Subscription />
            </div>
        </div>
    );
};

export default Overview;