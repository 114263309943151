// src/api/useApiWithErrors.js
import { useState } from 'react';
import useApi from './useApi';

const useApiWithErrors = () => {
  const { request } = useApi();
  const [error, setError] = useState(null);

  const apiRequest = async (method, url, data = null) => {
    try {
      const response = await request(method, url, data);
      setError(null);
      return response;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError('Resource not found');
      } else {
        setError('An error occurred');
      }
      throw err;
    }
  };

  return { apiRequest, error };
};

export default useApiWithErrors;