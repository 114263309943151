import React from 'react';
import { Link } from 'react-router-dom';
import './coachsdetails.css';
import Coachs from './Coachs/Coachs';
import FilterCoachs from './FilterCoachs/filtercoachs';

const CoachsDetails = () => {
  return (
    <div className="codt-coachsdetails">
      <nav aria-label="breadcrumb" className="codt-breadcrumb">
        <ol className="breadcrumb">
          <li className="codt-breadcrumb-item">
            <span>&lt; </span>
            <Link to="/Overview">Overview</Link>
          </li>
          <li className="codt-breadcrumb-separator">/</li>
          <li className="codt-breadcrumb-item">
            <Link to="/Overview/trainersrevenue">Trainers Revenue</Link>
          </li>
          <li className="codt-breadcrumb-separator">/</li>
          <li className="codt-breadcrumb-item active" aria-current="page">
            Coachs Details
          </li>
        </ol>
      </nav>
      <FilterCoachs />
      <Coachs />
    </div>
  );
};

export default CoachsDetails;