import React, { useState, useEffect } from 'react';
import './Exercise.css'; 
import useApi from '../../api/useApi';
import { fetchUser } from '../../api/auth/auth';

const Exercise = ({ existingData }) => {
  const [exerciseName, setExerciseName] = useState('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState('');
  const [repetionNumber, setRepetionNumber] = useState(0);
  const [setsNumber, setSetsNumber] = useState(0);
  const [restBetween, setRestBetween] = useState(0);
  const [difficultyLevel, setDifficultyLevel] = useState(''); 
  const [categoryType, setCategoryType] = useState(''); 
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true); 

  const { request } = useApi();

  const DIFFICULTY_LEVELS = ['BEGINNER', 'INTERMEDIATE', 'ADVANCED'];
  const CATEGORY_TYPES = ['CARDIO', 'REINFORCEMENT', 'STRETCHING'];

 
  useEffect(() => {
    if (existingData) {
      setExerciseName(existingData.exerciseName || '');
      setDescription(existingData.description || '');
      setDuration(existingData.duration || '');
      setRepetionNumber(existingData.repetionNumber || 0);
      setSetsNumber(existingData.setsNumber || 0);
      setRestBetween(existingData.restBetween || 0);
      setDifficultyLevel(existingData.difficultyLevel || '');
      setCategoryType(existingData.categoryType || '');
      setImage(existingData.image || null);
    }
  }, [existingData]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { _id } = await fetchUser(); 
  
    const formData = new FormData();
    formData.append('exerciseName', exerciseName || ''); 
    formData.append('description', description || ''); 
    formData.append('duration', duration || ''); 
    formData.append('repetionNumber', repetionNumber || 0); 
    formData.append('setsNumber', setsNumber || 0); 
    formData.append('restBetween', restBetween || 0); 
    formData.append('difficultyLevel', difficultyLevel || ''); 
    formData.append('categoryType', categoryType || ''); 
    formData.append('coachId', _id); 
  
    
    if (image) {
      formData.append('image', image);
    }
  
    setIsLoading(true);
  
    try {
     
      if (existingData) {
        await request('put', `exercises/${existingData._id}`, formData, {
          "Content-Type": "multipart/form-data"
        });
      } else {
        
        await request('post', 'exercises', formData, {
          "Content-Type": "multipart/form-data"
        });
      }
      resetForm(); 
      handleClose(); 
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to save exercise. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setExerciseName('');
    setDescription('');
    setDuration('');
    setRepetionNumber(0);
    setSetsNumber(0);
    setRestBetween(0);
    setDifficultyLevel('');
    setCategoryType('');
    setImage(null);
  };

  const handleClose = () => {
    setIsModalOpen(false); 
  };

  const handleCancel = () => {
    resetForm(); 
    handleClose(); 
  };

 
  if (!isModalOpen) {
    return null; 
  }

  return (
    <div className="exercise-popup-overlay">
      <div className="exercise-popup">
        <button className="exercise-popup-close" onClick={handleCancel} aria-label="Close"></button>
        <form onSubmit={handleSubmit} className="exercise-form-container" encType="multipart/form-data">
          <h2 className="form-title">{existingData ? 'Edit Exercise' : 'Add Exercise'}</h2>

          <div className="input-group">
            <label htmlFor="exerciseName">Exercise Name:</label>
            <input
              id="exerciseName"
              type="text"
              value={exerciseName}
              onChange={(e) => setExerciseName(e.target.value)}
              required
              placeholder="Enter exercise name"
              className="input-field"
            />
          </div>

          <div className="input-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter exercise description"
              required
              className="input-field"
            />
          </div>

          <div className="input-group">
            <label htmlFor="duration">Duration:</label>
            <input
              id="duration"
              type="text"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
              placeholder="Enter duration"
              className="input-field"
            />
          </div>

          <div className="containercardsS">
            <div className="input-group">
              <label htmlFor="repetionNumber">Number of Repetitions:</label>
              <input
                id="repetionNumber"
                type="number"
                value={repetionNumber}
                onChange={(e) => setRepetionNumber(Number(e.target.value))}
                className="input-field"
                min="0"
              />
            </div>

            <div className="input-group">
              <label htmlFor="setsNumber">Number of Sets:</label>
              <input
                id="setsNumber"
                type="number"
                value={setsNumber}
                onChange={(e) => setSetsNumber(Number(e.target.value))}
                className="input-field"
                min="0"
              />
            </div>

            <div className="input-group">
              <label htmlFor="restBetween">Rest Between Sets (in seconds):</label>
              <input
                id="restBetween"
                type="number"
                value={restBetween}
                onChange={(e) => setRestBetween(Number(e.target.value))}
                className="input-field"
                min="0"
              />
            </div>
          </div>

          <div className="input-group">
            <label htmlFor="difficultyLevel">Difficulty Level:</label>
            <select
              id="difficultyLevel"
              value={difficultyLevel}
              onChange={(e) => setDifficultyLevel(e.target.value)}
              required
              className="input-field"
            >
              <option value="">Select difficulty level</option>
              {DIFFICULTY_LEVELS.map((level) => (
                <option key={level} value={level}>{level}</option>
              ))}
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="categoryType">Category Type:</label>
            <select
              id="categoryType"
              value={categoryType}
              onChange={(e) => setCategoryType(e.target.value)}
              required
              className="input-field"
            >
              <option value="">Select category type</option>
              {CATEGORY_TYPES.map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="image">Upload Image:</label>
            <input
              id="image"
              type="file"
              onChange={handleImageChange}
              className="input-field"
            />
          </div>

          <div className="form-actions">
            <button type="submit" disabled={isLoading} className="submit-button">
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
            <button type="button" onClick={handleCancel} className="cancel-button">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Exercise;
