import React, { useState } from 'react';
import './Navbar.css';
import { usePageTitle } from '../../context/PageTitleContext';
import { IoIosNotificationsOutline } from "react-icons/io";
import constantImages from '../../../ConstantImages/constantimages';
import { Link, useNavigate } from 'react-router-dom';  

const Navbar = () => {
  const { pageTitle } = usePageTitle();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();  

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
     navigate('/');
  };

  return (
    <nav className="navgy-navbar">
      <div className="navgy-navbar-left">
        <span className="navgy-navbar-title">{pageTitle}</span>
      </div>
      <div className="navgy-navbar-right">
        <span className="navgy-navbar-pack">Votre Pack : Silver</span>
        <IoIosNotificationsOutline className="navgy-bell-icon" />
        <span className="navgy-navbar-welcome">Welcome</span>
        <div className="navgy-profile-icon-container" onClick={toggleDropdown}>
          <img src={constantImages.profile} alt="Profile Icon" className="navgy-profile-icon" />
          {isDropdownOpen && (
            <div className="navgy-dropdown">
              <ul>
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
                <li onClick={handleLogout}>Deconnexion</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;