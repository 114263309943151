// src/Components/context/PageTitleUserContext.js
import React, { createContext, useState } from 'react';

export const PageTitleUserContext = createContext();

export const PageTitleUserProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('');

  return (
    <PageTitleUserContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageTitleUserContext.Provider>
  );
};