// src/Components/Dashboard/Overview/Components/Subscription/Subscription.js
import React from 'react';
import './Subscriptionc.css';

const Subscriptionc = () => {
  const subscriptionData = [
    { name: 'Jane Cooper', pack: 'standards', type: 'one-to-one', date: '12/05/2024', status: 'Pending' },
    { name: 'Jane Cooper', pack: 'standards', type: 'one-to-one', date: '12/05/2024', status: 'Payed' },
    { name: 'Jane Cooper', pack: 'standards', type: 'one-to-one', date: '12/05/2024', status: 'Payed' },
    { name: 'Jane Cooper', pack: 'standards', type: 'one-to-one', date: '12/05/2024', status: 'Pending' },
    { name: 'Jane Cooper', pack: 'standards', type: 'one-to-one', date: '12/05/2024', status: 'Pending' },
  ];

  return (
    <div className='co-SubscriptionRequest-CoachBoard'>
      <div className="co-subscription-container">
        <div className="co-subscription-content">
          <div className="co-subscription-request">
            <h2 className="co-subscription-section-title">Subscription Request</h2>
            <table className="co-subscription-table">
              <thead className="co-subscription-request-thead">
                <tr>
                  <th>Full Name</th>
                  <th>Pack</th>
                  <th>type</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.pack}</td>
                    <td>{item.type}</td>
                    <td>{item.date}</td>
                    <td>
                      <span className={`co-subscription-status ${item.status === 'Pending' ? 'co-subscription-status-pending' : 'co-subscription-status-payed'}`}>
                        {item.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Subscriptionc;