//src/SuperComponents/SuperDashboard/Gym/SuccessPopupSuperDelete/SuccessPopupSuperDelete.js
import React from 'react';
import './SuccessPopupSuperDelete.css';
import { LuAlertTriangle } from 'react-icons/lu';

const SuccessPopupSuperDelete = ({ onConfirm, onCancel }) => {
  return (
    <div className="sup-success-popup-delete-overlay">
      <div className="sup-success-popup-delete-content">
        <div className="sup-success-popup-delete-header">
          <h3>Delete</h3>
          <button className="sup-close-button" onClick={onCancel}>
            &times;
          </button>
        </div>
        <div className="sup-success-popup-delete-body">
          <LuAlertTriangle className="sup-alert-icon" />
          <p>Alert! By confirming this, this pack will no longer be available to the public.</p>
        </div>
        <div className="sup-success-popup-delete-footer">
          <button className="sup-cancel-button" onClick={onCancel}>Cancel</button>
          <button className="sup-confirm-button" onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopupSuperDelete;