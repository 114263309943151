import React, { useState } from 'react';
import CoachsStatut from './CoachsStatut/CoachsStatut';
import FilterAddCoachs from './FilterAddCoachs/filteraddcoachs';
import SuccessPopup from '../Dashboard/Gym/SuccessPopup/SuccessPopup';  
import './AddCoachs.css'; 
import { MdAddBox } from 'react-icons/md';  
import { IoCloseOutline } from 'react-icons/io5';  
import { coachData as initialCoachesData } from '../../Data/coachstatutdata'; 

const AddCoachs = () => {
    const [coaches, setCoaches] = useState(initialCoachesData);
    const [filteredCoaches, setFilteredCoaches] = useState(coaches);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setEditIndex(null);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const newCoach = {
            name: formData.get('coachName'),
            email: formData.get('email'),
            phoneNumber: formData.get('phoneNumber'),
            commission: formData.get('percentage'),
            status: formData.get('status')
        };

        if (editIndex !== null) {
            const updatedCoaches = [...coaches];
            updatedCoaches[editIndex] = newCoach;
            setCoaches(updatedCoaches);
            setFilteredCoaches(updatedCoaches);
            setEditIndex(null);
        } else {
            setCoaches([...coaches, newCoach]);
            setFilteredCoaches([...coaches, newCoach]);
        }

        closePopup();
        setIsSuccessPopupOpen(true);
    };

    const closeSuccessPopup = () => {
        setIsSuccessPopupOpen(false);
    };

    const deleteCoach = (index) => {
        const updatedCoaches = coaches.filter((_, i) => i !== index);
        setCoaches(updatedCoaches);
        setFilteredCoaches(updatedCoaches);
    };

    const openEditPopup = (index) => {
        setEditIndex(index);
        setIsPopupOpen(true);
    };

    const handleFilterChange = ({ name, date, status }) => {
        const filtered = coaches.filter(coach => {
            return (name ? coach.name.toLowerCase().includes(name.toLowerCase()) : true) &&
                   (date ? coach.phoneNumber.includes(date) : true) &&
                   (status ? coach.status.toLowerCase() === status.toLowerCase() : true);
        });
        setFilteredCoaches(filtered);
    };

    return (
        <div className="adco-addCoachsPage">
            <FilterAddCoachs onFilterChange={handleFilterChange} />
            <CoachsStatut coaches={filteredCoaches} onDeleteCoach={deleteCoach} onEditCoach={openEditPopup} />
            <div className="adco-addCoachButtonContainer">
                <button className="adco-addCoachButton" onClick={openPopup}>
                    <MdAddBox className="adco-plusIcon" />  
                    Add Coach
                </button>
            </div>

            {isPopupOpen && (
                <div className="adco-popupOverlay">
                    <div className="adco-popupContent">
                        <button className="adco-closeButton" onClick={closePopup}>
                            <IoCloseOutline /> 
                        </button>
                        <h2>{editIndex !== null ? 'Edit Coach' : 'Add Coach'}</h2>
                        <form onSubmit={handleFormSubmit}>
                            <label>Coach Name</label>
                            <input 
                                type="text" 
                                name="coachName" 
                                placeholder="Samir Loussif" 
                                required 
                                autoComplete="name" 
                                defaultValue={editIndex !== null ? coaches[editIndex].name : ''}
                            />
                            
                            <label>Email</label>
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Samirloussif@gmail.com" 
                                required 
                                autoComplete="email" 
                                defaultValue={editIndex !== null ? coaches[editIndex].email : ''}
                            />
                            
                            <label>Phone Number</label>
                            <input 
                                type="tel" 
                                name="phoneNumber" 
                                placeholder="+216 12345678" 
                                required 
                                autoComplete="tel" 
                                defaultValue={editIndex !== null ? coaches[editIndex].phoneNumber : ''}
                            />
                            
                            <label>Percentage</label>
                            <input 
                                type="number" 
                                name="percentage" 
                                placeholder="20" 
                                required 
                                defaultValue={editIndex !== null ? coaches[editIndex].commission : ''}
                            />
                            
                            <label>Status</label>
                            <select 
                                name="status" 
                                required 
                                defaultValue={editIndex !== null ? coaches[editIndex].status : ''}
                            >
                                <option value="">Status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                            
                            <button type="submit" className="adco-adddCoachButton">
                                {editIndex !== null ? 'Update Coach' : 'Add Coach'}
                            </button>
                        </form>
                    </div>
                </div>
            )}

            {isSuccessPopupOpen && (
                <SuccessPopup 
                    message="This Email Contains an Account. The Coach Must Accept this Partnership." 
                    onClose={closeSuccessPopup} 
                />
            )}
        </div>
    );
};

export default AddCoachs;