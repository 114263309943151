import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="foogy-footer">
      <div className="foogy-footer-content">
        <div className="left-content">
          <p className="mb-0">
            © 2024 Celestial Wave Digital
            <span className="separator">·</span>
            <span className="date-time">2023/08/12 · 16:45 (GMT+1)</span>
          </p>
        </div>
        <div className="right-content">
          <a href="/terms" className="foogy-footer-link">Terms of use</a>
          <span className="separator">·</span>
          <a href="/privacy" className="foogy-footer-link">Privacy policy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;