import React, { useState, useEffect } from 'react';
import './allproducts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const AllProducts = () => {
  const [products, setProducts] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchedProducts = [
      { id: 1, name: 'Product 1', price: '250 TND', orders: '2', visibility: 'Visible', date: '2023-08-12 18:06:55' },
      { id: 2, name: 'Product 2', price: '250 TND', orders: '2', visibility: 'Visible', date: '2023-08-12 18:06:55' },
      { id: 3, name: 'Product 3', price: '250 TND', orders: '2', visibility: 'Visible', date: '2023-08-12 18:06:55' },
      { id: 4, name: 'Product 4', price: '250 TND', orders: '2', visibility: 'Visible', date: '2023-08-12 18:06:55' },
      { id: 5, name: 'Product 5', price: '250 TND', orders: '2', visibility: 'Visible', date: '2023-08-12 18:06:55' },
    ];
    setProducts(fetchedProducts);
  }, []);

  const handleEdit = (id) => {
    navigate(`/shop/edit-product/${id}`);
  };

  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setProducts(products.filter(product => product.id !== productToDelete.id));
    setShowDeleteModal(false);
    setShowSuccessMessage(true);
    setProductToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setProductToDelete(null);
  };

  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  return (
    <div className="allproducts">
      <div className="addproductcontainer"></div>
      <table>
        <thead>
          <tr>
            <th><input type="checkbox" /></th>
            <th>Product Name</th>
            <th>Price</th>
            <th>Orders</th>
            <th>Visibility</th>
            <th>Date of Creation</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <tr key={product.id}>
              <td>
                <input type="checkbox" />
              </td>
              <td>{product.name}</td>
              <td>{product.price}</td>
              <td>{product.orders}</td>
              <td>
                <button className={`visibility-btn ${product.visibility.toLowerCase()}`}>
                  {product.visibility}
                </button>
              </td>
              <td>{product.date}</td>
              <td>
                <div className="action-buttons">
                  <button onClick={() => handleEdit(product.id)} className="icon-link">
                    <FontAwesomeIcon icon={faEdit} className="edit-icon" />
                  </button>
                  <button onClick={() => handleDeleteClick(product)} className="del-btn">
                    <FontAwesomeIcon icon={faTrash} className="delete-icon" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showDeleteModal && (
        <div className="modal-backdrop">
          <div className="shop-popup-content alert-content">
            <div className="alert-modal">
              <div>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: '50px', color: '#FFA500' }} />
              </div>
              <p>Alert! Deleting this will no longer assign The Client to you.</p>
              <select>
                <option value="">Request category</option>
                {/* Additional options can be added here */}
              </select>
              <div className="alert-actions">
                <button className="cancel-btn" onClick={handleCancelDelete}>Cancel</button>
                <button className="confirm-btn" onClick={handleConfirmDelete}>Confirm</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showSuccessMessage && (
        <div className="modal-backdrop">
          <div className="shop-popup-content">
            <FontAwesomeIcon icon={faCheckCircle} className="success-icon" size="3x" color="green" />
            <p>Product Deleted Successfully</p>
            <button className="confirm-btn" onClick={closeSuccessMessage}>Done</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProducts;
