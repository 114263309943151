import React from 'react';
import './mail.css';

const Mail = () => {
  return (
    <div className="mail-container">
      <h2 className="mail-title">Mail Settings</h2>
      <div className="mail-card">
        <div className="mail-option">
          <label className="option-label">
          <div className='checkbox'>
            <input type="checkbox" name="tips" />
            </div>
            <div className='title_desc'>
              <span className="option-title">Special Promotions</span>
              <p className="option-description">
                A few times a year, we'll offer subscription discounts that you won't want to miss.
              </p>
            </div>
          </label>
        </div>
        <div className="mail-option">
          <label className="option-label">
            <div className='checkbox'>
            <input type="checkbox" name="tips" />
            </div>
            <div className='title_desc'>
              <span className="option-title">Tips & Tricks</span>
              <p className="option-description">
                Get the most out of Gym'sClub, including helpful info on how to get started.
              </p>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Mail;
