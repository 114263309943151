// src/Components/Dashboard/Coaches/Trainee/trainee.js
import React, { useState } from 'react';
import './trainee.css';  
import { RiDeleteBin6Line } from 'react-icons/ri'; 
import { MdAddBox } from "react-icons/md";  

const Trainee = () => {
  const traineesData = [
    { name: 'Jane Cooper', gender: 'Male', phone: '+21685968325' },
    { name: 'John Doe', gender: 'Male', phone: '+21612345678' },
    { name: 'Alice Johnson', gender: 'Female', phone: '+21687654321' },
    { name: 'Bob Smith', gender: 'Male', phone: '+21655555555' },
    { name: 'Eve Adams', gender: 'Female', phone: '+21698765432' },
    { name: 'Charlie Brown', gender: 'Male', phone: '+21611111111' },
    { name: 'Diana Prince', gender: 'Female', phone: '+21622222222' },
  ];

  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState('');

  const emailOptions = [
    'hamza@gmail.com',
    'john.doe@example.com',
    'alice.johnson@example.com',
    // Add more email options as needed
  ];

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();  
    togglePopup();  
  };

  return (
    <div className="tre-trainee-container">
      <div className="tre-trainee-content">
        <div className="tre-profile-trainee-content">
          <table>
            <thead>
              <tr>
                <th></th>  
                <th>Trainee Name</th>
                <th>Gender</th>
                <th>Phone Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {traineesData.map((trainee, index) => (
                <tr key={index}>
                  <td><input type="checkbox" /></td>  
                  <td>{trainee.name}</td>
                  <td>{trainee.gender}</td>
                  <td>{trainee.phone}</td>
                  <td>
                    <button className="tre-profile-trainee-delete-button">
                      <RiDeleteBin6Line /> 
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <button className="tre-add-subscription-button" onClick={togglePopup}>
        <MdAddBox />  
        Add Subscription
      </button>

      {showPopup && (
        <div className="tre-popup">
          <div className="tre-popup-content">
            <button className="tre-close-button" onClick={togglePopup}>x</button>
            <h2>Add Subscription</h2>
            <hr />
            <form onSubmit={handleFormSubmit}>
              <div className="tre-email-input-container">
                <label>Email:</label>
                <input
                  type="email"
                  list="email-list"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="hamza@gmail.com"
                  required
                />
                <datalist id="email-list">
                  {emailOptions.map((option, index) => (
                    <option key={index} value={option} />
                  ))}
                </datalist>
              </div>
              <button type="submit" className="tre-add-subscription-popup-button">
                Add Event
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Trainee;