import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CoachLayoutRoutes from './CoachLayoutRoutes';

const CoachRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<CoachLayoutRoutes />} />
    </Routes>
  );
};

export default CoachRoutes;
