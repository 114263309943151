import React from 'react';
import './Detailsco.css';

const Detailsco = ({ coachDetails }) => {
  return (
    <div className="detusco-details-card">
      <div className="detusco-details-header">
        <h2 className="detusco-gym-name">{coachDetails.name}</h2>
        <p className="detusco-plan">Current Plan</p>
      </div>
      <div className="detusco-details-content">
        <div className="detusco-details-info">
          <div className="detusco-info-pair">
            <div className="detusco-info-item">
              <h4>Location</h4>
              <p>{coachDetails.address}</p>
            </div>
          </div>
          <div className="detusco-info-pair">
            <div className="detusco-info-item">
              <h4>Age</h4>
              <p>{coachDetails.age}</p>
            </div>
          </div>
          <div className="detusco-info-pair">
            <div className="detusco-info-item">
              <h4>Experience</h4>
              <p>{coachDetails.experience}</p>
            </div>
          </div>
        </div>
        <div className="detusco-details-logo">
          <img src={coachDetails.image} alt="Coach" className="detusco-coach-image" />
        </div>
      </div>
    </div>
  );
};

export default Detailsco;