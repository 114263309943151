//src/SuperComponents/SuperDashboard/Coaches/SuccessPopup/SuccessPopup.js
import React, { useEffect } from 'react';
import './SuccessPopup.css';

const SuccessPopup = ({ message, onClose }) => {
    useEffect(() => {
         document.body.classList.add('blurred');

         return () => {
            document.body.classList.remove('blurred');
        };
    }, []);

    return (
        <div className="success-popup-overlay">
            <div className="success-popup-content">
                <div className="popup-header">
                    <h2 className="popup-title">Add Coach</h2>
                    <button className="close-button" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <hr className="popup-divider" />
                <div className="success-popup-message">
                    <div className="checkmark-icon">&#10003;</div>
                    <p>{message}</p>
                </div>
                <button className="done-button" onClick={onClose}>Done</button>
            </div>
        </div>
    );
};

export default SuccessPopup;