import React from 'react';
import { Link } from 'react-router-dom';
import './topsellingproducts.css';
import TopSellProduct from './TopSellProduct/TopSellProduct';
import FilterShop from './FilterShop/FilterShop';

const TopSellProductPage = () => {
  return (
    <div className='tsps-topsellproducts'>
      <nav aria-label="breadcrumb">
        <ol className="tsps-breadcrumb">
          <li className="tsps-breadcrumb-item">
            <span className="tsps-breadcrumb-arrow">&lt;</span>
            <Link to="/Overview">Overview</Link>
          </li>
          <li className="tsps-breadcrumb-item">
            <Link to="/Overview/shoprevenue">Shop Revenue</Link>
          </li>
          <li className="tsps-breadcrumb-item active" aria-current="page">
            Top Selling Products
          </li>
        </ol>
      </nav>
      <FilterShop />
      <TopSellProduct />
    </div>
  );
};

export default TopSellProductPage;