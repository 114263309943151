import React from 'react';
import { H5, UL, LI } from '../../../../../AbstractElements';
import { Earning, Order, OrderOverviewTitle, Refunds } from '../../../../../Constant';
import { OptionsOverView, OptionsOverViewBarChart } from '../../../../../Data/Ecommerce/Chart';
import ReactApexChart from 'react-apexcharts';
import './Order.css';
import { Link } from 'react-router-dom';

const OrderOverview = () => {
  return (
    <div className='card_overviewgym'>
      <div className='card-header card-no-border'>
        <div>
          <H5>{OrderOverviewTitle}</H5>
          <span className='show-more'>Last month</span>
        </div>
        <div>
          <UL attrUL={{ horizontal: true, className: 'ul-horizontal' }}>
            <LI>
              <span className='circle bg-secondary' />
              <Link to="/overview/gymrevenue" className='f-light'>{Refunds}</Link>
            </LI>
            <LI>
              <span className='circle bg-primary'> </span>
              <Link to="/overview/trainersrevenue" className='f-light'>{Earning}</Link>
            </LI>
            <LI>
              <span className='circle bg-success'> </span>
              <Link to="/overview/shoprevenue" className='f-light'>{Order}</Link>
            </LI>
          </UL>
        </div>
      </div>
      <div className='card-body pt-0'>
        <div className='overall-card'>
          <div className='chart-right'>
            <div className='current-sale-container order-container'>
              <ReactApexChart className='overview-wrapper' type='line' height={300} options={OptionsOverView.options} series={OptionsOverView.series} />
              <div className='back-bar-gym'>
                <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderOverview;