import React, { useState } from 'react';
import './Nutrition.css';
import { FaFire } from 'react-icons/fa'; 

const Nutrition = () => {
  const [events, setEvents] = useState({});
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
  const [selectedTile, setSelectedTile] = useState(null);
  const [mealName, setMealName] = useState('');
  const [calories, setCalories] = useState('');

   const numberOfTiles = 42;  

   const handleAddEventClick = (tileIndex) => {
    setSelectedTile(tileIndex);
    setIsPopupVisible(true);
  };

   const handleAddMeal = () => {
    if (mealName && calories) {
      setEvents({
        ...events,
        [selectedTile]: { meal: mealName, calories: `${calories} kcal`, details: ['100g de Lorem', '250g de Lorem', '100g de Lorem', '100g de Lorem', '250g de Lorem'] },
      });
      setIsPopupVisible(false);
      setMealName('');
      setCalories('');
    }
  };

   const handleEventDetailsClick = (tileIndex) => {
    setSelectedTile(tileIndex);
    setIsEditPopupVisible(true);
  };

   const handleDeleteEvent = () => {
    const newEvents = { ...events };
    delete newEvents[selectedTile];
    setEvents(newEvents);
    setIsEditPopupVisible(false);
  };

   const handleEditEvent = () => {
     setIsEditPopupVisible(false);
    alert('Edit functionality coming soon!');
  };

  return (
    <div className="nutrition-main-container">
      <div className="nutrition-grid">
        {Array.from({ length: numberOfTiles }).map((_, index) => (
          <div key={index} className="nutrition-day">
            {events[index] ? (
              <div className="event-details" onClick={() => handleEventDetailsClick(index)}>
                <p className="event-meal">{events[index].meal}</p>
                <div className="event-info">
                  <FaFire className="event-icon" />
                  <span className="event-calories">{events[index].calories}</span>
                </div>
              </div>
            ) : (
              <button className="add-event" onClick={() => handleAddEventClick(index)}>+</button>
            )}
          </div>
        ))}
      </div>

      {isPopupVisible && (
        <div className="nutrition-popup-container">
          <div className="nutrition-popup-content">
            <h3>Add Meal</h3>
            <input
              type="text"
              placeholder="Meal Name"
              value={mealName}
              onChange={(e) => setMealName(e.target.value)}
              className="nutrition-popup-input"
            />
            <input
              type="number"
              placeholder="Calories"
              value={calories}
              onChange={(e) => setCalories(e.target.value)}
              className="nutrition-popup-input"
            />
            <div className="popup-buttons">
              <button onClick={handleAddMeal} className="add-meal-button">Add</button>
              <button onClick={() => setIsPopupVisible(false)} className="close-popup-button">Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isEditPopupVisible && (
        <div className="edit-popup-container">
          <div className="edit-popup-content">
            <h3 className="edit-popup-title">{events[selectedTile]?.meal}</h3>
            <ul className="edit-popup-details">
              {events[selectedTile]?.details.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
            <div className="edit-popup-buttons">
              <button onClick={handleDeleteEvent} className="delete-button">Delete</button>
              <button onClick={handleEditEvent} className="edit-button">Edit</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nutrition;
