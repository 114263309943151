//src/Components/LayoutRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../Components/Layout/Layout';
import Gymrevenue from '../Components/Dashboard/Overview/Gymrevenue/gymrevenue';
import Trainersrevenue from '../Components/Dashboard/Overview/Trainersrevenue/trainersrevenue';
import GymDetails from '../Components/Dashboard/Display/Gymdetailsdisplay/gymdetailsdisplay';
import Shoprevenue from '../Components/Dashboard/Overview/Shoprevenue/shoprevenue';
import Gallery from '../Components/Dashboard/Display/Gallery/gallery';
import Pricing from '../Components/Dashboard/Display/Pricing/pricing';
import AddPricing from '../Components/Dashboard/Display/Pricing/addpricing';
import Overview from '../Components/Dashboard/Overview/overview';
import AccountDetails from '../Components/Dashboard/Settings/Accountdetails/accountdetails';
import NotificationSettings from '../Components/Dashboard/Settings/Notification/notification';
import Security from '../Components/Dashboard/Settings/Security/security';
import FAQ from '../Components/Dashboard/FAQ/faq';
import Contact from '../Components/Dashboard/Contact/contact';
import Activities from '../Components/Dashboard/Display/Activities/activities';
import AddActivity from '../Components/Dashboard/Display/Activities/addact';
import Profile from '../Components/Dashboard/Coaches/Profile/profile';
import Trainee from '../Components/Dashboard/Coaches/Trainee/trainee';
import TopSellingProducts from '../Components/Dashboard/Overview/Shoprevenue/TopSellingProducts/topsellingproducts';
import Gym from '../Components/Dashboard/Gym/gym';
import AddCoachs from '../Components/AddCoachs/AddCoachs'; 
import Schedule from '../Components/Dashboard/Schedule/schedule';  
import Clicks from '../Components/Dashboard/Overview/Clicks/Clicks';  
import CoachsDetails from '../Components/Dashboard/Overview/Trainersrevenue/CoachsDetails/coachsdetails';
import ProfileSet from '../Components/Layout/PrtofileSet/profileset';
import Shop from '../Components/Dashboard/Shop/shop';
import EditProduct from '../Components/Dashboard/Shop/EditProduct';
import CreateProduct from '../Components/Dashboard/Shop/CreateProduct';



const LayoutRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
      <Route path="/shop" element={<Shop />} />
      <Route path="/shop/edit-product/:id" element={<EditProduct />} />
        <Route path="/shop/create-product" element={<CreateProduct />} />
      <Route path="/profile" element={<ProfileSet />} />
        <Route path="/Overview" element={<Overview />} />
        <Route path="/Overview/gymrevenue" element={<Gymrevenue />} />
        <Route path="/Overview/trainersrevenue" element={<Trainersrevenue />} />
        <Route path="/Overview/shoprevenue" element={<Shoprevenue />} />
        <Route path="/Coaches/Profile" element={<Profile />} />
        <Route path="/Coaches/Trainee" element={<Trainee />} />
        <Route path="/display/gymdetails" element={<GymDetails />} />
        <Route path="/display/activities" element={<Activities />} />
        <Route path="/display/activities/addactivity" element={<AddActivity />} />
        <Route path="/display/activities/addactivity/:id" element={<AddActivity />} />
        <Route path="/display/gallery" element={<Gallery />} />
        <Route path="/display/pricing" element={<Pricing />} />
        <Route path="/display/pricing/addpricing" element={<AddPricing />} />
        <Route path="/Settings/accoundetails" element={<AccountDetails />} />
        <Route path="/Settings/notifications" element={<NotificationSettings />} />
        <Route path="/Settings/security" element={<Security />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Overview/topsellingproducts" element={<TopSellingProducts />} />
        <Route path="/Overview/coachsdetails" element={<CoachsDetails />} />
        <Route path="/Gym" element={<Gym />} />
        <Route path="/Coaches/Add" element={<AddCoachs />} />
        <Route path="/schedule" element={<Schedule />} /> 
        <Route path="/Overview/clicks" element={<Clicks />} /> 
      </Route>
    </Routes>
  );
};

export default LayoutRoutes;