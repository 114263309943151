import React from 'react';
import './delete.css';

const DeleteAccount = () => {
  return (
    <div className="dlt-delete-account-wrapper">
      <h2 className='dlt-delete-title'>Delete Account</h2>
      <div className="dlt-delete-account-container">
        <div className="dlt-delete-content">
          <p className="dlt-paragraph">
            You must <a className="dlt-link" href="/cancel-subscription">Cancel any Active Subscription</a> before deleting your account
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;