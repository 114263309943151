import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js';
import moment from 'moment';
import 'chartjs-adapter-date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import './shopincome.css';

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const fetchIncomeData = (year) => {
  const data = {
    '2024': [
      { month: '2024-01-01', income: 5000 },
      { month: '2024-02-01', income: 6000 },
      { month: '2024-03-01', income: 4000 },
      { month: '2024-04-01', income: 7000 },
      { month: '2024-05-01', income: 8000 },
      { month: '2024-06-01', income: 9000 },
      { month: '2024-07-01', income: 11000 },
      { month: '2024-08-01', income: 12000 },
      { month: '2024-09-01', income: 14000 },
      { month: '2024-10-01', income: 13000 },
      { month: '2024-11-01', income: 15000 },
      { month: '2024-12-01', income: 14000 },
    ],
    '2023': [
      { month: '2023-01-01', income: 4000 },
      { month: '2023-02-01', income: 5000 },
      { month: '2023-03-01', income: 3000 },
      { month: '2023-04-01', income: 6000 },
      { month: '2023-05-01', income: 7000 },
      { month: '2023-06-01', income: 8000 },
      { month: '2023-07-01', income: 10000 },
      { month: '2023-08-01', income: 11000 },
      { month: '2023-09-01', income: 13000 },
      { month: '2023-10-01', income: 12000 },
      { month: '2023-11-01', income: 14000 },
      { month: '2023-12-01', income: 15000 },
    ],
   
  };
  return data[year] || [];
};

const Income = () => {
  const [year, setYear] = useState('2024');
  const [incomeData, setIncomeData] = useState([]);

  useEffect(() => {
    const data = fetchIncomeData(year);
    setIncomeData(data);
  }, [year]);

  const labels = incomeData.map((data) => moment(data.month).format('MMM'));
  const dataPoints = incomeData.map((data) => data.income);

  const data = {
    labels,
    datasets: [
      {
        label: 'Income (TND)',
        data: dataPoints,
        fill: false,
        borderColor: '#8277F6',
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category',
        labels: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ]
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            if (value >= 1000) {
              return value / 1000 + 'k';
            }
            return value;
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="income-chart-container">
      <div className="year-selector">
        <div className="select-wrapper">
          <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
          <select value={year} onChange={(e) => setYear(e.target.value)}>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            
          </select>
        </div>
      </div>
      <div className='courbe'>

<Line data={data} options={options} />
</div>
    </div>
  );
};

export default Income;
