import React, { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import './SubscriptionPopup.css';
import useApi from '../../api/useApi';

const SubscriptionPopup = ({ onClose, onSubmit, packs }) => {
    const [formData, setFormData] = useState({
        email: '',
        pack: '',
        startDate: '',
        status: 'pending',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { request } = useApi();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const fetchUserByEmail = async (email) => {
        try {
            const response = await request('get', `/users/findbyemail/${email}`);
            return response._id;
        } catch (error) {
            console.error('Error fetching user by email:', error);
            return null;
        }
    };

    const calculateEndDate = (startDate, duration) => {
        const start = new Date(startDate);
        let endDate;
        switch (duration) {
            case 'MONTHLY':
                endDate = new Date(start.setMonth(start.getMonth() + 1));
                break;
            case 'YEARLY':
                endDate = new Date(start.setFullYear(start.getFullYear() + 1));
                break;
            default:
                endDate = new Date(start.setMonth(start.getMonth() + 1)); 
        }
        return endDate.toISOString().split('T')[0]; 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return; 
        setIsSubmitting(true);

        try {
            const userId = await fetchUserByEmail(formData.email);
            if (!userId) {
                console.error('User not found');
                setIsSubmitting(false);
                return;
            }

            const selectedPack = packs.find(pack => pack._id === formData.pack);
            const endDate = calculateEndDate(formData.startDate, selectedPack.duration);

            const payload = {
                packId: formData.pack,
                startDate: formData.startDate,
                endDate: endDate,
                subscriptionStatus: formData.status,
                paymentStatus: 'paid',
                UserId: userId,
            };

            const response = await request('post', '/subUser', payload);

            onSubmit(response);
            setIsSubmitting(false);
            onClose(); 
        } catch (error) {
            console.error('Error:', error);
            setIsSubmitting(false);
        }

             


    };

    return (
        <div className="popupOverlay">
            <div className="popupContent">
                <button className="closeButton" onClick={onClose}>
                    <IoCloseOutline />
                </button>
                <h2>Add Subscription</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="e.g. user@example.com"
                        required
                    />

                    <label htmlFor="pack">Select Pack</label>
                    <select
                        id="pack"
                        name="pack"
                        value={formData.pack}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select a pack</option>
                        {packs.map((pack) => (
                            <option key={pack._id} value={pack._id}>
                                {pack.name}
                            </option>
                        ))}
                    </select>

                    <label htmlFor="startDate">Start Date</label>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        required
                    />

                    <label htmlFor="status">Status</label>
                    <select
                        id="status"
                        name="status"

                        
                        value={formData.status}
                        onChange={handleChange}
                        required
                    >
                        <option value="pending">Pending</option>
                        <option value="accepted">Accepted</option>
                        <option value="rejected">Rejected</option>
                        <option value="expired">Expired</option>
                    </select>

                    <button
                        type="submit"
                        className="addSubscriptionButton"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Add Subscription'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SubscriptionPopup;