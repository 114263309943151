// src/CoachComponents/Setting/Accountdetails/accountdetails.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './accountdetails.css';

const Modal = ({ show, onClose, message }) => {
  if (!show) return null;

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal">
        <div className="modal-content">
          <div className="checkmark-circle">
            <div className="background"></div>
            <div className="checkmark"></div>
          </div>
          <p className='message'>{message}</p>
        </div>
      </div>
    </div>
  );
};

const AccountDetails = () => {
  const [coachDetails, setCoachDetails] = useState({
    name: 'sarra fersi',
    email: 'sarrafersi@example.com',
    city: 'sousse',
    address: 'kairoan',
    postalCode: '4360',
    profilePictureUrl: 'https://example.com/profile-picture.jpg'
  });

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoachDetails({
      ...coachDetails,
      [name]: value
    });
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setCoachDetails({
        ...coachDetails,
        profilePictureUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async () => {
    try {
      const response = await axios.post('https://jsonplaceholder.typicode.com/posts', coachDetails);
      if (response.status === 201) {
        setShowModal(true);
      } else {
        console.error('Failed to save data:', response);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/Overview');
  };

  return (
    <div className="account-details">
      <h2 className='title_details'>Account Details</h2>
      <div className="account-details-form">
        <div className="logo-container">
          <img src={coachDetails.profilePictureUrl} alt="Coach Profile" className="coach-profile" />
          <input
            type="file"
            name="profilePicture"
            onChange={handleProfilePictureChange}
            id="profile-picture-upload"
            className="logo-input"
          />
        </div>
        <div className='modifier_container'>
          <label htmlFor="profile-picture-upload" className="logo-upload-label">
            Modifier la photo de profil
          </label>
        </div>
        <div className='coachname_container'>
          <h3 className='coachname'>{coachDetails.name}</h3>
        </div>
        <input
          type="text"
          name="name"
          value={coachDetails.name}
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          value={coachDetails.email}
          onChange={handleChange}
        />
        <input
          type="text"
          name="city"
          placeholder="City"
          value={coachDetails.city}
          onChange={handleChange}
        />
        <input
          type="text"
          name="address"
          placeholder="Full Address"
          value={coachDetails.address}
          onChange={handleChange}
        />
        <input
          type="text"
          name="postalCode"
          placeholder="Postal Code"
          value={coachDetails.postalCode}
          onChange={handleChange}
        />
        <div className='details_btn'>
          <button onClick={handleSave} className="save-button">Save</button>
        </div>
      </div>
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        message="Saved!"
      />
    </div>
  );
};

export default AccountDetails;