import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './packs.css';

const Pack = () => {
  const countbasic = 6000;
  const countgold = 27545;
  const countsilver = 8723;
  const total = countbasic + countgold + countsilver;

  const packs = [
    { type: 'Basic', count: countbasic, color: '#7366FF' },
    { type: 'Gold', count: countgold, color: '#FF3364' },
    { type: 'Silver', count: countsilver, color: '#54BA4A' },
  ];

  return (
    <div className="pack-container">
      {packs.map((pack, index) => {
        const percentage = (pack.count / total) * 100;
        return (
          <div key={index} className="circular-progress-container">
            <h3>{pack.type}</h3>
            <div className="circular-progress-wrapper">
            <CircularProgressbar
  value={percentage}
  text={`${percentage.toFixed(0)}%`}
  styles={buildStyles({
    textColor: 'black',
    pathColor: pack.color,
    trailColor: '#d6d6d6',
  })}
  className="small-progressbar circular-progressbar-shadow"
/>

            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Pack;
