import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './addact.css';

const AddActivity = ({ activities, setActivities, id, closeModal }) => {
  const isEdit = Boolean(id);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [preview, setPreview] = useState(null);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    if (isEdit) {
      const activityToEdit = activities.find(activity => activity.id === id);
      if (activityToEdit) {
        setName(activityToEdit.name);
        setDescription(activityToEdit.description);
        setPreview(activityToEdit.image);
      }
    }
  }, [id, isEdit, activities]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setPreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newActivity = {
      id: isEdit ? id : activities.length + 1,
      name,
      description,
      image: preview || 'path/to/default/image.jpg',
      visibility: 'visible',
      date: new Date().toLocaleDateString()
    };

    if (isEdit) {
      const updatedActivities = activities.map(activity => 
        activity.id === id ? newActivity : activity
      );
      setActivities(updatedActivities);
      setModalMessage('Activity Updated');
    } else {
      setActivities([...activities, newActivity]);
      setModalMessage('Activity Added');
    }

    setTimeout(() => {
      setModalMessage('');
      closeModal();
    }, 1500);
  };

  const handleCancel = () => {
    setName('');
    setDescription('');
    setPreview(null);
    setModalMessage('');
    closeModal();
  };

  return (
    <div className="add-activity-form-container">
      <form className="add-activity-form" onSubmit={handleSubmit}>
        <h2>{isEdit ? 'Edit Activity' : 'Add Activity'}</h2>
        <label>
          Name of the Activity
          <input 
            type="text" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />
        </label>
        <label>
          Description
          <textarea 
            value={description} 
            onChange={(e) => setDescription(e.target.value)} 
            required 
          />
        </label>
        <label>
          Images*
          <div className="upload-box">
            <input 
              type="file" 
              accept="image/jpeg,image/png,application/pdf" 
              onChange={handleFileChange} 
            />
            {preview ? (
              <img src={preview} alt="Preview" className="image-preview" />
            ) : (
              <>
                <img src='../../upload.png' alt="Upload Icon" className="upload-icon" />
                <span>Drag & Drop or choose Image to upload</span>
                <p>Supported formats: Jpeg, pdf</p>
              </>
            )}
          </div> 
        </label>
        <button type="submit">Done</button>
        <button type="button" className="cancel-btn" onClick={handleCancel}>Cancel</button>
      </form>

      {modalMessage && (
        <div className="modal-backdrop">
          <div className="modal">
            <div className="modal-content">
              <div className="checkmark-circle">
                <div className="background"></div>
                <div className="checkmark"></div>
              </div>
              <p className="message">{modalMessage}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AddActivity.propTypes = {
  activities: PropTypes.array.isRequired,
  setActivities: PropTypes.func.isRequired,
  id: PropTypes.number,
  closeModal: PropTypes.func.isRequired
};

export default AddActivity;