// src/ConstantImages/constantimages.js
const constantImages = {
  avatar: '/profilplaning.png',
  coach: '/coach.png',  
  profile: '/profile-pic.png',  
  product1: '/Towel for gym displayed alown.png',
  product: '/RPM.png', // Path to the product image
  // Ajoutez l'image ici
};

export default constantImages;